import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import CurrenciesList from "src/components/wallet/CurrenciesList";
import "./index.scss";

const Withdraw = () => {
  const { t } = useTranslation();
  return (
    <div className="withdraw">
      <Typography variant="h6" align="center" sx={{ mt: 2 }}>
        {t("withdrawPage.choiceCurrency")}
      </Typography>
      <CurrenciesList path="withdraw" />
    </div>
  );
};

export default Withdraw;
