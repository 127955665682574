import { useNavigate } from "react-router-dom";
import { useGate, useStore } from "effector-react";
import PasswordChecklist from "react-password-checklist";

import "./index.scss";
import Logo from "src/assets/register/SetupPassword.png";
import UnAuthLayout from "src/components/layouts/UnAuthLayout";
import Input from "src/components/common/Input";
import Button from "src/components/common/Button";
import LoginLink from "src/components/common/LoginRegisterLink";
import {
  $email,
  $emailError,
  $invitationCode,
  $invitationCodeError,
  $password,
  $passwordError,
  register,
  ScreenGate,
  setEmail,
  setInvitationCode,
  setPassword,
} from "src/store/account";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const SetupPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useGate(ScreenGate, { onSuccess: () => navigate("/setup-tx-password") });

  useEffect(() => {
    const invitationCode = localStorage.getItem("invitationCode");
    invitationCode && setInvitationCode(invitationCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const invitationCode = useStore($invitationCode);
  const invitationCodeError = useStore($invitationCodeError);

  const password = useStore($password);
  const passwordError = useStore($passwordError);

  const email = useStore($email);
  const emailError = useStore($emailError);

  const loading = useStore(register.pending);

  return (
    <UnAuthLayout
      className="setup-password"
      img={Logo}
      title={t("pageTitle.setupPassword")}
    >
      <Input
        placeholder={t("placeholders.createPassword")}
        error={passwordError}
        isPassword
        value={password}
        onChange={value => setPassword(value)}
        style={{ marginBottom: password.length > 0 ? 0 : "20px" }}
      />
      <>
        {password.length > 0 && (
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={6}
            value={password}
            className="setup-password__checklist"
            iconSize={14}
          />
        )}
      </>
      <Input
        placeholder={`${t("placeholders.invitationCode")} (${t("optional")})`}
        error={invitationCodeError}
        value={invitationCode}
        onChange={value => setInvitationCode(value)}
      />
      <Input
        placeholder={`${t("placeholders.email")} (${t("optional")})`}
        value={email}
        onChange={value => setEmail(value)}
        error={emailError}
      />
      <Button
        onClick={() => register({ password, invitationCode, email })}
        className="setup-password__register-btn"
        loading={loading}
        disabled={loading}
      >
        {t("buttons.register")}
      </Button>
      <LoginLink />
    </UnAuthLayout>
  );
};

export default SetupPassword;
