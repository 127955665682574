import { gql } from "@apollo/client";
import { IAccount } from "src/graphQl/accounts";
import { ICurrency } from "src/graphQl/currencies";
import { PaypalPayments } from "./paypalTransactions";
import { IWithdrawalTransaction } from "./pendingTransactions";
import { StripePayment } from "./stripe";
import { IStake } from "./userStakes";

export interface ITransaction {
  fromAccountId?: number;
  fromAccount?: IAccount;
  toAccountId?: number;
  toAccount?: IAccount;
  currencyId?: number;
  currency: ICurrency;
  amount: number;
  baseAmount?: number;
  balanceFrom?: number;
  balanceTo?: number;
  prevBalanceFrom?: number;
  prevBalanceTo?: number;
  type?: TransactionType;
  id?: number;
  createdDate: string;
  updatedDate?: string;
}

export enum TransactionType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
  PAYPAL_PURCHASE = "PAYPAL_PURCHASE",
  PAYPAL_PURCHASE_SWAP_DEPOSIT = "PAYPAL_PURCHASE_SWAP_DEPOSIT",
  PAYPAL_PURCHASE_SWAP_FEE = "PAYPAL_PURCHASE_SWAP_FEE",
  STAKING_AMBASSADOR_INTEREST = "STAKING_AMBASSADOR_INTEREST",
  STAKING_INTEREST = "STAKING_INTEREST",
  STAKING_INVITER_INTEREST = "STAKING_INVITER_INTEREST",
  SWAP_DEPOSIT = "SWAP_DEPOSIT",
  SWAP_FEE = "SWAP_FEE",
  SWAP_WITHDRAWAL = "SWAP_WITHDRAWAL",
  SYSTEM_TRANSFER = "SYSTEM_TRANSFER",
  TWEE_BAA_TRANSFER = "TWEE_BAA_TRANSFER",
}

export interface IOperation {
  createdDate: string;
  id: number;
  transactions: ITransaction[];
  updatedDate: string;
  userAccount: IAccount;
  userAccount2?: IAccount;
  userAccountId: number;
  userAccountId2?: number;

  depositData: string;
  discriminator: string;
  payPalData: PaypalPayments;
  stakeData: IStake;
  stripeData: StripePayment;
  withdrawalData: IWithdrawalTransaction;
}

export const OPERATIONS = gql`
  query Operations(
    $where: OperationFilterInput
    $first: Int
    $last: Int
    $order: [OperationSortInput!]
    $after: String
  ) {
    operations(
      where: $where
      first: $first
      last: $last
      order: $order
      after: $after
    ) {
      nodes {
        createdDate
        id
        transactions {
          id
          amount
          baseAmount
          type
          id
          createdDate
          updatedDate
          currency {
            name
            id
          }
        }
        updatedDate
        depositData
        discriminator
        payPalData {
          purchasedCurrencyCode
          baseCurrencyCode
          purchasedCurrencyAmount
          baseCurrencyAmount
        }
        stakeData {
          currency {
            name
            id
          }
          amount
          baseAmount
        }
        stripeData {
          purchasedCurrencyCode
          purchasedCurrencyAmount
          baseCurrencyAmount
        }
        withdrawalData {
          amount
          baseAmount
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
