import { createEffect, createEvent, createStore } from "effector";
import { createGate } from "effector-react";
import { IKycDocument } from "src/graphQl/kyc";
import { IKycUploadDocumentsParams } from "src/services/Kyc";
import { TFileWithPreview } from "src/types/common";

export const KycGate = createGate<{ onUploadSuccess: () => void }>();

export const setFiles = createEvent<{ [key: string]: TFileWithPreview }>();
export const $files = createStore<{
  governmentIdFrontFile?: TFileWithPreview;
  governmentIdBackFile?: TFileWithPreview;
  selfieFile?: TFileWithPreview;
}>({}).on(setFiles, (_, fields) => ({
  ..._,
  ...fields,
}));

export const setUploadedFiles = createEvent<{
  [key: string]: string;
}>();
export const $uploadedFiles = createStore<{
  governmentIdFrontFile?: string;
  governmentIdBackFile?: string;
  selfieFile?: string;
}>({}).on(setUploadedFiles, (_, uploadedFiles) => ({
  ..._,
  ...uploadedFiles,
}));

export const getDocuments = createEffect<IKycDocument[], void>();
export const uploadDocuments = createEffect<IKycUploadDocumentsParams, void>();
