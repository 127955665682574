import { useCurrentUser } from "../graphQl/profile";

export const baseCurrencyNameToSymbol: { [k in string]: string } = {
  USD: "$",
  CNY: "¥",
};

export const useBaseCurrencySymbol = () => {
  const { user } = useCurrentUser();

  // if (loading || !user) {
  //   return "";
  // }

  return (
    (user?.baseCurrency?.name &&
      baseCurrencyNameToSymbol[user.baseCurrency.name]) ||
    localStorage.getItem("baseCurrencySymbol") ||
    baseCurrencyNameToSymbol["USD"]
  );
};
