import { CSSProperties, FC } from "react";
import cs from "classnames";

import "./index.scss";

import { ReactComponent as TBean } from "src/assets/wallet/TBean.svg";
import { ReactComponent as Twee } from "src/assets/wallet/Twee.svg";
import { ReactComponent as TweeB } from "src/assets/wallet/TweeB.svg";
import { ReactComponent as Usdt } from "src/assets/wallet/USDT.svg";
import PayPalIcon from "src/assets/wallet/paypal.png";
import StripeIcon from "src/assets/wallet/stripe.png";

interface IProps {
  currency: string;
  className?: string;
  style?: CSSProperties;
}

const CurrencyIcon: FC<IProps> = ({ currency, className, style }) => {
  const lCurrency = currency.toLowerCase();
  if (["tbean", "t-bean"].includes(lCurrency)) {
    return <TBean className={cs("currency-icon", className)} style={style} />;
  }
  if (lCurrency === "twee") {
    return <Twee className={cs("currency-icon", className)} style={style} />;
  }
  if (lCurrency === "tweeb") {
    return <TweeB className={cs("currency-icon", className)} style={style} />;
  }
  if (lCurrency === "usdt") {
    return <Usdt className={cs("currency-icon", className)} style={style} />;
  }
  if (lCurrency === "usd") {
    return (
      <img
        src={PayPalIcon}
        className={cs("currency-icon", className)}
        style={style}
        alt="PayPal"
      />
    );
  }
  if (lCurrency === "stripe") {
    return (
      <img
        src={StripeIcon}
        className={cs("currency-icon", className)}
        style={style}
        alt="Stripe"
      />
    );
  }
  return null;
};

export default CurrencyIcon;
