import { FC, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";

import "./index.scss";
import Button from "src/components/common/Button";
// import Transaction from "src/components/wallet/Transaction";
import { ReactComponent as Empty } from "src/assets/Empty.svg";
import { useCurrentUser } from "src/graphQl/profile";
import {
  IOperation,
  ITransaction,
  OPERATIONS,
  TransactionType,
} from "src/graphQl/operations";
import Loader from "src/components/common/Loader";
import { isTbean } from "src/utils/helpers";
import TransactionInfo from "src/components/wallet/TransactionInfo";
import Operation from "src/components/wallet/Operation";
import { useStore } from "effector-react";
import { $isAuth } from "src/store/account";
import { KycStatus } from "src/graphQl/kyc";

interface IProps {
  className?: string;
}

const CurrencyDetails: FC<IProps> = ({ className }) => {
  const { t } = useTranslation();
  const filters = [
    {
      href: "#all",
      text: t("currencyDetailsPage.filters.all"),
    },
    {
      href: "#deposit",
      text: t("currencyDetailsPage.filters.deposit"),
    },
    {
      href: "#withdrawal",
      text: t("currencyDetailsPage.filters.withdrawal"),
    },
  ];

  const { currency } = useParams();
  const { hash, search, pathname } = useLocation();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const currencyId = searchParams.get("currencyId");
  const isAuth = useStore($isAuth);

  const [getLastOperations, { data, loading }] = useLazyQuery(OPERATIONS);

  const txsFilter = useMemo(() => {
    let andFilters: any = [
      {
        userAccount: {
          userId: {
            eq: user?.id,
          },
        },
      },
    ];
    if (currencyId) {
      andFilters = [
        ...andFilters,
        {
          transactions: {
            some: {
              currencyId: { eq: +currencyId },
            },
          },
        },
      ];
    }
    if (hash === "#deposit") {
      andFilters = [
        ...andFilters,
        {
          transactions: {
            some: {
              type: { eq: TransactionType.DEPOSIT },
            },
          },
        },
      ];
    }
    if (hash === "#withdrawal") {
      andFilters = [
        ...andFilters,
        {
          transactions: {
            some: {
              type: { eq: TransactionType.WITHDRAWAL },
            },
          },
        },
      ];
    }
    return andFilters;
  }, [currencyId, user?.id, hash]);

  useEffect(() => {
    user?.id &&
      getLastOperations({
        variables: {
          where: {
            and: txsFilter,
          },
          order: {
            createdDate: "DESC",
          },
          first: 10,
        },
      });
  }, [getLastOperations, user?.id, currency, txsFilter]);

  // const txs = useMemo(
  //   () =>
  //     data?.operations?.nodes
  //       ?.map((a: IOperation) =>
  //         a.transactions
  //           ?.filter(
  //             (tx: ITransaction) => tx.currency.id?.toString() === currencyId
  //           )
  //           ?.map((tx: ITransaction) => tx)
  //       )
  //       .flat(),
  //   [data?.operations?.nodes, currencyId]
  // );

  const operations = useMemo(
    () => data?.operations?.nodes || [],
    [data?.operations?.nodes]
  );

  const filteredOperations = useMemo(
    () =>
      operations.map((i: IOperation) => ({
        ...i,
        transactions: i.transactions?.filter(
          (tx: ITransaction) => tx.currency.id?.toString() === currencyId
        ),
      })),
    [operations, currencyId]
  );

  const isEmpty =
    filteredOperations?.length === 0 && hash === "#all" && !loading;
  const isEmptyFiltered =
    filteredOperations?.length === 0 && hash !== "#all" && !loading;

  const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;

  const depositClickHandler = () => {
    if (!isAuth) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    if (kycRequired) {
      return navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
    }
    isTbean(currency)
      ? navigate(`/wallet/transfer#deposit`)
      : navigate(`/wallet/deposit/${currency}`);
  };

  return (
    <div className="currency-details">
      {!isEmpty ? (
        <div className="currency-details__card">
          <div className="currency-details__filters">
            {filters.map(i => (
              <a
                key={i.href}
                href={i.href}
                className={cs("currency-details__filter-btn", {
                  "currency-details__filter-btn_active": i.href === hash,
                })}
              >
                {i.text}
              </a>
            ))}
          </div>
          {!loading &&
            (isEmptyFiltered ? (
              <div className="currency-details__empty-text">
                {t("currencyDetailsPage.noData")}
              </div>
            ) : (
              <div className="currency-details__column-title">
                {t("currencyDetailsPage.amountBalanceTitle")}
              </div>
            ))}
          <div className="currency-details__txs">
            {loading ? (
              <Loader className="currency-details__loader" />
            ) : (
              filteredOperations?.map((i: IOperation) => (
                <Operation
                  key={i.id}
                  item={i}
                  hideIcon
                  className="currency-details__tx"
                />
              ))
            )}
          </div>
        </div>
      ) : (
        <div className="currency-details__empty-container">
          <Empty />
          <div className="currency-details__empty-text">
            {t("currencyDetailsPage.noData")}
          </div>
        </div>
      )}
      <div className="currency-details__buttons">
        {!isEmpty && (
          <Button
            className="currency-details__btn"
            onClick={() =>
              navigate(`/wallet/withdraw/${currency}?currencyId=${currencyId}`)
            }
          >
            {t("buttons.withdraw")}
          </Button>
        )}
        <Button
          className={cs("currency-details__btn", {
            "currency-details__btn_long": isEmpty,
          })}
          onClick={depositClickHandler}
        >
          {t("buttons.deposit", { currency: isEmpty ? currency : undefined })}
        </Button>
      </div>
      <TransactionInfo />
      {/* <CommonModal modalName={ModalNames.Register} /> */}
    </div>
  );
};

export default CurrencyDetails;
