import { Box, CircularProgress } from "@mui/material";
import { FC, ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface IProps {
  isAllowed: boolean;
  loading?: boolean;
  redirectPath?: string;
  children?: ReactNode | ReactNode[];
}

const ProtectedRoute: FC<IProps> = ({
  isAllowed,
  loading,
  redirectPath = "/",
  children,
}) => {
  const location = useLocation();
  if (loading) {
    return (
      <Box sx={{ display: "flex", p: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
