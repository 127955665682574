import { useTranslation } from "react-i18next";

import { ReactComponent as TweeBankLogo } from "src/assets/logo.svg";
import "./index.scss";

const LandingHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-header">
      <TweeBankLogo className="landing-header__logo" />
      <div className="landing-header__title">{t("landing.header.title")}</div>
      <div className="landing-header__text">{t("landing.header.text")}</div>
    </div>
  );
};

export default LandingHeader;
