import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { $isAuth } from "src/store/account";
import { ICurrency } from "./currencies";
import { IUser } from "./profile";
import { IStakeTemplate } from "./stakeTemplates";

export enum StakingState {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  DONE = "DONE",
}

export interface IStake {
  ambassadorId: number;
  amount: number;
  cancelledDate: string;
  createdDate: string;
  currency: ICurrency;
  currencyId: number;
  endDate: string;
  id: number;
  interest: number;
  interestAmount: number;
  interestCurrency: ICurrency;
  interestCurrencyId: number;
  inviterId: number;
  startDate: string;
  state: StakingState;
  template: IStakeTemplate;
  templateId: number;
  termDays: number;
  updatedDate: string;
  userId: number;
  baseAmount: number;

  ambassadorInterestAmount: number;
  baseAmbassadorInterestAmount: number;
  baseInterestAmount: number;
  inviterInterestAmount: number;
  user: IUser;
  expectedInterest: number;
}

export type UserStakesQueryResponse = {
  userStakes: {
    nodes: IStake[];
  };
};

export const USER_STAKES = gql`
  query UserStakes(
    $where: StakeFilterInput
    $first: Int
    $last: Int
    $order: [StakeSortInput!]
    $after: String
  ) {
    userStakes(
      where: $where
      first: $first
      last: $last
      order: $order
      after: $after
    ) {
      nodes {
        ambassadorId
        amount
        cancelledDate
        createdDate
        currency {
          name
        }
        currencyId
        endDate
        id
        interest
        interestAmount
        interestCurrency {
          name
        }
        interestCurrencyId
        inviterId
        startDate
        state
        template {
          id
        }
        templateId
        termDays
        updatedDate
        userId
        baseAmount
        ambassadorInterestAmount
        baseAmbassadorInterestAmount
        baseInterestAmount
        inviterInterestAmount
        expectedInterest
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const USER_STAKES_COUNT = gql`
  query UserStakes(
    $where: StakeFilterInput
    $first: Int
    $last: Int
    $order: [StakeSortInput!]
  ) {
    userStakes(where: $where, first: $first, last: $last, order: $order) {
      totalCount
    }
  }
`;

export const useUserActiveStakes = () => {
  const isAuth = useStore($isAuth);
  const [getData, { data, error, loading }] = useLazyQuery(USER_STAKES);

  useEffect(() => {
    isAuth &&
      getData({
        variables: {
          first: 100,
          where: {
            state: {
              eq: StakingState.ACTIVE,
            },
          },
        },
      });
  }, [isAuth, getData]);

  return {
    userStakes: data?.userStakes?.nodes || [],
    error,
    loading,
  };
};
