import { useTranslation } from "react-i18next";

import "./index.scss";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy">
      <div className="privacy__title">{t("privacyPolicy.title")}</div>
      <br />
      {t("privacyPolicy.description")}
      <br />
      <p>{t("privacyPolicy.mainPoints")}</p>
      <br />
      {t("privacyPolicy.point1")}
      <br />
      {t("privacyPolicy.point2")}
      <br />
      {t("privacyPolicy.point3")}
      <br />
      {t("privacyPolicy.point4")}
      <br />
      {t("privacyPolicy.section1part1")}
      <br />
      {t("privacyPolicy.section1part2")}
      <br />
      {t("privacyPolicy.section1part3")}
      <br />
      {t("privacyPolicy.section1part4")}
      <br />
      {t("privacyPolicy.section1part5")}
      <br />
      {t("privacyPolicy.section1part6")}
      <br />
      {t("privacyPolicy.section1part7")}
      <br />
      {t("privacyPolicy.section2part1")}
      <br />
      {t("privacyPolicy.section2part2")}
      <br />
      {t("privacyPolicy.section2part3")}
      <br />
      {t("privacyPolicy.section2part4")}
      <br />
      {t("privacyPolicy.section2part5")}
      <br />
      {t("privacyPolicy.section2part6")}
      <br />
      {t("privacyPolicy.section2part7")}
      <br />
      {t("privacyPolicy.section2part8")}
      <br />
      {t("privacyPolicy.section2part9")}
      <br />
      {t("privacyPolicy.section2part10")}
      <br />
      {t("privacyPolicy.section2part11")}
      <br />
      {t("privacyPolicy.section2part12")}
      <br />
      {t("privacyPolicy.section2part13")}
      <br />
      {t("privacyPolicy.section3part1")}
      <br />
      {t("privacyPolicy.section3part2")}
      <br />
      {t("privacyPolicy.section3part3")}
      <br />
      {t("privacyPolicy.section4part1")}
      <br />
      {t("privacyPolicy.section4part2")}
      <br />
      {t("privacyPolicy.section4part3")}
      <br />
      {t("privacyPolicy.section4part4")}
      <br />
      {t("privacyPolicy.section4part5")}
      <br />
      {t("privacyPolicy.section4part6")}
      <br />
      {t("privacyPolicy.section4part7")}
      <br />
      {t("privacyPolicy.section4part8")}
      <br />
      {t("privacyPolicy.section4part9")}
      <br />
      {t("privacyPolicy.section4part10")}
      <br />
      {t("privacyPolicy.section4part11")}
      <br />
      {t("privacyPolicy.section4part12")}
      <br />
      {t("privacyPolicy.section4part13")}
      <br />
      {t("privacyPolicy.section4part14")}
      <br />
      {t("privacyPolicy.section4part15")}
      <br />
      {t("privacyPolicy.section5part1")}
      <br />
      {t("privacyPolicy.section5part2")}
      <br />
      {t("privacyPolicy.section5part3")}
      <br />
      {t("privacyPolicy.section6part1")}
      <br />
      {t("privacyPolicy.section6part2")}
      <br />
      {t("privacyPolicy.section7part1")}
      <br />
      {t("privacyPolicy.section7part2")}
      <br />
      {t("privacyPolicy.section7part3")}
      <br />
      {t("privacyPolicy.section7part4")}
      <br />
      {t("privacyPolicy.section7part5")}
      <br />
      {t("privacyPolicy.section7part6")}
      <br />
      {t("privacyPolicy.section8part1")}
      <br />
      {t("privacyPolicy.section8part2")}
      <br />
      {t("privacyPolicy.section8part3")}
      <br />
      {t("privacyPolicy.section8part4")}
      <br />
      {t("privacyPolicy.section9part1")}
      <br />
      {t("privacyPolicy.section9part2")}
      <br />
      {t("privacyPolicy.section10part1")}
      <br />
      {t("privacyPolicy.section10part2")}
      <br />
      {t("privacyPolicy.section10part3")}
      <br />
      {t("privacyPolicy.section10part4")}
      <br />
      {t("privacyPolicy.section11part1")}
      <br />
      {t("privacyPolicy.section11part2")}
    </div>
  );
};

export default Privacy;
