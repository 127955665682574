import { FC, ReactNode } from "react";
import styles from "./styles.module.scss";
import cs from "classnames";
import { Box, SxProps, Theme } from "@mui/material";

type Props = {
  direction?: "horizontal" | "vertical";
  justify?:
    | "start"
    | "end"
    | "center"
    | "space-around"
    | "space-between"
    | "stretch";
  align?: "start" | "end" | "center";
  gap?: number | string;
  children: ReactNode;
  inline?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
};

const Space: FC<Props> = ({
  direction,
  justify,
  align,
  gap = 0,
  children,
  inline = false,
  className,
  sx,
}) => {
  return (
    <Box
      className={cs(
        styles.space,
        {
          [styles[`space--align-${align}`]]: align,
          [styles[`space--justify-${justify}`]]: justify,
          [styles[`space--direction-${direction}`]]: direction,
          [styles["space--inline"]]: inline,
        },
        className
      )}
      style={{ gap: +gap }}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default Space;
