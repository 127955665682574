import { Button, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DEFAULT_DECIMAL_PLACES } from "src/config";
import { useTotalEarnings } from "src/graphQl/totalEarnings";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";

import "./index.scss";

const TotalEarnings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { totalEarnings } = useTotalEarnings();
  const baseCurrencySymbol = useBaseCurrencySymbol();

  if (!totalEarnings) {
    return null;
  }

  const {
    totalAmbassadorInterest,
    totalExpectedAmbassadorInterest,
    totalExpectedInterest,
    totalExpectedInviterInterest,
    totalInterest,
    totalInviterInterest,
  } = totalEarnings;

  const totalEarning = new BigNumber(totalAmbassadorInterest)
    .plus(totalExpectedAmbassadorInterest)
    .plus(totalExpectedInterest)
    .plus(totalExpectedInviterInterest)
    .plus(totalInterest)
    .plus(totalInviterInterest)
    .dp(DEFAULT_DECIMAL_PLACES)
    .toNumber();

  return (
    <div className="total-earnings">
      <Typography
        sx={{ fontSize: "24px", color: "primary.main", my: "15px" }}
        align="center"
      >
        {t("earnings")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/earnings")}
        sx={{
          height: "72px",
          background:
            "linear-gradient(0deg, rgba(235, 187, 113, 0.6), rgba(235, 187, 113, 0.6)), #FFFFFF",
          display: "flex",
          justifyContent: "space-between",
          textTransform: "capitalize",
          "&:hover": {
            background:
              "linear-gradient(0deg, rgba(235, 187, 113, 0.6), rgba(235, 187, 113, 0.6)), #FFFFFF",
          },
        }}
        fullWidth
      >
        <Typography variant="h6" color="text.primary2">
          {t("totalEarnings")}
        </Typography>
        <Typography variant="h6" color="text.primary2">
          {totalEarning}&nbsp;
          <Typography variant="body1" color="#A4A4A4" component="span">
            {baseCurrencySymbol}
          </Typography>
        </Typography>
      </Button>
    </div>
  );
};

export default TotalEarnings;
