import { gql } from "@apollo/client";

export enum SettingsType {
  AMBASSADOR_INTEREST = "AMBASSADOR_INTEREST",
  INVITER_INTEREST = "INVITER_INTEREST",
  TIV_THRESHOLD = "TIV_THRESHOLD",
  TIV_STAKE_POSITIVE_INTEREST = "TIV_STAKE_POSITIVE_INTEREST",
  TIV_STAKE_NEGATIVE_INTEREST = "TIV_STAKE_NEGATIVE_INTEREST",
  NO_TIV_STAKE_INTEREST = "NO_TIV_STAKE_INTEREST",
}

export interface ISetting {
  value: string;
  valueType: string;
  type: SettingsType;
  id: number;
  createdDate: string;
  updatedDate: string;
}

export const SETTINGS = gql`
  query Settings {
    settings {
      nodes {
        value
        valueType
        type
        id
        createdDate
        updatedDate
      }
    }
  }
`;
