import { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useStore } from "effector-react";
import {
  $email,
  $emailError,
  closeEmailDialog,
  setEmail,
  updateEmail,
  patchUserFx,
} from "../../../store/account";
import { useCurrentUser } from "../../../graphQl/profile";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
};

const EmailDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const email = useStore($email);
  const emailError = useStore($emailError);
  const pending = useStore(patchUserFx.pending);

  const isUpdated = email !== user?.email;

  return (
    <Dialog open={open} onClose={() => closeEmailDialog()}>
      <DialogTitle>
        {t(user?.email ? "dialogTitle.updateEmail" : "dialogTitle.setEmail")}
        <IconButton
          aria-label="close"
          onClick={() => closeEmailDialog()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label={t("placeholders.email")}
          fullWidth
          variant="filled"
          name="email"
          autoComplete="email"
          error={!!emailError}
          helperText={emailError}
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => updateEmail()}
          variant="contained"
          color="primary"
          fullWidth
          loading={pending}
          disabled={!isUpdated}
        >
          {t(user?.email ? "buttons.update" : "buttons.set")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDialog;
