import { createEvent, restore } from "effector";
import { createGate } from "effector-react";

export const AppGate = createGate();

export const setModal = createEvent<{
  name: string | number;
  data?: any;
} | null>();
export const $modal = restore(setModal, null);
