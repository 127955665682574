import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";

import CurrencyIcon from "src/components/common/CurrencyIcon";
import { IAccount } from "src/graphQl/accounts";
import { IStakeTemplate, STAKE_TEMPLATES } from "src/graphQl/stakeTemplates";
import { useAccounts } from "src/graphQl/profile";
import { groupBy, objFromArr, round } from "src/utils/helpers";
import "./index.scss";
import Loader from "src/components/common/Loader";
import { DEFAULT_ROUND } from "src/config";
import { SETTINGS } from "src/graphQl/settings";
import { TWEE_BAA_TIV } from "src/graphQl/tweeBaaTiv";
import { CURRENCIES, ICurrency } from "src/graphQl/currencies";
import { $isAuth } from "src/store/account";
import { useStore } from "effector-react";

const Staking = () => {
  const { t } = useTranslation();
  const { accounts } = useAccounts();
  const navigate = useNavigate();
  const isAuth = useStore($isAuth);

  const [getTemplates, { data, loading }] = useLazyQuery(STAKE_TEMPLATES);
  const [getSettings, { data: settings, loading: loadingSettings }] =
    useLazyQuery(SETTINGS);
  const [getTiv, { data: tiv, loading: loadingTiv }] =
    useLazyQuery(TWEE_BAA_TIV);
  const [getCurrencies, { data: currencies }] = useLazyQuery(CURRENCIES);

  useEffect(() => {
    !isAuth &&
      getCurrencies({
        variables: {
          where: {
            name: { nin: ["USD", "CNY"] },
          },
        },
      });
  }, [isAuth, getCurrencies]);

  const accountsList = isAuth
    ? accounts
    : currencies?.currencies?.nodes?.map((currency: ICurrency) => ({
        balance: 0,
        baseBalance: 0,
        currency,
        id: currency.id,
      }));

  useEffect(() => {
    isAuth && getTiv();
  }, [isAuth, getTiv]);

  useEffect(() => {
    getTemplates({
      variables: {
        first: 100,
        where: {
          currency: {
            name: {
              neq: "USDT",
            },
          },
        },
      },
    });
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stakeTemplates = data?.stakeTemplates?.nodes;
  const settingsArray = useMemo(
    () => settings?.settings?.nodes || [],
    [settings?.settings?.nodes]
  );
  const tivValue = tiv?.tweeBaaTiv || 0;
  const settingsObj = useMemo(
    () => objFromArr("type", settingsArray, "value"),
    [settingsArray]
  );
  const formattedTemplates = useMemo(
    () =>
      stakeTemplates?.map((template: IStakeTemplate) => ({
        ...template,
        currencyName: template.currency.name,
      })),
    [stakeTemplates]
  );

  const groupedTemplates: { string: IStakeTemplate[] } = useMemo(
    () =>
      formattedTemplates ? groupBy(formattedTemplates, "currencyName") : {},
    [formattedTemplates]
  );

  return (
    <div className="staking">
      <div className="staking__title">
        {t("stakingPage.interestRatesTitle")}
      </div>
      <div className="staking__rates">
        <div className="staking__periods">
          {[1, 3, 6, 12].map(i => (
            <div key={i} className="staking__period">
              <span className="staking__period-value">{i}</span>{" "}
              {/* TODO: Use plural api */}
              {t(i === 1 ? "month" : "months")}
            </div>
          ))}
        </div>
        {loading || loadingSettings || loadingTiv ? (
          <Loader />
        ) : (
          Object.entries(groupedTemplates)?.map(([currencyName, templates]) => (
            <div key={currencyName} className="staking__currency-rates">
              <div className="staking__rate-currency">
                <CurrencyIcon
                  currency={currencyName}
                  className="staking__rate-currency-icon"
                />{" "}
                {currencyName}
              </div>
              <div className="staking__rate-items">
                {templates
                  .sort((a, b) => a.termMonths - b.termMonths)
                  .map(({ id, termMonths, baseInterest, currencyId }) => {
                    // const percent = getStakingPercent(
                    //   baseInterest,
                    //   settingsObj,
                    //   tivValue
                    // );
                    const maxPercent = new BigNumber(baseInterest)
                      .plus(settingsObj.TIV_STAKE_POSITIVE_INTEREST)
                      .toNumber();
                    return (
                      <div
                        key={id}
                        className="staking__rate-item"
                        onClick={() =>
                          navigate(
                            `/staking/stake/${currencyName}?period=${termMonths}&interestCurrencyId=${currencyId}&interestCurrencyName=${currencyName}`
                          )
                        }
                      >
                        {maxPercent}%
                      </div>
                    );
                  })}
              </div>
            </div>
          ))
        )}
      </div>
      <div className="staking__rates-hint">
        {t("stakingPage.rateTiVNote")} {t("stakingPage.yourTiv")}: {tivValue}{" "}
        <Link to="/about-staking">{t("stakingPage.tivMoreLink")}</Link>
      </div>
      <div className="staking__title">
        {t("stakingPage.dualBenifitStakingSystemTitle")}
      </div>
      <div className="staking__description">
        {t("stakingPage.dualBenifitStakingSystemDescription")}
      </div>
      <div className="staking__title">
        {t("stakingPage.choiceCurrencyToInvestTitle")}
      </div>
      <div className="staking__available">
        {t("stakingPage.availableCurrenciesToInvestTitle")}
      </div>
      <div className="staking__currencies">
        {accountsList
          ?.filter((i: IAccount) => i.currency.name !== "USDT")
          ?.map((i: IAccount) => (
            <div
              key={i.id}
              className="staking__currency-item"
              onClick={() =>
                navigate(
                  `/staking/stake/${i.currency.name}?interestCurrencyId=${i.currencyId}&interestCurrencyName=${i.currency.name}`
                )
              }
            >
              <div className="staking__currency-item-icon-name">
                <CurrencyIcon currency={i.currency.name} />
                {i.currency.name}
              </div>
              <div className="staking__currency-item-balance">
                {round(i.balance, DEFAULT_ROUND)} {i.currency.name}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Staking;
