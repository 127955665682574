import { FC, ReactNode } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogCloseButton from "../../components/common/DialogCloseButton";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

type SuccessDialogProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

const SuccessDialog: FC<SuccessDialogProps> = ({ open, onClose, children }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Box color="text.primary" sx={{ m: "0 auto" }}>
          <LoadingButton
            variant="text"
            fullWidth
            color="inherit"
            onClick={onClose}
          >
            {t("buttons.close")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
