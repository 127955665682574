import { createEffect, createEvent, restore } from "effector";
import { IContactUsParams } from "src/services/Home";

export const contactUs = createEffect<IContactUsParams, void>();

export const setName = createEvent<string>();
export const $name = restore(setName, "").reset(contactUs.done);
export const setEmail = createEvent<string>();
export const $email = restore(setEmail, "").reset(contactUs.done);
export const setMessage = createEvent<string>();
export const $message = restore(setMessage, "").reset(contactUs.done);
