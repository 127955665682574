import {
  useLocation,
  useParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useGate, useStore } from "effector-react";
import BigNumber from "bignumber.js";
import { AiOutlineScan } from "react-icons/ai";
import { QrReader } from "react-qr-reader";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import Button from "src/components/common/Button";
import { getOnlyNumbers, round } from "src/utils/helpers";
import {
  $amount,
  $showConfirmTx,
  $withdrawalAddress,
  requestWithdraw,
  setAmount,
  setShowConfirmTx,
  setWithdrawalAddress,
  WithdrawCurrencyGate,
} from "src/store/wallet";
import Input from "src/components/common/Input";
import { useAccounts } from "src/graphQl/profile";
import WithdrawSuccess from "src/components/wallet/WithdrawSuccess";
import { useCurrencies } from "src/graphQl/currencies";
import { DEFAULT_ROUND } from "src/config";
import ConfirmTxPassword from "../ConfirmTxPassword";
import { KycStatus, useKyc } from "src/graphQl/kyc";
import { $isAuth } from "src/store/account";

const WithdrawCurrency = () => {
  const { t } = useTranslation();
  useGate(WithdrawCurrencyGate);
  const { currency } = useParams();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { currencies } = useCurrencies();
  const { accounts } = useAccounts();
  const isAuth = useStore($isAuth);
  const navigate = useNavigate();

  const showConfirmTx = useStore($showConfirmTx);
  const amount = useStore($amount);
  const withdrawalAddress = useStore($withdrawalAddress);
  const requestingWithdraw = useStore(requestWithdraw.pending);
  const currencyId = searchParams.get("currencyId");
  const [showScanner, setShowScanner] = useState(false);
  const { kyc } = useKyc();

  if (!currency || !currencyId) {
    return <Navigate to="/wallet/withdraw" />;
  }

  const kycRequired = kyc?.status !== KycStatus.APPROVED;

  // if (kyc?.status && kyc?.status !== KycStatus.APPROVED) {
  //   return <KycRequired kycStatus={kyc.status} />;
  // }

  const accountData = accounts?.find(i => i.currencyId === +currencyId);
  const handlingFee =
    currencies?.find(i => i.id === +currencyId)?.handlingFee || 0;

  const updateAmount = (percent: number) => {
    const newAmount = accountData?.balance
      ? new BigNumber(accountData?.balance)
          .div(100)
          .multipliedBy(percent)
          .toString()
      : "0";
    setAmount(newAmount);
  };

  const openScanner = () => {
    setShowScanner(true);
  };

  const roundedBalance = accountData?.balance
    ? round(accountData?.balance, DEFAULT_ROUND)
    : 0;

  const withdrawClickHandler = () => {
    if (!isAuth) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    if (kycRequired) {
      return navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
    }
    setShowConfirmTx(true);
  };

  if (showConfirmTx) {
    return (
      <ConfirmTxPassword
        onClick={transactionPassword =>
          requestWithdraw({
            currencyId: +currencyId,
            amount: +amount,
            to: withdrawalAddress,
            transactionPassword,
          })
        }
        loading={requestingWithdraw}
      />
    );
  }

  // const modalName = !isAuth
  //   ? ModalNames.Register
  //   : kycRequired
  //   ? ModalNames.Kyc
  //   : null;

  return (
    <div className="withdraw-currency">
      <div className="withdraw-currency__card">
        <div className="withdraw-currency__title">
          <CurrencyIcon currency={currency as string} />
          {t("withdrawCurrencyPage.pageTitle", { currency })}
        </div>

        <div className="withdraw-currency__address">
          <Input
            placeholder="Withdrawal address"
            value={withdrawalAddress}
            onChange={setWithdrawalAddress}
            className="withdraw-currency__address-input"
          />{" "}
          {showScanner && (
            <>
              <QrReader
                onResult={(result, error) => {
                  if (!!result) {
                    setWithdrawalAddress(result.toString());
                    setShowScanner(false);
                  }
                  if (!!error) {
                    console.info(error);
                  }
                }}
                className="withdraw-currency__qr-reader"
                ViewFinder={() => (
                  <div className="withdraw-currency__qr-reader-frame" />
                )}
                constraints={{ facingMode: { exact: "environment" } }}
                videoContainerStyle={{
                  width: "100%",
                  height: "50vh",
                  top: "20%",
                  // boxShadow: "0 0 0 50px red inset",
                  // pointerEvents: "none",
                  // overflow: "hidden",
                }}
                videoStyle={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <MdClose
                onClick={() => setShowScanner(false)}
                className="withdraw-currency__qr-reader-close"
              />
            </>
          )}
          <AiOutlineScan
            className="withdraw-currency__scan"
            onClick={openScanner}
          />
        </div>
        <div className="withdraw-currency__amount">
          <Input
            placeholder={`Amount, ${currency}`}
            value={amount}
            onChange={setAmount}
            className="withdraw-currency__amount-input"
            onlyValidValue={getOnlyNumbers}
          />
          <div className="withdraw-currency__amount-info">
            <div className="withdraw-currency__amount-available">
              {t("withdrawCurrencyPage.availableBalance", {
                balance: roundedBalance,
                currency,
              })}
            </div>
            <div className="withdraw-currency__possible-parts">
              {[
                { title: "25%", value: 25 },
                { title: "50%", value: 50 },
                { title: "All", value: 100 },
              ].map(i => (
                <div key={i.value} onClick={() => updateAmount(i.value)}>
                  {i.title}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="withdraw-currency__info">
          {t("withdrawCurrencyPage.handlingFee")}
          <div className="withdraw-currency__info-value">
            {handlingFee} {currency}
          </div>
        </div>
        <div className="withdraw-currency__info">
          {t("withdrawCurrencyPage.totalWithdraw")}
          <div className="withdraw-currency__info-value">
            {round(+amount + handlingFee, DEFAULT_ROUND)} {currency}
          </div>
        </div>
        <Button
          className="withdraw-currency__confirm"
          onClick={withdrawClickHandler}
          loading={requestingWithdraw}
          disabled={
            !amount ||
            !withdrawalAddress ||
            !searchParams.has("currencyId") ||
            requestingWithdraw
          }
        >
          {t("buttons.confirm")}
        </Button>
      </div>
      <WithdrawSuccess />
      {/* <CommonModal modalName={modalName} /> */}
    </div>
  );
};

export default WithdrawCurrency;
