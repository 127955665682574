import { Box } from "@mui/material";
import { FC } from "react";
import { matchPath, Outlet, useLocation, useMatch } from "react-router-dom";
import Header from "src/components/common/Header";
import HeaderLinks from "src/components/common/HeaderLinks";
import TabBar from "src/components/common/TabBar";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import ContactUsLink from "src/components/wallet/ContactUsLink";
import { useTranslation } from "react-i18next";
import { STAKING_HEADER_LINKS } from "src/config/links";

const StakingLayout: FC = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const stakingCurrency = useMatch("/staking/stake/:currency");
  const stakingDetails = useMatch("/staking/details/:currency");
  const stakedDetails = useMatch("/staking/staked-details/:currency");
  const stakingFinishedDetails = useMatch(
    "/staking/finished-staking-details/:currency"
  );

  const headerParamsFromPathname = () => {
    if (matchPath("/staking/rates", pathname)) {
      return { title: t("stakingLayout.pageTitles.rates") };
    }
    if (matchPath("/staking/summary", pathname)) {
      return { title: t("stakingLayout.pageTitles.summary") };
    }
    if (stakingCurrency) {
      return {
        title: t("stakingLayout.pageTitles.staking", {
          param: stakingCurrency?.params?.currency,
        }),
        backPath: "/staking",
      };
    }
    if (stakingDetails) {
      const searchParams = new URLSearchParams(search);
      const period = searchParams.get("period");
      const interestCurrency = searchParams.get("interestCurrency");
      const amount = searchParams.get("amount");
      const invitationCode = searchParams.get("invitationCode");
      return {
        title: t("stakingLayout.pageTitles.staking", {
          param: stakingDetails?.params?.currency,
        }),
        backPath: `/staking/stake/${stakingDetails?.params?.currency}?period=${period}&interestCurrency=${interestCurrency}&amount=${amount}&invitationCode=${invitationCode}`,
      };
    }
    if (stakedDetails) {
      return {
        title: t("stakingLayout.pageTitles.staking", {
          param: stakedDetails?.params?.currency,
        }),
        backPath: "/staking/summary",
      };
    }
    if (stakingFinishedDetails) {
      return {
        title: t("stakingLayout.pageTitles.finishedStaking", {
          param: stakingFinishedDetails?.params?.currency,
        }),
        backPath: "/staking/summary",
      };
    }
    return {
      title: t("stakingLayout.pageTitles.staking", { param: undefined }),
    };
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <Header
          {...headerParamsFromPathname()}
          showDashboardBtn={pathname === "/staking"}
        />
        {!stakingCurrency && !stakingDetails && !stakedDetails && (
          <HeaderLinks links={STAKING_HEADER_LINKS} />
        )}
      </LocalThemeProvider>
      <Outlet />
      <ContactUsLink />
      <TabBar />
    </Box>
  );
};

export default StakingLayout;
