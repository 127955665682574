import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { round } from "../../utils/helpers";
import { DEFAULT_ROUND } from "src/config";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type SuccessDialogContentProps = {
  baseCurrencyAmount: number;
  fee: number;
  baseCurrencyCode: string;
  purchasedCurrencyAmount: number;
  purchasedCurrencyCode: string;
  title: string;
  paymentMethod: string;
  feeMessage: string;
};

const SuccessDialogContent: FC<SuccessDialogContentProps> = ({
  baseCurrencyAmount,
  baseCurrencyCode,
  fee,
  purchasedCurrencyAmount,
  purchasedCurrencyCode,
  title,
  paymentMethod,
  feeMessage,
}) => {
  return (
    <>
      <Typography textAlign="center" variant="h6" color="text.primary2">
        {title}
      </Typography>
      <Divider sx={{ mt: "23px", mb: "17px" }} />
      <Typography textAlign="center" variant="subtitle1" color="text.primary2">
        {paymentMethod}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          mt: "30px",
        }}
      >
        <Typography variant="subtitle1" color="text.secondary" noWrap>
          {`${round(
            baseCurrencyAmount + fee,
            DEFAULT_ROUND
          )} ${baseCurrencyCode}`}
        </Typography>
        <Box color="text.secondary" display="inline-flex" fontSize="24px">
          <ArrowForwardIcon />
        </Box>
        <Typography
          variant="subtitle1"
          color="text.primary2"
          fontWeight="bold"
          noWrap
        >
          {`${round(
            purchasedCurrencyAmount,
            DEFAULT_ROUND
          )} ${purchasedCurrencyCode}`}
        </Typography>
      </Box>
      <Box>
        <Typography variant="micro" color="text.secondary">
          {feeMessage}: {`${fee} ${baseCurrencyCode}`}
        </Typography>
      </Box>
    </>
  );
};

export default SuccessDialogContent;
