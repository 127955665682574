import { loginFx } from ".";
import { effectErrorHandler } from "src/utils/helpers";
import AuthService from "src/services/Auth";

loginFx.use(async params => {
  try {
    const { data } = await AuthService.tokens(params);
    console.log("loginFx", data);
    const { accessToken, refreshToken } = data;
    localStorage.setItem("accessToken", accessToken);
    refreshToken && localStorage.setItem("refreshToken", refreshToken);
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
