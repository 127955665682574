import { NavLink } from "react-router-dom";
import { BiUserCircle, BiWallet } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { ReactComponent as Investment } from "src/assets/Investment.svg";
import { ReactComponent as InviteEarn } from "src/assets/InviteEarn.svg";

const TabBar = () => {
  const { t } = useTranslation();
  const activeStyle = {
    opacity: 1,
  };

  return (
    <div className="tab-bar">
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : {})}
        className="tab-bar__link"
        to="/wallet"
      >
        <BiWallet className="tab-bar__link-icon" /> {t("appTabBar.wallet")}
      </NavLink>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : {})}
        className="tab-bar__link"
        to="/staking"
      >
        <Investment className="tab-bar__link-icon" />{" "}
        {t("appTabBar.investment")}
      </NavLink>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : {})}
        className="tab-bar__link"
        to="/invite-earn"
      >
        <InviteEarn className="tab-bar__link-icon" />{" "}
        {t("appTabBar.inviteEarn")}
      </NavLink>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : {})}
        className="tab-bar__link"
        to="/profile"
      >
        <BiUserCircle className="tab-bar__link-icon" /> {t("appTabBar.profile")}
      </NavLink>
    </div>
  );
};

export default TabBar;
