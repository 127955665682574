import { useLocation, useNavigate } from "react-router-dom";
import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useEffect } from "react";
import PasswordChecklist from "react-password-checklist";

import "./index.scss";
import UnAuthLayout from "src/components/layouts/UnAuthLayout";
import Input from "src/components/common/Input";
import Button from "src/components/common/Button";
import {
  $password,
  $passwordError,
  $phone,
  getConfirmationCode,
  ScreenGate,
  setPassword,
  setPhone,
} from "src/store/account";
import { PhoneVerificationRequestType } from "src/services/User";
import ResetPasswordImg from "src/assets/register/ResetPassword.png";

const ResetPassword = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const backBtnName = searchParams.get("backBtnName");

  const { t } = useTranslation();
  const navigate = useNavigate();
  useGate(ScreenGate, {
    onSuccess: () => navigate("/confirm-code-reset-password"),
  });

  const phone = useStore($phone);

  useEffect(() => {
    setPhone(phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const password = useStore($password);
  const passwordError = useStore($passwordError);
  const loading = useStore(getConfirmationCode.pending);

  return (
    <UnAuthLayout
      className="reset-password"
      title={t("pageTitle.resetPassword")}
      img={ResetPasswordImg}
      backBtn={
        <div className="reset-password__back" onClick={() => navigate(-1)}>
          <HiOutlineChevronLeft className="reset-password__back-icon" />{" "}
          {backBtnName || t("buttons.backToLogin")}
        </div>
      }
      hAndroid
    >
      <div className="reset-password__text">
        {t("resetPassword.pageDescription")}
        <span className="reset-password__phone">{phone}</span>
      </div>
      <Input
        placeholder={t("placeholders.enterNewPassword")}
        value={password}
        onChange={value => setPassword(value)}
        isPassword
        error={passwordError}
        style={{ marginBottom: password.length > 0 ? 0 : "20px" }}
      />
      <>
        {password.length > 0 && (
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={6}
            value={password}
            className="setup-password__checklist"
            iconSize={14}
          />
        )}
      </>
      <Button
        onClick={() =>
          getConfirmationCode({
            phone,
            type: PhoneVerificationRequestType.ResetPassword,
            requirePassword: true,
            password,
          })
        }
        className="reset-password__btn"
        loading={loading}
        disabled={loading}
      >
        {t("buttons.sendConfirmationCode")}
      </Button>
    </UnAuthLayout>
  );
};

export default ResetPassword;
