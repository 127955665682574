import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { $isAuth } from "src/store/account";

export interface TotalEarnings {
  totalAmbassadorInterest: number;
  totalExpectedAmbassadorInterest: number;
  totalExpectedInterest: number;
  totalExpectedInviterInterest: number;
  totalInterest: number;
  totalInviterInterest: number;
}

export const TOTAL_EARNINGS = gql`
  query totalEarnings {
    totalEarnings {
      totalAmbassadorInterest
      totalExpectedAmbassadorInterest
      totalExpectedInterest
      totalExpectedInviterInterest
      totalInterest
      totalInviterInterest
    }
  }
`;

export const useTotalEarnings = () => {
  const isAuth = useStore($isAuth);
  const [getData, { data, error, loading }] = useLazyQuery(TOTAL_EARNINGS);

  useEffect(() => {
    isAuth && getData();
  }, [isAuth, getData]);

  return {
    totalEarnings: data?.totalEarnings,
    error,
    loading,
  };
};
