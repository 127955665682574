import { ChangeEvent, CSSProperties, FC, HTMLAttributes } from "react";
import cs from "classnames";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useToggle } from "rooks";

import "react-phone-number-input/style.css";
import "./index.scss";

interface IProps {
  className?: string;
  placeholder: string;
  isPassword?: boolean;
  error?: string;
  value: string;
  onChange: (value: string) => void;
  onlyValidValue?: (value: string) => string;
  unit?: string;
  style?: CSSProperties;
  inputMode?: HTMLAttributes<HTMLInputElement>["inputMode"];
  autoFocus?: boolean;
}

const Input: FC<IProps> = ({
  className,
  placeholder,
  isPassword,
  error,
  value,
  onChange,
  onlyValidValue,
  unit,
  style,
  inputMode = "text",
  autoFocus = false,
  ...rest
}) => {
  const [passHidden, togglePassHidden] = useToggle(true);

  const togglePassHiddenHandler = (
    e: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    togglePassHidden(e);
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (onlyValidValue) {
      newValue = onlyValidValue(e.target.value);
    }
    onChange(newValue);
  };

  return (
    <div className={cs("input-component", className)} style={style}>
      <input
        placeholder=" "
        type={isPassword && passHidden ? "password" : "text"}
        autoComplete="new-password"
        value={value}
        onChange={changeHandler}
        inputMode={inputMode}
        autoFocus={autoFocus}
        {...rest}
      />
      <div className="input-component__placeholder">{placeholder}</div>
      {unit && value && (
        <div
          className="input-component__unit"
          style={{
            marginLeft: `calc(15px + ${value.length}ch + 10px + ${
              value.length * 1.5
            }px)`,
          }}
        >
          {unit}
        </div>
      )}
      {error && <div className="input-error">{error}</div>}
      {isPassword &&
        (passHidden ? (
          <AiFillEye
            className="input-component__icon"
            onClick={e => togglePassHiddenHandler(e)}
          />
        ) : (
          <AiFillEyeInvisible
            className="input-component__icon"
            onClick={e => togglePassHiddenHandler(e)}
          />
        ))}
    </div>
  );
};

export default Input;
