import { FC, ReactNode } from "react";
import cs from "classnames";

import "./index.scss";
import TabBar from "src/components/common/TabBar";
import ContactUsLink from "src/components/wallet/ContactUsLink";
import Header from "src/components/common/Header";

interface IProps {
  className?: string;
  headerTitle?: string;
  headerBackPath?: any;
  headerShowBalance?: boolean;
  headerShowCurrencyBalance?: string;
  children: ReactNode;
}

const AuthLayout: FC<IProps> = ({
  className,
  headerTitle,
  headerBackPath,
  headerShowBalance,
  headerShowCurrencyBalance,
  children,
}) => {
  return (
    <div className={cs("auth-layout", className)}>
      <Header
        title={headerTitle}
        backPath={headerBackPath}
        showBalance={headerShowBalance}
        showCurrencyBalance={headerShowCurrencyBalance}
      />
      <div className="auth-layout__content">{children}</div>
      <ContactUsLink />
      <TabBar />
    </div>
  );
};

export default AuthLayout;
