import {
  Box,
  CircularProgress,
  Container,
  styled,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import Header from "src/components/common/Header";
import Space from "src/components/common/Space";
import TabBar from "src/components/common/TabBar";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import UserStakes from "src/components/UserStakes";
import { DEFAULT_ROUND } from "src/config";
import { TotalEarnings, useTotalEarnings } from "src/graphQl/totalEarnings";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";
import { round } from "src/utils/helpers";

const CardContainer = styled(Box)(({ theme }) => ({
  boxShadow: "1px 1px 7px rgba(0, 0, 0, 0.12)",
  borderRadius: theme.shape.borderRadius,
  padding: "18px 20px 16px 20px",
  backgroundColor: theme.palette.background.white,
}));

interface IProps {
  totalEarnings: TotalEarnings;
}

const Earnings: FC<IProps> = ({ totalEarnings }) => {
  const { t } = useTranslation();
  const baseCurrencySymbol = useBaseCurrencySymbol();

  const {
    totalAmbassadorInterest,
    totalExpectedAmbassadorInterest,
    totalExpectedInterest,
    totalExpectedInviterInterest,
    totalInterest,
    totalInviterInterest,
  } = totalEarnings;

  return (
    <div>
      <Container sx={{ pt: "27px", pb: "30px" }}>
        <CardContainer>
          <Space justify="space-between">
            <Typography variant="subtitle2" color="text.primary2">
              {t("earningsPage.currentExpectedEarning")}:
            </Typography>
            <Typography variant="subtitle2">
              {baseCurrencySymbol}
              {round(totalExpectedInterest, DEFAULT_ROUND)}
            </Typography>
          </Space>
          <Space justify="space-between" sx={{ mt: "7px" }}>
            <Typography variant="subtitle2" color="text.primary2">
              {t("earningsPage.sumFinishedStakes")}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary2">
              {baseCurrencySymbol}
              {round(totalInterest, DEFAULT_ROUND)}
            </Typography>
          </Space>
          <Space justify="space-between" sx={{ mt: "7px" }}>
            <Typography variant="subtitle2" color="text.primary2">
              {t("earningsPage.expectedAmbassadorEarning")}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary2">
              {baseCurrencySymbol}
              {round(totalExpectedAmbassadorInterest, DEFAULT_ROUND)}
            </Typography>
          </Space>
          <Space justify="space-between" sx={{ mt: "7px" }}>
            <Typography variant="subtitle2" color="text.primary2">
              {t("earningsPage.endedAmbassadorEarning")}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary2">
              {baseCurrencySymbol}
              {round(totalAmbassadorInterest, DEFAULT_ROUND)}
            </Typography>
          </Space>
          <Space justify="space-between" sx={{ mt: "7px" }}>
            <Typography variant="subtitle2" color="text.primary2">
              {t("earningsPage.expectedInviterEarning")}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary2">
              {baseCurrencySymbol}
              {round(totalExpectedInviterInterest, DEFAULT_ROUND)}
            </Typography>
          </Space>
          <Space justify="space-between" sx={{ mt: "7px" }}>
            <Typography variant="subtitle2" color="text.primary2">
              {t("earningsPage.endedInviterEarning")}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary2">
              {baseCurrencySymbol}
              {round(totalInviterInterest, DEFAULT_ROUND)}
            </Typography>
          </Space>
        </CardContainer>
        <Box p="0 10px" mt="30px">
          <UserStakes />
        </Box>
      </Container>
    </div>
  );
};

const ProtectedEarnings = () => {
  const { t } = useTranslation();
  const { totalEarnings, loading: loadingTotalEarnings } = useTotalEarnings();

  if (loadingTotalEarnings || !totalEarnings) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <Header backPath={-1} title={t("earnings")} />
      </LocalThemeProvider>
      <Earnings totalEarnings={totalEarnings} />
      <TabBar />
    </Box>
  );
};

export default ProtectedEarnings;
