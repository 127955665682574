import { toast } from "react-toastify";

import StakingService from "src/services/Staking";
import { effectErrorHandler } from "src/utils/helpers";
import { StakeConfirmGate, cancelStake, stake } from ".";

stake.use(async params => {
  try {
    await StakingService.stake(params);
    toast.success("Staked successful");
    const { onConfirm } = StakeConfirmGate.state.getState();
    onConfirm();
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

cancelStake.use(async params => {
  try {
    await StakingService.cancelStake(params);
    toast.success("Canceled successfully");
    const { onConfirm } = StakeConfirmGate.state.getState();
    onConfirm();
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
