import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { $isAuth } from "src/store/account";
import { apolloClient } from ".";
import { IUser } from "./profile";

enum KycDocumentType {
  GOVERNMENT_ID_BACK = "GOVERNMENT_ID_BACK",
  GOVERNMENT_ID_FRONT = "GOVERNMENT_ID_FRONT",
  SELFIE = "SELFIE",
}

export interface IKycDocument {
  contentType: string;
  createdDate: string;
  fileId: string;
  id: number;
  type: KycDocumentType;
  updatedDate: string;
  user: IUser;
  userId: number;
}

export enum KycStatus {
  NONE = "NONE",
  PENDING = "PENDING",
  DECLINE = "DECLINE",
  APPROVED = "APPROVED",
}

export interface IKyc {
  documents: IKycDocument[];
  lastAdminComment?: string;
  status: KycStatus;
}

export const KYC = gql`
  query Kyc {
    kyc {
      documents {
        fileId
        type
        id
      }
      lastAdminComment
      status
    }
  }
`;

export const useKyc = () => {
  const isAuth = useStore($isAuth);
  const [getKyc, { data, error, loading }] = useLazyQuery<{
    kyc: IKyc;
  }>(KYC);

  useEffect(() => {
    isAuth && getKyc();
  }, [isAuth, getKyc]);

  return {
    kyc: data?.kyc,
    error,
    loading,
  };
};

export const updateKycStatus = () => {
  const data = apolloClient.readQuery({
    query: KYC,
  });
  apolloClient.writeQuery({
    query: KYC,
    data: {
      kyc: { ...data.kyc, status: KycStatus.PENDING, lastAdminComment: null },
    },
  });
};
