import { gql } from "@apollo/client";

export type CreateStripePaymentSessionQueryResponse = {
  stripeCheckoutSessionUrl: string;
};

export type CreateStripePaymentSessionQueryProps = {
  request: {
    amount: number;
    currency: "T-Bean" | "USDT";
    successUrl: string;
    failUrl: string;
  };
};

export const createStripePaymentSessionQuery = gql`
  query createStripePaymentSession(
    $request: StripeCheckoutSessionRequestInput!
  ) {
    stripeCheckoutSessionUrl(request: $request)
  }
`;

export type StripePayment = {
  userId: number;
  sessionId: string;
  baseCurrencyAmount: number;
  baseCurrencyFeeAmount: number;
  baseCurrencyNetAmount: number;
  purchasedCurrencyId: number;
  purchasedCurrencyCode: string;
  purchasedCurrencyAmount: number;
  id: number;
  createdDate: string;
  updatedDate: string;
};

export type GetStripePaymentResponse = {
  stripePayments: {
    nodes: [StripePayment];
  };
};
export type GetStripePaymentProps = {
  sessionId: string;
};

export const getStripePayment = gql`
  query stripePaments($sessionId: String!) {
    stripePayments(where: { sessionId: { eq: $sessionId } }) {
      nodes {
        userId
        sessionId
        baseCurrencyAmount
        baseCurrencyFeeAmount
        baseCurrencyNetAmount
        purchasedCurrencyId
        purchasedCurrencyCode
        purchasedCurrencyAmount
        id
        createdDate
        updatedDate
      }
    }
  }
`;
