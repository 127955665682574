import { Container, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Header from "src/components/common/Header";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import "./index.scss";

function RewardTable() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%", fontSize: "12px" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutAmbassador.rewardTable.headCell1")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutAmbassador.rewardTable.headCell2")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutAmbassador.rewardTable.headCell3")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutAmbassador.rewardTable.headCell4")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutAmbassador.rewardTable.headCell5")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutAmbassador.rewardTable.headCell6")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell sx={{ fontSize: "12px" }} component="th" scope="row">
              {t("aboutAmbassador.rewardTable.bodyCell1")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>8%</TableCell>
            <TableCell sx={{ fontSize: "12px" }}>10%</TableCell>
            <TableCell sx={{ fontSize: "12px" }}>12%</TableCell>
            <TableCell sx={{ fontSize: "12px" }}>15%</TableCell>
            <TableCell sx={{ fontSize: "12px" }}>30%</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Title = styled("div")({
  color: "#ebbb71",
  fontSize: "16px",
  fontWeight: 500,
  margin: "15px 0 5px",
});

const AboutAmbassador = () => {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
      className="about-ambassador"
    >
      <LocalThemeProvider theme="dark">
        <Header backPath={-1} title={t("aboutAmbassador.headerTitle")} />
      </LocalThemeProvider>
      <Container
        sx={{
          background: "#fff",
          p: "16px",
          fontSize: "14px",
          lineHeight: 1.5,
        }}
      >
        <Title>{t("aboutAmbassador.title1")}</Title>
        {t("aboutAmbassador.section1Paragraph1")}
        <br />
        {t("aboutAmbassador.section1Paragraph2")}
        <Title>{t("aboutAmbassador.title2")}</Title>
        {t("aboutAmbassador.section1Paragraph2Part2")}
        <ul>
          <li>{t("aboutAmbassador.section1Paragraph3li1")}</li>
          <li>{t("aboutAmbassador.section1Paragraph3li2")}</li>
          <li>{t("aboutAmbassador.section1Paragraph3li3")}</li>
          <li>{t("aboutAmbassador.section1Paragraph3li4")}</li>
          <li>{t("aboutAmbassador.section1Paragraph3li5")}</li>
          <li>{t("aboutAmbassador.section1Paragraph3li6")}</li>
        </ul>
        <Title>{t("aboutAmbassador.title3")}</Title>
        <p>{t("aboutAmbassador.section2Paragraph1")}</p>
        <br />
        {t("aboutAmbassador.section2Paragraph2")}
        <br />
        {t("aboutAmbassador.section2Paragraph3")}
        <Title>{t("aboutAmbassador.title4")}</Title>
        <p>{t("aboutAmbassador.section3Paragraph1")}</p>
        <p>{t("aboutAmbassador.section3Paragraph2")}</p>
        <p>{t("aboutAmbassador.section3Paragraph3")}</p>
        <p>{t("aboutAmbassador.section3Paragraph4")}</p>
        <br />
        {t("aboutAmbassador.section3Paragraph5")}
        <br />
        {t("aboutAmbassador.section3Paragraph6")}
        <RewardTable />
      </Container>
    </Container>
  );
};

export default AboutAmbassador;
