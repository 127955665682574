import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { round } from "src/utils/helpers";
import { useAccounts } from "src/graphQl/profile";
import { DEFAULT_ROUND } from "src/config";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";

interface IProps {
  title?: string;
  backPath?: any;
  showBalance?: boolean;
  showDashboardBtn?: boolean;
  showCurrencyBalance?: string;
}

const Header: FC<IProps> = ({
  title,
  backPath,
  showBalance,
  showDashboardBtn,
  showCurrencyBalance,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accounts } = useAccounts();
  const baseCurrencySymbol = useBaseCurrencySymbol();

  const totalBalanceInBaseCurrency =
    accounts?.reduce((acc: number, cur: any) => {
      acc += cur.baseBalance;
      return acc;
    }, 0) || 0;

  const roundedTotalBalanceInBaseCurrency = round(
    totalBalanceInBaseCurrency,
    DEFAULT_ROUND
  );

  const currencyData =
    showCurrencyBalance &&
    accounts?.find(i => i.currency.name === showCurrencyBalance);

  return (
    <div className="header">
      {showDashboardBtn && (
        <div
          className="header__dashboard-link"
          onClick={() => navigate("/dashboard")}
        >
          <HiOutlineChevronLeft className="header__dashboard-btn" />
          Dashboard
        </div>
      )}
      <div
        className="header__title"
        style={{ paddingTop: showDashboardBtn ? "20px" : "25px" }}
      >
        {backPath && (
          <HiOutlineChevronLeft
            className="header__back-btn"
            onClick={() => navigate(backPath)}
          />
        )}
        {title}
      </div>
      {showBalance && (
        <div className="header__balance">
          <div className="header__balance-description">
            {t("header.totalAssets")}, {baseCurrencySymbol}
          </div>
          <div className="header__balance-container">
            <span className="header__balance-unit">{baseCurrencySymbol}</span>
            <span className="header__balance-value">
              {roundedTotalBalanceInBaseCurrency}
            </span>
          </div>
        </div>
      )}
      {showCurrencyBalance && currencyData && (
        <div className="header__currency-balance">
          <span className="header__balance-value">
            {round(currencyData.balance, DEFAULT_ROUND)}
          </span>
          <span className="header__currency-balance-name">
            {showCurrencyBalance}
          </span>
        </div>
      )}
    </div>
  );
};

export default Header;
