import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { $isAuth } from "src/store/account";

export const AMBASSADOR_REWARDS = gql`
  query ambassadorRewards {
    ambassadorRewards
  }
`;

export const useAmbassadorRewards = () => {
  const isAuth = useStore($isAuth);
  const [getRewards, { data, error, loading }] =
    useLazyQuery(AMBASSADOR_REWARDS);

  useEffect(() => {
    isAuth && getRewards();
  }, [isAuth, getRewards]);

  return {
    ambassadorRewards: data?.ambassadorRewards || 0,
    error,
    loading,
  };
};
