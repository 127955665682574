import { createTheme, ThemeOptions } from "@mui/material/styles";

const commonThemePalette = createTheme({
  shape: {
    borderRadius: "8px",
  },
  palette: {
    primary: {
      main: "#EBBB71",
    },
    secondary: {
      main: "#6200EE",
    },
    text: {
      primary: "#20202c",
      primary2: "#4b4b4c",
      secondary: "rgba(75, 75, 76, 0.4)",
      inversed: "#fff",
    },
    error: {
      main: "#ff6565",
    },
    background: {
      default: "#f5f5f8",
      white: "#fff",
      dark: "#20202c",
    },
  },
});

const commonTheme = createTheme(commonThemePalette, {
  typography: {
    h5: {
      fontSize: "24px",
    },
    h6: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "24px",
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    micro: {
      fontSize: "10px",
      lineHeight: "16px",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ".MuiInputLabel-root + & .MuiFilledInput-input": {
            paddingTop: "25px",
            paddingBottom: "8px",
          },
          "&:before": {
            display: "none",
          },
        },
        input: {
          paddingTop: "17px",
          paddingBottom: "16px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("xs")]: {
            maxWidth: "100%",
            paddingLeft: "15px",
            paddingRight: "15px",
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: ({ theme }) => ({
          "&.Mui-selected": {
            color: theme.palette.primary.main,
          },
          color: theme.palette.primary.dark,
        }),
        root: ({ theme }) => ({
          "&.Mui-disabled": {
            color: theme.palette.primary.dark,
            opacity: 0.3,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "4px",
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: {
            variant: "standard",
          },
          style: {
            ".MuiInputBase-root": {
              fontWeight: "500",
            },
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        standard: {
          opacity: 0.9,
          "&:focus": {
            background: "transparent",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { size: "medium" },
          style: {
            fontSize: "14px",
          },
        },
        {
          props: { size: "small", variant: "text" },
          style: {
            minWidth: "40px",
          },
        },
        {
          props: { variant: "text" },
          style: {
            textTransform: "initial",
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: "10px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          width: "310px",
          maxWidth: "100%",
          padding: "0px 30px 10px 30px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "10px 30px 30px 30px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "30px 30px 15px 30px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.main,
        }),
      },
    },
  },
} as ThemeOptions);

export default commonTheme;

const lightThemePalette = createTheme(commonTheme, {
  palette: {
    mode: "light",
    text: {
      primary: "#20202C",
    },
  },
} as ThemeOptions);

export const lightTheme = createTheme(lightThemePalette, {
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: "rgba(40, 42, 43, 0.12)",
          color: "#282A2B",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#282A2B",
          "&.Mui-focused": {
            color: "#282A2B",
          },
        },
      },
    },
  },
});

const darkThemePallete = createTheme(commonTheme, {
  palette: {
    mode: "dark",
    info: {
      main: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255,255,255,0.6)",
    },
    background: {
      default: "#20202C",
    },
  },
} as ThemeOptions);

export const darkTheme = createTheme(darkThemePallete, {
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: "rgba(255, 255, 255, 0.12);",
          color: "rgba(255, 255, 255, 0.87)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.87)",
          "&.Mui-focused": {
            color: "#F5F5F8",
          },
        },
      },
    },
  },
});
