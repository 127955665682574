import { AxiosResponse } from "axios";
import $api from "./http";

export interface IStakeParams {
  templateId: number;
  interestCurrencyId: number;
  amount: number;
  invitationCode?: string;
}

export interface ICancelStakeParams {
  stakeId: number;
  transactionPassword: string;
}

export default class StakingService {
  static stake(params: IStakeParams): Promise<AxiosResponse<number>> {
    return $api.post<number>("/Staking", params);
  }
  static cancelStake(
    params: ICancelStakeParams
  ): Promise<AxiosResponse<number>> {
    return $api.post<number>("/Staking/cancel", params);
  }
}
