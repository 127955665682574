import { useNavigate, useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { FiCopy } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import "./index.scss";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import { useCurrentUser } from "src/graphQl/profile";
import { ReactComponent as WarningIcon } from "src/assets/Warning.svg";

const DepositCurrency = () => {
  const { t } = useTranslation();
  const { currency } = useParams();
  const navigate = useNavigate();

  const { user, loading } = useCurrentUser();
  const address = user?.address || "";
  const shortAddress = `${address.slice(0, 5)}...${address.slice(-11)}`;

  // if (user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED) {
  //   return <KycRequired kycStatus={user.kycStatus} />;
  // }

  const downloadQR = () => {
    const canvas = document.querySelector(
      ".deposit-currency__qr-code > canvas"
    ) as HTMLCanvasElement;
    const pngUrl =
      canvas &&
      canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="deposit-currency">
      <div className="deposit-currency__card">
        <div className="deposit-currency__title">
          <CurrencyIcon currency={currency as string} />
          {t("depositCurrencyPage.pageTitle", { currency })}
        </div>
        {!loading && (
          <div className="deposit-currency__qr-code">
            <QRCodeCanvas value={address} size={162} />
          </div>
        )}
        {!loading && (
          <div className="deposit-currency__actions">
            <CopyToClipboard
              text={address}
              onCopy={() =>
                toast.success(t("depositCurrencyPage.successCopyMessage"))
              }
            >
              <div className="deposit-currency__copy-address">
                {shortAddress}{" "}
                <FiCopy className="deposit-currency__copy-icon" />
              </div>
            </CopyToClipboard>

            <div className="deposit-currency__save-qr" onClick={downloadQR}>
              {t("buttons.saveQrCode")}
            </div>
          </div>
        )}
        <div className="deposit-currency__text">
          {t("depositCurrencyPage.sendCurrencyMessage", { currency })}
        </div>
        <div className="deposit-currency__warning">
          <WarningIcon className="deposit-currency__warning-icon" />
          <div className="deposit-currency__warning-text">
            {t("depositCurrencyPage.otherBlockchainWarning")}
          </div>
        </div>
        <div
          className="deposit-currency__close"
          onClick={() => navigate("/wallet/deposit")}
        >
          {t("buttons.close")}
        </div>
      </div>
    </div>
  );
};

export default DepositCurrency;
