import { useState, useEffect } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { useStore } from "effector-react";
import { AiOutlineSwap } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "./index.scss";
import Button from "src/components/common/Button";
import { ReactComponent as TBeans } from "src/assets/wallet/TBean.svg";
import { isValidNumber } from "src/utils/helpers";
import SuccessDialog from "./SuccessDialog";
import { useCurrentUser } from "src/graphQl/profile";
import { transferTbeans } from "src/store/wallet";
import { TWEEBAA_ACCOUNT_BALANCES } from "src/graphQl/tweeBaaAccountBalances";
import Loader from "src/components/common/Loader";
import { KycStatus } from "src/graphQl/kyc";

enum CryptoCurrency {
  "USDT" = "USDT",
  "T-Bean" = "T-Bean",
}

const currenciesLimits: { [k in CryptoCurrency]: [number, number] } = {
  USDT: [1, 10000],
  "T-Bean": [1, 10000],
};

const TransferTbean = () => {
  const { t } = useTranslation();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const { user, loading } = useCurrentUser();
  const [amount, setAmount] = useState("");
  const [accounts, setAccounts] = useState([
    { name: "TweeBank", balance: 0 },
    { name: "TweeBaa account", balance: 0 },
  ]);
  // const amountLimits = currenciesLimits[fromCurrency];
  const amountLimits = currenciesLimits["T-Bean"];
  const transferring = useStore(transferTbeans.pending);

  const [getBalances, { data, loading: loadingBalances }] = useLazyQuery(
    TWEEBAA_ACCOUNT_BALANCES
  );

  const appConnected = !!user?.tweeBaaAppUser;
  const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;

  useEffect(() => {
    appConnected && getBalances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConnected]);

  const balances = data?.tweeBaaAccountBalances;

  useEffect(() => {
    // if (balances?.tweeBaaBalance && balances?.tweeBankBalance) {
    if (hash === "#withdraw") {
      setAccounts([
        { name: "TweeBank", balance: balances?.tweeBankBalance || 0 },
        { name: "TweeBaa account", balance: balances?.tweeBaaBalance || 0 },
      ]);
    } else {
      setAccounts([
        { name: "TweeBaa account", balance: balances?.tweeBaaBalance || 0 },
        { name: "TweeBank", balance: balances?.tweeBankBalance || 0 },
      ]);
    }
    // }
  }, [balances, hash]);

  const amountValid =
    Number(amount) >= amountLimits[0] && Number(amount) <= amountLimits[1];

  const updateAmount = (percent: number) => {
    const newAmount = accounts[0].balance
      ? new BigNumber(accounts[0].balance)
          .div(100)
          .multipliedBy(percent)
          .toString()
      : "0";
    setAmount(newAmount);
  };

  const transferHandler = () => {
    if (!user) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    if (kycRequired) {
      return navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
    }
    if (!appConnected) {
      return navigate(`/something-required#tweebaa`, {
        state: { prevPath: pathname },
      });
    }
    if (!balances) {
      return toast.warn("System isn't available right now. Come back later.");
    }
    transferTbeans({
      amount: +amount,
      toTweeBaa: hash === "#withdraw",
    });
  };

  const onCloseSuccess = () => {
    setAmount("");
    getBalances();
  };

  if (loading || loadingBalances) {
    return <Loader />;
  }

  // if (!balances) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         gap: "10px",
  //         mb: "30px",
  //       }}
  //     >
  //       <Typography variant="h6" align="center">
  //         System error
  //       </Typography>
  //     </Box>
  //   );
  // }

  // const modalName = getModalName({ user: !!user, kycRequired, appConnected });

  return (
    <Box className="transfer-tbean">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          mb: "30px",
        }}
      >
        <TBeans className="transfer-tbean__currency-icon" />
        <Typography variant="h6" align="center">
          {t("transferPage.title", { currencyName: "T-Bean" })}
        </Typography>
      </Box>
      <div className="transfer-tbean__accounts">
        <div className="transfer-tbean__account-container">
          {t("transferPage.from")}
          <div className="transfer-tbean__account-info">
            <div className="transfer-tbean__account-info-name">
              {accounts[0].name}
            </div>
            <div className="transfer-tbean__account-info-balance">
              {t("transferPage.available")}
              <div>{accounts[0].balance} T-Bean</div>
            </div>
          </div>
        </div>
        <AiOutlineSwap
          onClick={() =>
            hash === "#withdraw" ? navigate("#deposit") : navigate("#withdraw")
          }
          className="transfer-tbean__swap-icon"
        />
        <div className="transfer-tbean__account-container">
          {t("transferPage.to")}
          <div className="transfer-tbean__account-info">
            <div className="transfer-tbean__account-info-name">
              {accounts[1].name}
            </div>
            <div className="transfer-tbean__account-info-balance">
              {t("transferPage.available")}
              <div>{accounts[1].balance} T-Bean</div>
            </div>
          </div>
        </div>
      </div>
      <TextField
        value={amount}
        variant="filled"
        onChange={e =>
          isValidNumber(e.target.value) && setAmount(e.target.value)
        }
        InputProps={{
          endAdornment: <InputAdornment position="end"></InputAdornment>,
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "^([0-9]+([.][0-9]*)?|[.][0-9]+)?$",
        }}
        sx={{ mt: "30px" }}
        error={!amountValid && !!amount}
        helperText={
          !amountValid &&
          !!amount &&
          `Invalid value: min ${amountLimits[0]}, max ${amountLimits[1]}`
        }
        fullWidth
        placeholder={t("transferPage.amountPlaceholder")}
      />
      <div className="transfer-tbean__possible-parts">
        {[
          { title: "25%", value: 25 },
          { title: "50%", value: 50 },
          { title: "All", value: 100 },
        ].map(i => (
          <div key={i.value} onClick={() => updateAmount(i.value)}>
            {i.title}
          </div>
        ))}
      </div>
      <Button
        onClick={transferHandler}
        className="transfer-tbean__btn"
        disabled={!amount || !amountValid || transferring}
        loading={transferring}
      >
        {t("transferPage.transfer")}
      </Button>

      <Button
        onClick={() => navigate("/staking")}
        className="transfer-tbean__btn button_outline"
      >
        {t("buttons.stake")}
      </Button>

      <SuccessDialog onCloseFn={onCloseSuccess} />
      {/* <CommonModal modalName={modalName} /> */}
    </Box>
  );
};

export default TransferTbean;
