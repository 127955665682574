import { SxProps, Button } from "@mui/material";
import { ReactChild, FC, useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

type Props = {
  textForCopy: string;
  children?: ReactChild;
  successText?: ReactChild;
  sx?: SxProps;
};

const CopyButton: FC<Props> = ({ textForCopy, children, successText, sx }) => {
  const { t } = useTranslation();

  const successTextDefault = t("copyButton.success");
  const defaultText = t("copyButton.default");

  const [copied, setCopied] = useState(false);

  const copyClickHandler = () => {
    navigator.clipboard.writeText(textForCopy);
    setCopied(true);
  };
  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  return (
    <Button
      color={copied ? "primary" : "secondary"}
      endIcon={copied ? <DoneIcon /> : <ContentCopyIcon />}
      onClick={copyClickHandler}
      sx={sx}
    >
      {copied ? successText || successTextDefault : children || defaultText}
    </Button>
  );
};
export default CopyButton;
