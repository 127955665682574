import { createEffect, createEvent, restore } from "effector";
import { createGate } from "effector-react";

import { ITransaction } from "src/graphQl/operations";
import { IWithdrawalRequestParams } from "src/services/Account";
import { ISwapParams } from "src/services/Exchange";
import { ITransferTbeansParams } from "src/services/User";
import { cancelStake, StakeConfirmGate } from "../staking";

export const WithdrawCurrencyGate = createGate();

export const requestWithdraw = createEffect<IWithdrawalRequestParams, void>();

export const setAmount = createEvent<string>();
export const $amount = restore(setAmount, "").reset([
  WithdrawCurrencyGate.close,
  requestWithdraw.done,
]);

export const setWithdrawalAddress = createEvent<string>();
export const $withdrawalAddress = restore(setWithdrawalAddress, "").reset([
  WithdrawCurrencyGate.close,
  requestWithdraw.done,
]);

export const setWithdrawSuccessModal = createEvent<boolean>();
export const $withdrawSuccessModal = restore(setWithdrawSuccessModal, false).on(
  requestWithdraw.done,
  () => true
);

export const transferTbeans = createEffect<ITransferTbeansParams, void>();
export const setTransferSuccessModal = createEvent<boolean>();
export const $transferSuccessModal = restore(setTransferSuccessModal, false).on(
  transferTbeans.done,
  () => true
);

export const swap = createEffect<ISwapParams, void>();
export const setSwapSuccessModal = createEvent<boolean>();
export const $swapSuccessModal = restore(setSwapSuccessModal, false).on(
  swap.done,
  () => true
);

export const setOperationInfoModal = createEvent<{
  txs: ITransaction[];
  isPending?: boolean;
} | null>();
export const $operationInfoModal = restore(setOperationInfoModal, null);

export const setShowConfirmTx = createEvent<boolean>();
export const $showConfirmTx = restore(setShowConfirmTx, false).reset([
  WithdrawCurrencyGate.close,
  requestWithdraw.done,
  cancelStake.done,
  swap.done,
  StakeConfirmGate.close,
]);
