import { useNavigate } from "react-router-dom";
import { useGate, useStore } from "effector-react";

import "./index.scss";
import Logo from "src/assets/register/SetupPassword.png";
import UnAuthLayout from "src/components/layouts/UnAuthLayout";
import Input from "src/components/common/Input";
import Button from "src/components/common/Button";
import {
  $password,
  $passwordError,
  ScreenGate,
  setPassword,
  setupTxPassword,
} from "src/store/account";
import { useTranslation } from "react-i18next";

const SetupTxPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useGate(ScreenGate, { onSuccess: () => navigate("/") });
  const loading = useStore(setupTxPassword.pending);

  const password = useStore($password);
  const passwordError = useStore($passwordError);

  return (
    <UnAuthLayout
      className="setup-password"
      img={Logo}
      title={t("pageTitle.setupTransactionPassword")}
      hAndroid
    >
      <div className="setup-password__description">
        {t("setupTransactionPassword.note")}
      </div>
      <Input
        placeholder={t("placeholders.createTransactionPassword")}
        isPassword
        error={passwordError}
        value={password}
        onChange={value => setPassword(value)}
      />

      <Button
        onClick={() => setupTxPassword({ password })}
        className="setup-password__register-btn"
        loading={loading}
        disabled={loading}
      >
        {t("buttons.setUp")}
      </Button>
    </UnAuthLayout>
  );
};

export default SetupTxPassword;
