// import { useGate, useStore } from "effector-react";
import { useNavigate } from "react-router-dom";
// import { isValidPhoneNumber } from "react-phone-number-input";
// import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useEffect } from "react";
// import { useTranslation } from "react-i18next";

import { API_URL, LOGIN_REDIRECT_URL } from "src/config";

// import "./index.scss";
// import Logo from "src/assets/register/CreateAccount.png";
// import Button from "src/components/common/Button";
// import LoginLink from "src/components/common/LoginRegisterLink";
// import PhoneInput from "src/components/common/PhoneInput";
// import {
//   $phone,
//   $phoneError,
//   getConfirmationCode,
//   setPhone,
//   ScreenGate,
//   setFirstName,
//   $firstName,
//   $firstNameError,
//   $lastName,
//   $lastNameError,
//   setLastName,
// } from "src/store/account";
// import UnAuthLayout from "src/components/layouts/UnAuthLayout";
// import { PhoneVerificationRequestType } from "src/services/User";
// import Input from "src/components/common/Input";
// import { CAPTCHA_SITEKEY, USE_TEST_NUMBER } from "src/config";

const CreateAccount = () => {
  const navigate = useNavigate();
  // useGate(ScreenGate, { onSuccess: () => navigate("/confirm-code") });
  // const { search } = useLocation();
  // const searchParams = new URLSearchParams(search);
  // const invitationCode = searchParams.get("invitationCode");

  useEffect(() => {
    // invitationCode && localStorage.setItem("invitationCode", invitationCode);
    window.open(API_URL + `/Auth/login?redirectUrl=${LOGIN_REDIRECT_URL}`, "_self");
    navigate("/");
  }, []);

  // const phone = useStore($phone);
  // const error = useStore($phoneError);

  // const firstName = useStore($firstName);
  // const firstNameError = useStore($firstNameError);

  // const lastName = useStore($lastName);
  // const lastNameError = useStore($lastNameError);

  // const loading = useStore(getConfirmationCode.pending);

  // const [captchaToken, setCaptchaToken] = useState("");
  // const captchaRef: any = useRef(null);

  // const { t } = useTranslation();

  // const onLoad = () => {
  //   // this reaches out to the hCaptcha JS API and runs the
  //   // execute function on it. you can use other functions as
  //   // documented here:
  //   // https://docs.hcaptcha.com/configuration#jsapi
  //   captchaRef?.current?.execute();
  // };

  // // useEffect(() => {
  // //   if (captchaToken) {
  // //     console.log(`hCaptcha Token: ${captchaToken}`);
  // //     $api.post(`${API_URL}/captcha?captchaResponse=${captchaToken}`);
  // //   }
  // // }, [captchaToken]);

  return null;

  // return (
  //   <UnAuthLayout
  //     className="create-account"
  //     img={Logo}
  //     title={t("pageTitle.createAccount")}
  //   >
  //     <Input
  //       placeholder={t("placeholders.firstName")}
  //       error={firstNameError}
  //       value={firstName}
  //       onChange={value => setFirstName(value)}
  //     />
  //     <Input
  //       placeholder={t("placeholders.lastName")}
  //       error={lastNameError}
  //       value={lastName}
  //       onChange={value => setLastName(value)}
  //       style={{ marginBottom: 0 }}
  //     />
  //     <div className="create-account__hint">{t("registerCountryWarning")}</div>
  //     <PhoneInput
  //       onChange={setPhone}
  //       value={phone}
  //       placeholder={t("placeholders.phoneNumber")}
  //       error={error}
  //     />
  //     <div className="create-account__captcha">
  //       <HCaptcha
  //         sitekey={CAPTCHA_SITEKEY}
  //         onLoad={onLoad}
  //         onVerify={setCaptchaToken}
  //         ref={captchaRef}
  //       />
  //     </div>
  //     <Button
  //       onClick={() =>
  //         getConfirmationCode({
  //           phone,
  //           type: PhoneVerificationRequestType.Signup,
  //           isCreateAccount: true,
  //           firstName,
  //           lastName,
  //           captchaToken,
  //         })
  //       }
  //       className="create-account__send-btn"
  //       loading={loading}
  //       disabled={
  //         loading ||
  //         (!isValidPhoneNumber(phone) && !USE_TEST_NUMBER) ||
  //         !captchaToken
  //       }
  //     >
  //       {t("buttons.sendConfirmationCode")}
  //     </Button>
  //     <LoginLink />
  //   </UnAuthLayout>
  // );
};

export default CreateAccount;
