import AccountService from "src/services/Account";
import ExchangeService from "src/services/Exchange";
import UserService from "src/services/User";
import { requestWithdraw, swap, transferTbeans } from "src/store/wallet";
import { effectErrorHandler } from "src/utils/helpers";

requestWithdraw.use(async ({ currencyId, amount, to, transactionPassword }) => {
  try {
    await AccountService.withdrawalRequest({
      currencyId,
      amount,
      to,
      transactionPassword,
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

swap.use(async params => {
  try {
    await ExchangeService.swap(params);
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

transferTbeans.use(async params => {
  try {
    await UserService.transferTbeans(params);
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
