import { FC } from "react";
import dayjs from "dayjs";
import cs from "classnames";

import "./index.scss";
import { IOperation } from "src/graphQl/operations";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import { setOperationInfoModal } from "src/store/wallet";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";
import { round } from "src/utils/helpers";
import { DEFAULT_ROUND } from "src/config";

interface IProps {
  hideIcon?: boolean;
  hideUsd?: boolean;
  className?: string;
  item: IOperation;
}

const Operation: FC<IProps> = ({ hideIcon, hideUsd, className, item }) => {
  const {
    createdDate,
    discriminator,
    payPalData,
    stakeData,
    stripeData,
    withdrawalData,
    transactions,
  } = item;
  const baseCurrencySymbol = useBaseCurrencySymbol();
  const currencyNameForIcon = stripeData
    ? "stripe"
    : stakeData?.currency?.name ||
      payPalData?.baseCurrencyCode ||
      transactions[0]?.currency?.name;

  const currencyName =
    stripeData?.purchasedCurrencyCode ||
    stakeData?.currency?.name ||
    payPalData?.purchasedCurrencyCode ||
    transactions[0]?.currency?.name;

  const amount =
    stripeData?.purchasedCurrencyAmount ||
    payPalData?.purchasedCurrencyAmount ||
    stakeData?.amount ||
    withdrawalData?.amount ||
    transactions[0].amount;

  const baseAmount =
    stripeData?.baseCurrencyAmount ||
    payPalData?.baseCurrencyAmount ||
    stakeData?.baseAmount ||
    withdrawalData?.baseAmount ||
    transactions[0].baseAmount;

  return (
    <div
      className={cs("operation", className)}
      onClick={() => setOperationInfoModal({ txs: transactions })}
    >
      <div className="operation__icon-type-wrapper">
        {!hideIcon && currencyNameForIcon && (
          <CurrencyIcon
            currency={currencyNameForIcon}
            style={{ width: 24, height: 24 }}
          />
        )}
        <div className="operation__type">
          {discriminator.replace("Operation", "")}
        </div>
      </div>
      <div className="operation__date">
        {dayjs(createdDate).format("DD/MM HH:mm:ss")}
      </div>
      <div className="operation__amounts">
        <div className="operation__amount">
          {round(amount, DEFAULT_ROUND)} {currencyName}
        </div>
        {!hideUsd && (
          <div className="operation__amount-usd">
            {round(baseAmount, DEFAULT_ROUND)} {baseCurrencySymbol}
          </div>
        )}
      </div>
    </div>
  );
};

export default Operation;
