import { toast } from "react-toastify";
import HomeService from "src/services/Home";
import { effectErrorHandler, isValidEmail } from "src/utils/helpers";
import { contactUs } from ".";

contactUs.use(async params => {
  try {
    if (!params.name) {
      throw Error("Enter your name please");
    }
    if (!isValidEmail(params.email)) {
      throw Error("Enter valid email");
    }
    if (!params.message) {
      throw Error("Enter your message please");
    }
    await HomeService.contactUs(params);
    toast.success("Your message sent successfully");
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
