import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStore } from "effector-react";

import "./index.scss";
import { $modal, setModal } from "src/store/common";
import Space from "src/components/common/Space";
import CopyButton from "src/components/common/CopyButton";
import { useCurrentUser } from "src/graphQl/profile";
import { ModalNames } from "src/types/common";
import { useTranslation } from "react-i18next";

const InviteFriends = () => {
  const { t } = useTranslation();
  const modal = useStore($modal);
  const { user } = useCurrentUser();

  const onClose = () => {
    setModal(null);
  };

  return (
    <Dialog open={modal?.name === ModalNames.InviteFriends}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 5 }}>
        <Typography variant="h6" align="left">
          {t("inviteFriends.title")}
        </Typography>
        <Typography variant="subtitle2" color="text.primary2" mt="12px">
          {t("inviteFriends.instruction")}
        </Typography>
        <Space direction="vertical" gap={5} sx={{ mt: "22px", width: "100%" }}>
          <Space justify="space-between" align="center" gap={5}>
            <Typography variant="subtitle2" noWrap>
              {t("inviteFriends.referalCodeLabel")}: {user?.referralCode}
            </Typography>
            <CopyButton textForCopy={user?.referralCode || ""} />
          </Space>
          <Space justify="space-between" align="center" gap={5}>
            <Typography variant="subtitle2">
              {t("inviteFriends.invitationLink")}
            </Typography>
            <CopyButton
              textForCopy={`${window.location.origin}/create-account?invitationCode=${user?.referralCode}`}
            />
          </Space>
        </Space>
      </DialogContent>
    </Dialog>
  );
};

export default InviteFriends;
