import { useLocation, useNavigate } from 'react-router-dom'
import { useDidMount } from 'rooks'

const PurchaseByStripeSuccess = () => {
  const navigate = useNavigate();
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search);
  const sessionId = searchParams.get("session_id");
  useDidMount(()=>{
    navigate(`/wallet/purchase?stripe_result=success&stripe_session_id=${sessionId}`);
  })

  return null;
}

export default PurchaseByStripeSuccess