import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { Trans } from "react-i18next";

type SuccessDialogFailedContentProps = {
  titleTransKey: string;
  descriptionTransKey: string;
};

const SuccessDialogFailedContent: FC<SuccessDialogFailedContentProps> = ({
  titleTransKey,
  descriptionTransKey,
}) => {
  return (
    <>
      <Typography textAlign="center" variant="h6" color="text.primary2">
        <Trans i18nKey={titleTransKey} />
      </Typography>
      <Divider sx={{ mt: "23px", mb: "17px" }} />
      <Box sx={{ minHeight: "180px", mb: "20px" }}>
        <Typography variant="subtitle1" color="error" textAlign="center">
          <Trans i18nKey={descriptionTransKey} />
        </Typography>
      </Box>
    </>
  );
};

export default SuccessDialogFailedContent;
