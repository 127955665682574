import axios from "axios";
import { API_URL } from "src/config";

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
}

const $api = axios.create({
  withCredentials: false,
  baseURL: API_URL,
});

$api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  // config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiMTAiLCJuYmYiOjE3MTIxMDg1NDEsImV4cCI6MTcxMjEzMDE0MSwiaXNzIjoiVHdlZUJhbmtJc3N1ZXIiLCJhdWQiOiJUd2VlQmFua0F1ZGllbmNlIn0.cSCWSzBCtRwL0uQWmGqTZKS2GS4Io1JSfzo6cF0heh4`;
  return config;
});

// $api.interceptors.response.use(
//   config => {
//     return config;
//   },
//   async error => {
//     const originalRequest = error.config;
//     const refreshToken = localStorage.getItem("refreshToken");
//     if (
//       error.response.status === 401 &&
//       error.config &&
//       !error.config._isRetry &&
//       refreshToken
//     ) {
//       originalRequest._isRetry = true;
//       try {
//         const response = await axios.post<IAuthResponse>(
//           `${API_URL}/Auth/refreshToken/${refreshToken}`,
//           {
//             withCredentials: true,
//           }
//         );
//         localStorage.setItem("accessToken", response.data.accessToken);
//         return $api.request(originalRequest);
//       } catch (e) {
//         console.log("Not authorized");
//       }
//     }
//     throw error;
//   }
// );

export default $api;
