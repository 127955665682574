export const WALLET_HEADER_LINKS = [
  {
    label: "walletLayout.linksLabels.withdraw",
    path: "/wallet/withdraw",
  },
  {
    label: "walletLayout.linksLabels.deposit",
    path: "/wallet/deposit",
  },
  {
    label: "walletLayout.linksLabels.exchange",
    path: "/wallet/exchange",
  },
  {
    label: "walletLayout.linksLabels.transactions",
    path: "/wallet/transactions",
  }
];

export const STAKING_HEADER_LINKS = [
  {
    label: "stakingLayout.navLinks.staking",
    path: "/staking",
    exact: true,
  },
  // {
  //   label: t("stakingLayout.navLinks.rates"),
  //   path: "rates",
  // },
  {
    label: "stakingLayout.navLinks.summary",
    path: "/staking/summary",
  },
];
