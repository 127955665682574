import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { useTranslation } from "react-i18next";

import CurrencyIcon from "src/components/common/CurrencyIcon";
import { IStake, useUserActiveStakes } from "src/graphQl/userStakes";
import "./index.scss";
import { round } from "src/utils/helpers";
import { DEFAULT_ROUND } from "src/config";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const UserStakes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userStakes } = useUserActiveStakes();

  if (userStakes?.length === 0) {
    return null;
  }

  return (
    <div className="user-stakes">
      <div className="user-stakes__table-titles">
        <div className="user-stakes__table-title">
          {t("userStakes.expectedInterest")}
        </div>
        <div className="user-stakes__table-title">
          {t("userStakes.endDate")}
        </div>
      </div>
      <div className="user-stakes__stakes">
        {userStakes?.map((i: IStake) => {
          return (
            <div
              key={i.id}
              className="user-stakes__stake-item"
              onClick={() =>
                navigate(
                  `/staking/staked-details/${i.currency.name}?stakeId=${i.id}`
                )
              }
            >
              <CurrencyIcon
                currency={i.currency.name as string}
                style={{ width: 16, height: 16, minWidth: 16 }}
              />
              <div className="user-stakes__amount">
                {round(i.expectedInterest, DEFAULT_ROUND)}{" "}
                {i.interestCurrency.name}
              </div>
              <div className="user-stakes__finished-date">
                {dayjs(i.endDate).format("D MMM YYYY")}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserStakes;
