import { useState, FC, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Container,
  TextField,
  MenuItem,
  Divider,
  Button,
  Stack,
} from "@mui/material";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Space from "../../components/common/Space";
import ProfileCard from "../../components/profile/ProfileCard";
import ChangePasswordDialog from "../../components/profile/ChangePasswordDialog";
import ChangeTransactionPasswordDialog from "../../components/profile/ChangeTransactionPasswordDialog";
import SetEmailDialog from "../../components/profile/SetEmailDialog";
import {
  $emailUpdateDialogOpened,
  logout,
  openEmailDialog,
  setCurrency,
  setLanguageFx,
} from "../../store/account";
import { useCurrentUser, IUser } from "../../graphQl/profile";
import { useCurrencies } from "../../graphQl/currencies";
// import ConnectTweeBaaAppButton from "../../components/common/ConnectTweeBaaAppButton";
import { baseCurrencyNameToSymbol } from "src/hooks/useBaseCurrencySymbol";
import { useLocation, useNavigate } from "react-router-dom";

const Languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "cn",
    label: "Mandarin",
  },
];

type ProfileProps = {
  user?: IUser;
  baseCurrencies: {
    id: number;
    name: string;
  }[];
};

const Profile: FC<ProfileProps> = ({ user, baseCurrencies }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [changePasswordDialogOpened, setChangePasswordDialogOpened] =
    useState(false);
  const emailUpdateDialogOpened = useStore($emailUpdateDialogOpened);
  const [baseCurrencyId, setBaseCurrencyId] = useState(-1);

  const [
    changeTransactionPasswordDialogOpened,
    setChangeTransactionPasswordDialogOpened,
  ] = useState(false);

  useEffect(() => {
    if (baseCurrencies) {
      const usdCurrency = baseCurrencies?.find(i => i.name === "USD");
      const baseCurrency =
        user?.baseCurrencyId ||
        localStorage.getItem("baseCurrencyId") ||
        usdCurrency?.id;
      baseCurrency && setBaseCurrencyId(+baseCurrency);
    }
  }, [baseCurrencies, user?.baseCurrencyId]);

  const lang = user?.language || localStorage.getItem("LANGUAGE") || "en";

  const setCurrencyHandler = (currencyId: number) => {
    localStorage.setItem("baseCurrencyId", currencyId.toString());
    const currency = baseCurrencies?.find(i => i.id === currencyId);
    if (currency) {
      localStorage.setItem(
        "baseCurrencySymbol",
        baseCurrencyNameToSymbol[currency.name]
      );
      localStorage.setItem("baseCurrencyName", currency.name);
    }

    setBaseCurrencyId(currencyId);
    setCurrency(currencyId);
  };

  return (
    <>
      <Container sx={{ pt: "27px", pb: "40px" }}>
        <ProfileCard />
        <Box
          sx={{
            width: "250px",
            maxWidth: "100%",
            ml: "auto",
            mr: "auto",
            pt: "20px",
          }}
        >
          <Space gap={20} direction="vertical">
            {user && (
              <Box position="relative">
                <TextField
                  variant="standard"
                  label={t("placeholders.email")}
                  value={user.email || "Not set"}
                  fullWidth
                  InputProps={{ readOnly: true, disableUnderline: true }}
                />
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => openEmailDialog(user?.email || "")}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  {t(user.email ? "buttons.changeEmail" : "buttons.setEmail")}
                </Button>
              </Box>
            )}
            {user && user.tweeBaaAppUser?.id && (
              <Box position="relative">
                <TextField
                  variant="standard"
                  label={t("placeholders.tweebaaAccountId")}
                  value={user.tweeBaaAppUser?.id}
                  fullWidth
                  InputProps={{ readOnly: true, disableUnderline: true }}
                />
              </Box>
            )}
            <Box>
              <TextField
                variant="standard"
                label={t("placeholders.language")}
                value={lang}
                InputProps={{ disableUnderline: true }}
                SelectProps={{ autoWidth: true }}
                select
                onChange={e => setLanguageFx(e.target.value)}
              >
                {Languages.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box>
              <TextField
                variant="standard"
                label={t("placeholders.baseCurrency")}
                value={baseCurrencyId}
                InputProps={{ disableUnderline: true }}
                SelectProps={{ autoWidth: true }}
                InputLabelProps={{ sx: { overflow: "visible" } }}
                select
                onChange={e => setCurrencyHandler(Number(e.target.value))}
              >
                {baseCurrencies.map(currency => (
                  <MenuItem key={currency.id} value={currency.id}>
                    {currency.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Space>
          {user && (
            <>
              <Divider sx={{ mt: "20px", mb: "26px" }} />
              {/* <Typography variant="h6">{t("inviteFriends.title")}</Typography>
              <Typography variant="subtitle2" color="text.primary2" mt="12px">
                {t("inviteFriends.instruction")}
              </Typography> */}
              {/* {user && (
                <Space direction="vertical" gap={5} sx={{ mt: "22px" }}>
                  <Space justify="space-between" align="center" gap={5}>
                    <Typography variant="subtitle2" noWrap>
                      {t("inviteFriends.referalCodeLabel")}: {user.referralCode}
                    </Typography>
                    <CopyButton textForCopy={user.referralCode} />
                  </Space>
                  <Space justify="space-between" align="center" gap={5}>
                    <Typography variant="subtitle2">
                      {t("inviteFriends.invitationLink")}
                    </Typography>
                    <CopyButton
                      textForCopy={`${window.location.origin}/create-account?invitationCode=${user.referralCode}`}
                    />
                  </Space>
                </Space>
              )} */}
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/something-required#invite`, {
                    state: { prevPath: pathname },
                  })
                }
                sx={{ mb: "26px" }}
                fullWidth
              >
                {t("buttons.inviteFriends")}
              </Button>
              {/* <Divider sx={{ mt: "15px", mb: "26px" }} /> */}
              <Stack spacing="16px" alignItems="flex-start">
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => setChangePasswordDialogOpened(true)}
                >
                  {t("buttons.changePassword")}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => setChangeTransactionPasswordDialogOpened(true)}
                >
                  {t("buttons.changeTransactionPassword")}
                </Button>
              </Stack>
              {/* {!user.tweeBaaAppUser && (
                <ConnectTweeBaaAppButton sx={{ mt: "33px" }} fullWidth />
              )} */}
              <Divider sx={{ mt: "32px", mb: "25px" }} />
              <Button
                variant="text"
                color="secondary"
                onClick={() => logout()}
                sx={{ justifyContent: "flex-start" }}
              >
                {t("buttons.logout")}
              </Button>
            </>
          )}
        </Box>
      </Container>
      {user && (
        <>
          <ChangePasswordDialog
            open={changePasswordDialogOpened}
            onClose={() => setChangePasswordDialogOpened(false)}
          />
          <ChangeTransactionPasswordDialog
            open={changeTransactionPasswordDialogOpened}
            onClose={() => setChangeTransactionPasswordDialogOpened(false)}
          />
          <SetEmailDialog open={emailUpdateDialogOpened} />
        </>
      )}
    </>
  );
};

const ProfileProtected = () => {
  const { user, loading: userLoading } = useCurrentUser();

  const { currencies: baseCurrencies, loading: currenciesLoading } =
    useCurrencies("BASE");

  if (userLoading || currenciesLoading || !baseCurrencies) {
    return (
      <Box
        sx={{
          display: "flex",
          pt: "30px",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return <Profile user={user} baseCurrencies={baseCurrencies} />;
};

export default ProfileProtected;
