import { gql } from "@apollo/client";
import { ICurrency } from "./currencies";

export interface IStakeTemplate {
  baseInterest: number;
  createdDate: string;
  currency: ICurrency;
  currencyId: number;
  id: number;
  termMonths: number;
  updatedDate: string;
}

export const STAKE_TEMPLATES = gql`
  query StakeTemplates(
    $where: StakeTemplateFilterInput
    $first: Int
    $last: Int
    $order: [StakeTemplateSortInput!]
  ) {
    stakeTemplates(where: $where, first: $first, last: $last, order: $order) {
      nodes {
        baseInterest
        createdDate
        currency {
          id
          name
        }
        currencyId
        id
        termMonths
        updatedDate
      }
    }
  }
`;
