import { FC, ReactChild, ReactNode } from "react";
import cs from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { HiOutlineChevronLeft } from "react-icons/hi";

interface IProps {
  className?: string;
  img?: string;
  title?: string;
  children: ReactChild | ReactChild[];
  defaultBackBtn?: boolean;
  backBtn?: string | ReactNode;
  hAndroid?: boolean; // min-height for android
}

const UnAuthLayout: FC<IProps> = ({
  className,
  img,
  title,
  children,
  defaultBackBtn,
  backBtn,
  hAndroid,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={cs("unauth-layout", className, {
        "unauth-layout_android":
          hAndroid && navigator.userAgent.match(/Android/i),
      })}
    >
      {/* {defaultBackBtn && (
        <HiOutlineChevronLeft
          onClick={() => navigate(-1)}
          className="unauth-layout__back"
        />
      )} */}
      {defaultBackBtn && (
        <div className="unauth-layout__back" onClick={() => navigate(-1)}>
          <HiOutlineChevronLeft className="unauth-layout__back-icon" />
          {t("headerDefaultBack")}
        </div>
      )}
      {backBtn && <div className="unauth-layout__back">{backBtn}</div>}
      {img && <img src={img} className="unauth-layout__logo" alt="Logo" />}
      {title && (
        <div
          className={cs("unauth-layout__title", {
            "unauth-layout__title_without-img": !img,
          })}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default UnAuthLayout;
