import { useState, FC, useEffect } from "react";
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useUrlState from "@ahooksjs/use-url-state";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";

import "./index.scss";
import Button from "src/components/common/Button";
import { ReactComponent as Usdt } from "src/assets/wallet/USDT.svg";
import { ReactComponent as TBeans } from "src/assets/wallet/TBean.svg";
import { round, isValidNumber, ceilDecimal } from "src/utils/helpers";
import { DEFAULT_ROUND } from "src/config";
// import { useDebouncedValue } from "rooks";
import { IUser, useCurrentUser } from "../../graphQl/profile";
import Space from "../../components/common/Space";

import { CryptoCurrency } from "./types";
import StripeButton from "./StripeButton";
import PayPalButton from "./PayPalButton";
import { CAN_PAYPAL_OPERATION } from "../../graphQl/canPaypalOperation";
import WarningDialog from "./WarningDialog";
import SuccessStripeDialog from "./SuccessStripeDialog";
import SuccessPayPalDialog from "./SuccessPayPalDialog";
import { KycStatus } from "src/graphQl/kyc";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";
import { useLocation, useNavigate } from "react-router-dom";

const cryptoDisplayValues: { [k in CryptoCurrency]: string } = {
  USDT: "USDT",
  "T-Bean": "T-Bean",
};

const currenciesLimits: { [k in CryptoCurrency | "USD"]: [number, number] } = {
  USDT: [1, 10000],
  "T-Bean": [1, 10000],
  USD: [1, 10000],
};

const PurchaseInputForm: FC<{
  cryptoCurrency: CryptoCurrency;
  cryptoRate: number;
  onPayPalSuccess: (orderId: string) => void;
  user?: IUser;
}> = ({ cryptoCurrency, cryptoRate, onPayPalSuccess, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [warningOpened, setWarningOpened] = useState(false);
  const [amountCrypto, setAmountCrypto] = useState("");
  const [checkCanOperation] = useLazyQuery(CAN_PAYPAL_OPERATION);
  const amountCryptoLimits = currenciesLimits[cryptoCurrency];
  // const amountBaseLimits = currenciesLimits.usd;
  const amountBaseCurrency = new BigNumber(cryptoRate)
    .multipliedBy(amountCrypto)
    .dp(4)
    .toNumber();

  const fixedFee = 0.49;
  const feePercent = 4.99;

  const amountBaseCurrencyTotal =
    amountBaseCurrency === 0
      ? 0
      : ceilDecimal(
          (amountBaseCurrency + fixedFee) / (1 - feePercent / 100),
          0.01
        );

  // const paypalFee = round(
  //   amountBaseCurrencyTotal - amountBaseCurrency,
  //   DEFAULT_ROUND
  // );

  const amountCryptoValid =
    Number(amountCrypto) >= amountCryptoLimits[0] &&
    Number(amountCrypto) <= amountCryptoLimits[1];

  const amountBaseCurrencyValid = true;
  // const amountBaseCurrencyValid =
  //   !!amountBaseCurrencyTotal &&
  //   amountBaseCurrencyTotal >= amountBaseLimits[0] &&
  //   amountBaseCurrencyTotal <= amountBaseLimits[1];

  const showInput =
    amountCryptoValid &&
    amountBaseCurrencyValid &&
    !!amountBaseCurrency &&
    !!amountBaseCurrencyTotal;

  const onPayPalSuccessHandler = (orderId: string) => {
    setAmountCrypto("");
    onPayPalSuccess(orderId);
  };

  useEffect(() => {
    setAmountCrypto("");
  }, [cryptoCurrency]);

  const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;

  const checkPaymentOperationAbility = async () => {
    // TODO: why only usdt?
    // if (cryptoCurrency !== CryptoCurrency.usdt) return true;
    if (!user) {
      navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
      return false;
    }
    if (kycRequired) {
      navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
      return false;
    }
    setDisabled(true);
    // we actually don't know final taxes, so just omit it and take a bit more to check service ability to transfer credits
    const cryptoAmountWithGap = Math.ceil(amountBaseCurrencyTotal / cryptoRate);

    try {
      const { data: res, error } = await checkCanOperation({
        variables: {
          amount: cryptoAmountWithGap,
          currency: cryptoCurrency,
        },
      });
      if (error || !res.canPaypalOperation) {
        console.log(error);
        throw new Error();
      }
      setDisabled(false);
      return true;
    } catch (err) {
      console.log("err");
      toast.warn(t("purchasePage.paymentErrorPage"));
      setWarningOpened(true);
      setDisabled(false);
    }
    return false;
  };

  return (
    <>
      <TextField
        value={amountCrypto}
        variant="filled"
        onChange={e =>
          isValidNumber(e.target.value) && setAmountCrypto(e.target.value)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {cryptoDisplayValues[cryptoCurrency]}
            </InputAdornment>
          ),
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "^([0-9]+([.][0-9]*)?|[.][0-9]+)?$",
        }}
        sx={{ mt: "30px" }}
        error={!amountCryptoValid && !!amountCrypto}
        helperText={
          !amountCryptoValid &&
          !!amountCrypto &&
          `Invalid value: min ${amountCryptoLimits[0]}, max ${amountCryptoLimits[1]} ${cryptoDisplayValues[cryptoCurrency]}`
        }
        fullWidth
      />
      {showInput && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: "25px",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="body2" noWrap>
            {t("purchasePage.totalPreview")}:
          </Typography>

          <Space align="center">
            <Typography variant="h6" color="text.primary2" noWrap>
              {amountBaseCurrency} USD + Fee
            </Typography>
            <Tooltip title={t("purchasePage.feeNote")} placement="top" arrow>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Space>
        </Box>
      )}
      {showInput && (
        <Stack mt="27px" spacing="20px">
          <StripeButton
            amount={Number(amountCrypto)}
            currencyCode={cryptoCurrency}
            disabled={disabled}
            setDisabled={setDisabled}
            onClickValidation={checkPaymentOperationAbility}
          />
          <PayPalButton
            amount={amountBaseCurrencyTotal}
            onSuccess={onPayPalSuccessHandler}
            currencyCode={cryptoCurrency}
            disabled={disabled}
            onClickValidation={checkPaymentOperationAbility}
          />
        </Stack>
      )}
      <WarningDialog
        open={warningOpened}
        onClose={() => setWarningOpened(false)}
      />
    </>
  );
};

const useInitialBaseRate = () => {
  const { data, loading } = useQuery<{
    [k in "tbean" | "usdt" | "tbeancny" | "usdtcny"]: number;
  }>(
    gql`
      query cryptoBaseRate {
        tbean: convert(amount: 1, from: "T-Bean", to: "USD")
        usdt: convert(amount: 1, from: "USDT", to: "USD")
        tbeancny: convert(amount: 1, from: "T-Bean", to: "CNY")
        usdtcny: convert(amount: 1, from: "USDT", to: "CNY")
      }
    `,
    {
      fetchPolicy: "network-only",
      pollInterval: 10000,
    }
  );

  // const invertedRates: { [k in CryptoCurrency]: number } | undefined = data && {
  //   "T-Bean": 1 / data.tbean,
  //   USDT: 1 / data.usdt,
  // };
  if (localStorage.getItem("baseCurrencyName") === "CNY") {
    return {
      loading,
      data: { USDT: data?.usdtcny || 0, "T-Bean": data?.tbeancny || 0 },
    };
  }

  return {
    loading,
    data: { USDT: data?.usdt || 0, "T-Bean": data?.tbean || 0 },
  };
};

const Purchase = () => {
  const { t } = useTranslation();
  const [stripeSuccessDialog, setStripeSuccessDialog] = useState<string>();
  const [payPalOrderId, setPayPalOrderId] = useState<string>();

  const [{ stripe_result, stripe_session_id }, setStripeState] = useUrlState({
    stripe_result: undefined,
    stripe_session_id: undefined,
  });

  useEffect(() => {
    if (!stripe_result) return;
    if (stripe_result === "fail") {
      toast.warn(t("purchasePage.paymentErrorPage"));
    } else if (stripe_result === "success" && stripe_session_id) {
      setStripeSuccessDialog(stripe_session_id);
    }
  }, [setStripeState, stripe_result, stripe_session_id, t]);

  const [cryptoCurrency, setCryptoCurrency] = useState<CryptoCurrency | null>(
    null
  );
  // const { currencies: baseCurrencies } = useCurrencies("BASE");
  // // const usdCurrency = baseCurrencies?.find(i => i.name === "USD");
  // const baseCurrenciesObj = baseCurrencies?.reduce(
  //   (acc: any, cur: ICurrency) => {
  //     acc[cur.name] = cur;
  //     return acc;
  //   },
  //   {}
  // );
  // console.log(baseCurrenciesObj);
  const { user } = useCurrentUser();
  // const baseCurrency =
  //   user?.baseCurrencyId ||
  //   localStorage.getItem("baseCurrencyId") ||
  //   usdCurrency?.id;

  const { data: cryptoRate } = useInitialBaseRate();
  const baseCurrencySymbol = useBaseCurrencySymbol();

  // if (user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED) {
  //   return <KycRequired kycStatus={user.kycStatus} />;
  // }

  // const appConnected = user?.tweeBaaAppUser;
  // const showConnectTweebaaApp =
  //   !appConnected && cryptoCurrency === CryptoCurrency["T-Bean"];

  const onStripeSuccessDialogClose = () => {
    setStripeState({
      stripe_result: undefined,
      stripe_session_id: undefined,
    });
    setStripeSuccessDialog(undefined);
  };

  // const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;
  // const modalName = !user
  //   ? ModalNames.Register
  //   : kycRequired
  //   ? ModalNames.Kyc
  //   : null;

  return (
    <>
      <Box
        sx={{
          width: "300px",
          maxWidth: "100%",
          margin: "0 auto",
          pt: "35px",
          pb: "60px",
        }}
      >
        <Typography variant="h6" align="center">
          {t("purchasePage.choiceCurrencyToBuy")}
        </Typography>
        <div className="purchase__currencies">
          <div className="purchase__currency-container">
            <Button
              style={{
                opacity: cryptoCurrency === CryptoCurrency.USDT ? 1 : 0.3,
              }}
              onClick={() => setCryptoCurrency(CryptoCurrency.USDT)}
              iconLeft={<Usdt className="purchase__currency-icon" />}
              className="purchase__currency-btn"
            >
              {cryptoDisplayValues.USDT}
            </Button>
            <div className="purchase__currency-rate">
              1 {cryptoDisplayValues.USDT} = {baseCurrencySymbol}
              {round(cryptoRate?.USDT || 0, DEFAULT_ROUND)}
            </div>
          </div>
          <div className="purchase__currency-container">
            <Button
              style={{
                opacity: cryptoCurrency === CryptoCurrency["T-Bean"] ? 1 : 0.3,
              }}
              onClick={() => setCryptoCurrency(CryptoCurrency["T-Bean"])}
              iconLeft={<TBeans className="purchase__currency-icon" />}
              className="purchase__currency-btn"
            >
              {cryptoDisplayValues["T-Bean"]}
            </Button>
            <div className="purchase__currency-rate">
              1 {cryptoDisplayValues["T-Bean"]} = {baseCurrencySymbol}
              {round(cryptoRate?.["T-Bean"] || 0, DEFAULT_ROUND)}
            </div>
          </div>
        </div>
        {/* {showConnectTweebaaApp && <ConnectTweebaaApp />} */}
        {!!cryptoCurrency && !!cryptoRate && (
          <PurchaseInputForm
            cryptoCurrency={cryptoCurrency}
            cryptoRate={cryptoRate[cryptoCurrency]}
            onPayPalSuccess={setPayPalOrderId}
            user={user}
          />
        )}
      </Box>
      <SuccessStripeDialog
        open={!!stripeSuccessDialog}
        onClose={onStripeSuccessDialogClose}
        sessionId={stripeSuccessDialog}
      />
      <SuccessPayPalDialog
        open={!!payPalOrderId}
        onClose={() => setPayPalOrderId(undefined)}
        orderId={payPalOrderId}
      />
      {/* <CommonModal modalName={modalName} /> */}
    </>
  );
};

export default Purchase;
