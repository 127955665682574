import {
  createEvent,
  restore,
  createStore,
  createEffect,
  combine,
} from "effector";
import { createGate } from "effector-react";
import { PhoneVerificationRequestType } from "src/services/User";
import { IUser } from "../../graphQl/profile";
import { ValidationError } from "../../types/errors";
import { requestWithdraw } from "../wallet";
import { PatchUserParams } from "./types";

export const ScreenGate = createGate<{ onSuccess: () => void }>();
export const ConfirmTxPasswordGate = createGate();

export const checkAuth = createEffect<void, boolean>();

export const setAuthChecked = createEvent<boolean>();
export const $isAuthChecked = restore(setAuthChecked, false)
  .on(checkAuth.done, () => true)
  .on(checkAuth.fail, () => true);

export const setAuth = createEvent<boolean>();
export const $isAuth = restore(setAuth, false);

export const noAuthUser = combine(
  $isAuthChecked,
  $isAuth,
  (isAuthChecked, isAuth) => isAuthChecked && !isAuth
);

export const setPhone = createEvent<string>();
export const $phone = restore(setPhone, "");
export const setPhoneError = createEvent<string>();
export const $phoneError = restore(setPhoneError, "").reset(setPhone);

export const setFirstName = createEvent<string>();
export const $firstName = restore(setFirstName, "").reset([ScreenGate.close]);
export const setFirstNameError = createEvent<string>();
export const $firstNameError = restore(setFirstNameError, "").reset(
  setFirstName
);

export const setLastName = createEvent<string>();
export const $lastName = restore(setLastName, "").reset([ScreenGate.close]);
export const setLastNameError = createEvent<string>();
export const $lastNameError = restore(setLastNameError, "").reset(setLastName);

export const checkConfirmationCode = createEffect<string, void>();
export const setCode = createEvent<string>();
export const $code = restore(setCode, "").reset([
  checkConfirmationCode.done,
  checkConfirmationCode.fail,
]);
export const $needCheckCode = $code.map(code => code.length === 6);
export const $codeError = createStore("").reset([setCode, ScreenGate.close]);

export const setInvitationCode = createEvent<string>();
export const $invitationCode = restore(setInvitationCode, "").reset([
  ScreenGate.close,
]);
export const setInvitationCodeError = createEvent<string>();
export const $invitationCodeError = restore(setInvitationCodeError, "").reset(
  setInvitationCode
);

export const setEmail = createEvent<string>();
export const $email = restore(setEmail, "").reset([ScreenGate.close]);
export const setEmailError = createEvent<string>();
export const $emailError = restore(setEmailError, "").reset(setEmail);
export const openEmailDialog = createEvent<string>();
export const closeEmailDialog = createEvent();
export const $emailUpdateDialogOpened = createStore(false);

export const register = createEffect<
  { password: string; invitationCode: string; email: string },
  void
>();

export const setPassword = createEvent<string>();
export const $password = restore(setPassword, "").reset([
  register.done,
  ScreenGate.close,
  requestWithdraw.done,
  ConfirmTxPasswordGate.close,
]);
export const setPasswordError = createEvent<string>();
export const $passwordError = restore(setPasswordError, "").reset([
  setPassword,
  ConfirmTxPasswordGate.close,
]);

export const getConfirmationCode = createEffect<
  {
    phone: string;
    doNotCallSuccess?: boolean;
    type: PhoneVerificationRequestType;
    requirePassword?: boolean;
    password?: string;
    isCreateAccount?: boolean;
    firstName?: string;
    lastName?: string;
    captchaToken?: string;
  },
  void
>();

export const confirmLogin = createEffect<void, void>();
export const resetPassword = createEffect<{ password: string }, void>();
export const confirmResetPassword = createEffect<void, void>();
export const setupTxPassword = createEffect<{ password: string }, void>();
export const updateEmail = createEvent();
export const patchUserFx = createEffect<
  PatchUserParams,
  void,
  Error | ValidationError
>();
export const fetchUserFx = createEffect<void, IUser>();
export const setCurrency = createEvent<number>();
export const setLanguageFx = createEffect<string, void>();

export const changeTransactionPasswordFx = createEffect<
  { currentPassword: string; newPassword: string },
  void,
  void
>();

export const changePasswordFx = createEffect<
  { currentPassword: string; newPassword: string },
  void,
  void
>();

export const confirmResetTransactionPassword = createEffect<void, void>();

export const logout = createEffect<void, void>();

export const setSentryUser = createEffect<IUser | null, void, void>();
