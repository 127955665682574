import { ScopedCssBaseline, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { FC, ReactNode } from "react";
import { darkTheme, lightTheme } from "src/styles/theme";
import styles from "./styles.module.scss";

type Props = {
  children: ReactNode;
  theme: "dark" | "light";
  background?: "transparent" | React.CSSProperties["color"];
};

const LocalThemeProvider: FC<Props> = ({ children, theme, background }) => {
  const themeByName = {
    dark: darkTheme,
    light: lightTheme,
  };
  const content = (
    <ThemeProvider theme={themeByName[theme]}>
      <ScopedCssBaseline
        enableColorScheme
        classes={{
          root: background ? styles.backgroundTransparent : undefined,
        }}
      >
        {children}
      </ScopedCssBaseline>
    </ThemeProvider>
  );
  if (background && background !== "transparent") {
    return <Box bgcolor={background}>{content}</Box>;
  }

  return content;
};

export default LocalThemeProvider;
