export type TFileWithPreview = {
  preview?: string;
} & File;

export enum ModalNames {
  InviteFriends,
  Register,
  Kyc,
  ConnectTweebaa,
}
