import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  onClose: () => void;
  open: boolean;
};
const WarningDialog: FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle />
      <DialogContent>
        <Typography textAlign="center" variant="h6" color="text.primary2">
          {t("purchaseWarningDialog.title")}
        </Typography>
        <Divider sx={{ mt: "23px", mb: "17px" }} />
        <Box sx={{ minHeight: "180px", mb: "20px" }}>
          <Typography variant="subtitle1" color="error" textAlign="center">
            <Trans i18nKey="purchaseWarningDialog.text" />
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WarningDialog;
