// import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineMail, MdOutlinePhoneAndroid } from "react-icons/md";
import { CONTACTS } from "src/config";

import "./index.scss";

const LandingContacts = () => {
  return (
    <div className="landing-contacts">
      {/* <div className="landing-contacts__item">
        <HiOutlineLocationMarker className="landing-contacts__icon" />
        <div className="landing-contacts__text">Cayman Island</div>
      </div> */}
      <div className="landing-contacts__item">
        <MdOutlinePhoneAndroid className="landing-contacts__icon" />
        <div className="landing-contacts__text">{CONTACTS.phone}</div>
      </div>
      <div className="landing-contacts__item">
        <MdOutlineMail className="landing-contacts__icon" />
        <div className="landing-contacts__text">{CONTACTS.email}</div>
      </div>
    </div>
  );
};

export default LandingContacts;
