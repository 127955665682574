import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/pagination";
import "./index.scss";
import StRockefellerJr from "src/assets/landing/StRockefellerJr.jpg";
import KevinHarrington from "src/assets/landing/KevinHarrington.jpg";
import CoryBergeron from "src/assets/landing/CoryBergeron.jpg";
import JeniBond from "src/assets/landing/JeniBond.jpg";
import AntonyCalvert from "src/assets/landing/AntonyCalvert.jpg";
import CaseyMcClellan from "src/assets/landing/CaseyMcClellan.jpg";

const LandingAboutUs = () => {
  const { t } = useTranslation();

  const slides = [
    {
      img: StRockefellerJr,
      title: t("landing.aboutUs.slides.0.title"),
      subTitle: t("landing.aboutUs.slides.0.subTitle"),
      text: t("landing.aboutUs.slides.0.text"),
    },
    {
      img: KevinHarrington,
      title: t("landing.aboutUs.slides.1.title"),
      subTitle: t("landing.aboutUs.slides.1.subTitle"),
      text: t("landing.aboutUs.slides.1.text"),
    },
    {
      img: CoryBergeron,
      title: t("landing.aboutUs.slides.2.title"),
      subTitle: t("landing.aboutUs.slides.2.subTitle"),
      text: t("landing.aboutUs.slides.2.text"),
    },
    {
      img: JeniBond,
      title: t("landing.aboutUs.slides.3.title"),
      subTitle: t("landing.aboutUs.slides.3.subTitle"),
      text: t("landing.aboutUs.slides.3.text"),
    },
    {
      img: AntonyCalvert,
      title: t("landing.aboutUs.slides.4.title"),
      subTitle: t("landing.aboutUs.slides.4.subTitle"),
      text: t("landing.aboutUs.slides.4.text"),
    },
    {
      img: CaseyMcClellan,
      title: t("landing.aboutUs.slides.5.title"),
      subTitle: t("landing.aboutUs.slides.5.subTitle"),
      text: t("landing.aboutUs.slides.5.text"),
    },
  ];

  return (
    <div className="landing-about-us">
      <div className="landing-about-us__title">
        {t("landing.aboutUs.title")}
      </div>
      <Swiper
        pagination
        modules={[Pagination]}
        className="landing-about-us__swiper"
      >
        {slides.map((i, index) => (
          <SwiperSlide key={index}>
            <div className="landing-about-us__slide-img-wrapper">
              <img
                src={i.img}
                className="landing-about-us__slide-img"
                alt={i.title}
              />
            </div>
            <div className="landing-about-us__slide-title">{i.title}</div>
            <div className="landing-about-us__slide-subtitle">{i.subTitle}</div>
            <div className="landing-about-us__slide-text">{i.text}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LandingAboutUs;
