import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useStore } from "effector-react";

import ProtectedRoute from "src/routes/ProtectedRoute";

import Wallet from "src/screens/Wallet";
import Profile from "src/screens/Profile";
import CreateAccount from "src/screens/CreateAccount";
import ConfirmCode from "src/screens/ConfirmCode";
import SetupPassword from "src/screens/SetupPassword";
import Login from "src/screens/Login";
import Transactions from "src/screens/Transactions";
import TweePurchases from "src/screens/TweePurchases";
import Deposit from "src/screens/Deposit";
import Withdraw from "src/screens/Withdraw";
import Exchange from "src/screens/Exchange";
import SetupTxPassword from "src/screens/SetupTxPassword";
import CurrencyDetails from "src/screens/CurrencyDetails";
import UserDashboard from "src/screens/UserDashboard";
import {
  $isAuth,
  confirmLogin,
  confirmResetPassword,
  confirmResetTransactionPassword,
} from "src/store/account";
import { PhoneVerificationRequestType } from "src/services/User";
import ResetPassword from "src/screens/ResetPassword";
import Purchase from "src/screens/Purchase";
import ProfileLayout from "../components/layouts/ProfileLayout";
import Invitees from "../screens/Invitees";
import Ambassador from "../screens/Ambassador";
import DepositCurrency from "src/screens/DepositCurrency";
import WithdrawCurrency from "src/screens/WithdrawCurrency";
import ResetTransactionPassword from "../screens/ResetTransactionPassword";
import StakingLayout from "src/components/layouts/StakingLayout";
import Staking from "src/screens/Staking";
import WalletLayout from "src/components/layouts/WalletLayout";
import StakingCurrency from "src/screens/StakingCurrency";
import StakingDetails from "src/screens/StakingDetails";
import StakingSummary from "src/screens/StakingSummary";
// import KycOverview from "src/screens/KycOverview";
import Kyc from "src/screens/Kyc";
import Landing from "src/screens/Landing";
import LoggedLanding from "src/screens/LoggedLanding";
import AmbassadorProgram from "src/screens/AmbassadorProgram";
import Privacy from "src/screens/Privacy";
import Agreement from "src/screens/UserAgreement";
import ConnectTweeBaaAppFallback from "../screens/ConnectTweeBaaAppFallback";
import TransferTbean from "src/screens/TransferTbean";
import ContactUsScreen from "src/screens/ContactUs";
import Rewards from "src/screens/Rewards";
import RewardsInProgress from "src/screens/RewardsInProgress";
import AboutStaking from "src/screens/AboutStaking";
import AboutAmbassador from "src/screens/AboutAmbassador";
import PurchaseByStripeSuccess from "../screens/PurchaseByStripeSuccess";
import TranslationRedirect from "../screens/TranslationRedirect";
import InviteEarn from "src/screens/InviteEarn";
import CommonPage from "src/screens/CommonPage";
import SwitchLanguage from "src/components/common/SwitchLanguage";
import * as Sentry from "@sentry/react";
import Earnings from "src/screens/Earnings";

const PageNotFound = lazy(() => import("src/components/common/PageNotFound"));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RoutesComponent = () => {
  const isAuth = useStore($isAuth);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SwitchLanguage />
      <SentryRoutes>
        <Route path="/" element={isAuth ? <LoggedLanding /> : <Landing />} />
        <Route path="/something-required" element={<CommonPage />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route
          path="/confirm-code"
          element={
            <ConfirmCode
              successRoute="/setup-password"
              phoneVerificationRequestType={PhoneVerificationRequestType.Signup}
            />
          }
        />
        <Route path="/setup-password" element={<SetupPassword />} />
        <Route path="/setup-tx-password" element={<SetupTxPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/reset-transaction-password"
          element={<ResetTransactionPassword />}
        />
        <Route
          path="/confirm-code-reset-password"
          element={
            <ConfirmCode
              successRoute="/login"
              phoneVerificationRequestType={
                PhoneVerificationRequestType.ResetPassword
              }
              preSuccess={confirmResetPassword}
            />
          }
        />
        <Route
          path="/confirm-code-transaction-password"
          element={
            <ConfirmCode
              successRoute="/"
              phoneVerificationRequestType={
                PhoneVerificationRequestType.ResetTransactionPassword
              }
              preSuccess={confirmResetTransactionPassword}
            />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/confirm-code-login"
          element={
            <ConfirmCode
              useLocationState
              successRoute="/"
              phoneVerificationRequestType={PhoneVerificationRequestType.Login}
              preSuccess={confirmLogin}
            />
          }
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/contact-us" element={<ContactUsScreen />} />
        <Route path="/about-staking" element={<AboutStaking />} />
        <Route path="/about-ambassador" element={<AboutAmbassador />} />
        <Route path="/invite-earn" element={<InviteEarn />} />
        <Route path="/tweeBaaConnect" element={<ConnectTweeBaaAppFallback />} />

        {/* <Route path="/kyc-overview" element={<KycOverview />} /> */}
        <Route path="/wallet" element={<WalletLayout />}>
          <Route index element={<Wallet />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="deposit" element={<Deposit />} />
          <Route path="exchange" element={<Exchange />} />
          <Route path="transfer" element={<TransferTbean />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="twee-purchases" element={<TweePurchases/>}/>
          <Route path="purchase" element={<Purchase />} />
          <Route
            path="currency-details/:currency"
            element={<CurrencyDetails />}
          />
          <Route path="withdraw/:currency" element={<WithdrawCurrency />} />
          <Route path="deposit/:currency" element={<DepositCurrency />} />
        </Route>

        <Route path="/staking" element={<StakingLayout />}>
          <Route index element={<Staking />} />
          <Route path="rates" element={<StakingSummary />} />
          <Route path="summary" element={<StakingSummary />} />
          <Route path="stake/:currency" element={<StakingCurrency />} />
          <Route path="details/:currency" element={<StakingDetails />} />
          <Route
            path="staked-details/:currency"
            element={<StakingDetails isStaked />}
          />
          <Route
            path="finished-staking-details/:currency"
            element={<StakingDetails isFinished />}
          />
        </Route>

        <Route path="/profile" element={<ProfileLayout />}>
          <Route index element={<Profile />} />
          <Route path="invitees" element={<Invitees />} />
          <Route path="rewards/this-year" element={<Rewards />} />
          <Route path="rewards/in-progress" element={<RewardsInProgress />} />
          <Route path="ambassador" element={<Ambassador />} />
          <Route
            path="ambassador-program"
            element={<AmbassadorProgram withoutHeader />}
          />
        </Route>
        <Route path="/ambassador-program" element={<AmbassadorProgram />} />
        <Route path="/dashboard" element={<UserDashboard />} />
        <Route path="/earnings" element={<Earnings />} />

        <Route element={<ProtectedRoute isAllowed={isAuth} />}>
          <Route path="/kyc" element={<Kyc />} />

          {/* <Route path="/wallet" element={<WalletLayout />}>
            <Route index element={<Wallet />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="exchange" element={<Exchange />} />
            <Route path="transfer" element={<TransferTbean />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="purchase" element={<Purchase />} />
            <Route
              path="currency-details/:currency"
              element={<CurrencyDetails />}
            />
            <Route path="withdraw/:currency" element={<WithdrawCurrency />} />
            <Route path="deposit/:currency" element={<DepositCurrency />} />
          </Route> */}

          {/* <Route
            path="/tweeBaaConnect"
            element={<ConnectTweeBaaAppFallback />}
          /> */}
          <Route path="stripe-success" element={<PurchaseByStripeSuccess />} />
          <Route
            path="stripe-fail"
            element={<Navigate to="/wallet/purchase?stripe_result=fail" />}
          />
        </Route>
        {process.env.REACT_APP_IS_STAGE && (
          <Route path="/translation" element={<TranslationRedirect />} />
        )}
        <Route path="*" element={<PageNotFound />} />
      </SentryRoutes>
    </Suspense>
  );
};

export default RoutesComponent;
