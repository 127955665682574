import { gql } from "@apollo/client";

export type tweeBaaAccountBalancesResponse = {
  tweeBaaAccountBalances: {
    tweeBaaBalance: number;
    tweeBankBalance: number;
  };
};

export const TWEEBAA_ACCOUNT_BALANCES = gql`
  query tweeBaaAccountBalances {
    tweeBaaAccountBalances {
      tweeBaaBalance
      tweeBankBalance
    }
  }
`;
