import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Container } from "@mui/material";
import BigNumber from "bignumber.js";
import { FC, useMemo } from "react";

import { useAmbassadorRewards } from "src/graphQl/ambassadorRewards";
import { useAmbassadorRewardsInProgress } from "src/graphQl/ambassadorRewardsInProgress";
import { INVITEES_STAKES_TRANSACTIONS } from "src/graphQl/inviteesStakesTransactions";
import { useInviterRewardsInProgress } from "src/graphQl/inviterRewardsInProgress";
import { ITransaction } from "src/graphQl/operations";
import { roundDecimal } from "src/utils/helpers";
import InviteesList from "../../components/profile/InviteesList";
import RewardsCard from "../../components/profile/RewardsCard";
import { IUser, useCurrentUser } from "../../graphQl/profile";

const Invitees: FC<{ user?: IUser }> = ({ user }) => {
  const inviteesUsers = useMemo(
    () => user?.inviteeInvitations?.map(invitation => invitation.inviteeUser),
    [user?.inviteeInvitations]
  );
  const sortedInviteesUsers = useMemo(
    () =>
      inviteesUsers?.sort((a, b) =>
        b.createdDate.localeCompare(a.createdDate)
      ) || [],
    [inviteesUsers]
  );

  const { inviterRewardsInProgress } = useInviterRewardsInProgress();
  const { ambassadorRewardsInProgress } = useAmbassadorRewardsInProgress();
  const { ambassadorRewards } = useAmbassadorRewards();
  const { data: inviteesStakesTransactions } = useQuery(
    INVITEES_STAKES_TRANSACTIONS
  );

  const rewardsThisYear = useMemo(
    () =>
      inviteesStakesTransactions?.inviteesStakesTransactions?.nodes?.reduce(
        (acc: number, cur: ITransaction) => {
          acc += cur?.baseAmount || 0;
          return acc;
        },
        0
      ) || 0,
    [inviteesStakesTransactions?.inviteesStakesTransactions?.nodes]
  );

  const roundedRewardsInProgress = new BigNumber(inviterRewardsInProgress)
    .plus(ambassadorRewardsInProgress)
    .dp(4)
    .toNumber();

  return (
    <div>
      <Container sx={{ pt: "27px", pb: "30px" }}>
        <RewardsCard
          currentYearValue={roundDecimal(rewardsThisYear)}
          inProgressValue={roundedRewardsInProgress}
          ambassadorValue={roundDecimal(ambassadorRewards)}
          isAmbassador
        />
        <Box p="0 10px" mt="17px">
          <InviteesList users={sortedInviteesUsers} />
        </Box>
      </Container>
    </div>
  );
};

const ProtectedInvitees = () => {
  const { user, loading } = useCurrentUser();

  if (loading) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return <Invitees user={user} />;
};

export default ProtectedInvitees;
