import { Box } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import { KycStatus, useKyc } from "src/graphQl/kyc";
import { useAmbassadorInProcess } from "src/graphQl/profile";
import AppHeader from "../../common/AppHeader";
import NavTabs from "../../common/NavTabs";
import TabBar from "../../common/TabBar";
import LocalThemeProvider from "../../LocalThemeProvider";
import ContactUsLink from "../../wallet/ContactUsLink";

type Props = {
  backPath?: string;
};

interface IParamsFromPathname {
  backPath?: string;
  title: string;
}

const ProfileLayout: FC<Props> = ({ backPath }) => {
  const { t } = useTranslation();
  const { kyc } = useKyc();
  const { pathname } = useLocation();
  const { ambassadorInProcess } = useAmbassadorInProcess();

  const tabs = [
    {
      label: t("profile.nav.settings"),
      href: "",
    },
    {
      label: t("profile.nav.invitees"),
      href: "invitees",
    },
    {
      label: t("profile.nav.ambassador"),
      href: ambassadorInProcess ? "ambassador" : "ambassador-program",
    },
  ];

  // const isAmbassadorScreen = matchPath("/profile/ambassador", pathname);

  const headerBackPath = kyc?.status !== KycStatus.APPROVED ? "/" : "";

  const headerParamsFromPathname = (): IParamsFromPathname => {
    if (matchPath("/profile/rewards/this-year", pathname)) {
      return {
        title: t("rewardsCard.thisYearTitle"),
        backPath: "/profile/invitees",
      };
    }
    if (matchPath("/profile/rewards/in-progress", pathname)) {
      return {
        title: t("rewardsCard.inProgress"),
        backPath: "/profile/invitees",
      };
    }
    return {
      title: t("pageTitle.profile"),
      backPath: headerBackPath,
    };
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <AppHeader {...headerParamsFromPathname()} />
        <NavTabs tabs={tabs} />
      </LocalThemeProvider>
      <Outlet />
      <ContactUsLink />
      <TabBar />
    </Box>
  );
};

export default ProfileLayout;
