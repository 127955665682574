import { Box } from "@mui/material";
import { FC } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import Header from "src/components/common/Header";
import HeaderLinks from "src/components/common/HeaderLinks";
import TabBar from "../../common/TabBar";
import LocalThemeProvider from "../../LocalThemeProvider";
import ContactUsLink from "../../wallet/ContactUsLink";
import { useTranslation } from "react-i18next";
import { WALLET_HEADER_LINKS } from "src/config/links";

type Props = {
  backPath?: string;
};

const WalletLayout: FC<Props> = ({ backPath }) => {
  const { t } = useTranslation();
  const { pathname, hash } = useLocation();
  const currencyDetails = matchPath(
    "/wallet/currency-details/:currency",
    pathname
  );

  const headerParamsFromPathname = () => {
    if (matchPath("/wallet/transfer", pathname)) {
      return {
        title: t("transferPage.transfer"),
        backPath: `/wallet/${hash.slice(1)}`,
      };
    }
    if (matchPath("/wallet/withdraw", pathname)) {
      return { title: t("walletLayout.pageTitles.withdraw") };
    }
    if (matchPath("/wallet/deposit", pathname)) {
      return { title: t("walletLayout.pageTitles.deposit") };
    }
    if (matchPath("/wallet/exchange", pathname)) {
      return { title: t("walletLayout.pageTitles.exchange") };
    }
    if (matchPath("/wallet/transactions", pathname)) {
      return { title: t("walletLayout.pageTitles.transactions") };
    }
    if (matchPath("/wallet/purchase", pathname)) {
      return { title: t("walletLayout.pageTitles.purchase") };
    }
    if (matchPath("/wallet/twee-purchases", pathname)) {
      return { title: t("walletLayout.pageTitles.purchase") };
    }
    if (currencyDetails) {
      return {
        title: currencyDetails?.params?.currency,
        showCurrencyBalance: currencyDetails?.params?.currency,
      };
    }
    if (matchPath("/wallet/deposit/:currency", pathname)) {
      return {
        title: t("walletLayout.pageTitles.deposit"),
        backPath: "/wallet/deposit",
      };
    }
    if (matchPath("/wallet/withdraw/:currency", pathname)) {
      return {
        title: t("walletLayout.pageTitles.withdraw"),
        backPath: "/wallet/withdraw",
      };
    }
    return {
      title: t("walletLayout.pageTitles.wallet"),
      showBalance: true,
      backPath: undefined,
    };
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <Header
          backPath="/wallet"
          {...headerParamsFromPathname()}
          showDashboardBtn={pathname === "/wallet"}
        />
        <HeaderLinks links={WALLET_HEADER_LINKS} />
      </LocalThemeProvider>
      <Outlet />
      <ContactUsLink />
      <TabBar />
    </Box>
  );
};

export default WalletLayout;
