import { FC, ReactNode, useEffect } from "react";
import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import "./index.scss";
import Header from "src/components/common/Header";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import RegisterImg from "src/assets/modals/Register.png";
import UserMsgImg from "src/assets/UserMsgExclamation.png";
import Button from "src/components/common/Button";
import AppIntegrationImg from "src/assets/AppIntegration.png";
import { TWEE_APP_AUTH_URL } from "src/graphQl/tweeAppAuthUrl";
import ContentBlock from "src/components/landing/ContentBlock";
import RocketImg from "src/assets/RocketImg.png";
import MetaverseImg from "src/assets/Metaverse.png";
import TabBar from "src/components/common/TabBar";
import { useStore } from "effector-react";
import { $isAuth } from "src/store/account";
import HeaderLinks from "src/components/common/HeaderLinks";
import { STAKING_HEADER_LINKS, WALLET_HEADER_LINKS } from "src/config/links";
import { useAmbassadorInProcess } from "src/graphQl/profile";
import InviteFriendsImg from "src/assets/InviteFriends.png";
import { setModal } from "src/store/common";
import { ModalNames } from "src/types/common";
import InviteFriends from "src/components/modals/InviteFriends";

interface IProps {
  image?: string;
  // headerTitle?: string;
  title?: string | ReactNode;
  text?: string | ReactNode;
  onButtonClick?: () => void;
  btnText?: string;
}

interface CustomizedState {
  prevPath: string;
}

const CommonPage: FC<IProps> = ({
  image,
  // headerTitle = "",
  title = "",
  text = "",
  onButtonClick,
  btnText = "",
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { pathname, hash } = location;
  const navigate = useNavigate();
  const isAuth = useStore($isAuth);
  const { ambassadorInProcess } = useAmbassadorInProcess();

  const prevPath = state?.prevPath;

  const fromWallet = prevPath?.includes("/wallet");
  const fromStaking = prevPath?.includes("/staking");
  // const fromInviteEarn = prevPath?.includes("/invite-earn");
  const fromProfile = prevPath?.includes("/profile");

  const getHeaderLinks = () => {
    if (fromWallet) {
      return WALLET_HEADER_LINKS;
    }
    if (fromStaking) {
      return STAKING_HEADER_LINKS;
    }
    if (fromProfile) {
      return [
        {
          label: t("profile.nav.settings"),
          path: "/profile",
        },
        {
          label: t("profile.nav.invitees"),
          path: "/profile/invitees",
        },
        {
          label: t("profile.nav.ambassador"),
          path: ambassadorInProcess
            ? "/profile/ambassador"
            : "/profile/ambassador-program",
        },
      ];
    }
    return [];
  };

  const getHeaderTitle = () => {
    if (prevPath?.includes("/wallet/withdraw")) {
      return t("walletLayout.pageTitles.withdraw");
    }
    if (prevPath?.includes("/wallet/deposit")) {
      return t("walletLayout.pageTitles.deposit");
    }
    if (prevPath?.includes("/wallet/currency-details")) {
      return t("walletLayout.pageTitles.deposit");
    }
    if (prevPath?.includes("/wallet/exchange")) {
      return t("walletLayout.pageTitles.exchange");
    }
    if (prevPath?.includes("/wallet/purchase")) {
      return t("walletLayout.pageTitles.purchase");
    }
    if (prevPath?.includes("/profile")) {
      return t("pageTitle.profile");
    }
    if (prevPath?.includes("/invite-earn")) {
      return t("inviteEarn.headerTitle");
    }
    if (prevPath?.includes("/dashboard")) {
      return t("dashboard.title");
    }
    if (prevPath?.includes("/ambassador-program")) {
      return t("becomeAmbassador.title");
    }
    if (prevPath?.includes("/staking")) {
      return t("stakingLayout.pageTitles.staking", { param: undefined });
    }
    return "";
  };

  const headerLinks = getHeaderLinks();
  const headerTitle = getHeaderTitle();

  const isRequiredPage = pathname === "/something-required";

  const isRegister = isRequiredPage && hash === "#register";
  const isKyc = isRequiredPage && hash === "#kyc";
  const isConnectTweebaa = isRequiredPage && hash === "#tweebaa";
  const isInviteFriends = isRequiredPage && hash === "#invite";

  const [getTweebaaUrl, { data: tweeBaaAuthUrl, loading }] =
    useLazyQuery(TWEE_APP_AUTH_URL);

  useEffect(() => {
    isConnectTweebaa && getTweebaaUrl();
  }, [isConnectTweebaa, getTweebaaUrl]);

  const registerData = {
    title: t("registerRequired.title"),
    text: t("registerRequired.text"),
    btnText: t("buttons.registerTweebank"),
    image: RegisterImg,
    onButtonClick: () => navigate("/create-account"),
  };
  const kycData = {
    title: t("kycRequired.title"),
    text: t("kycRequired.text"),
    btnText: t("buttons.startKyc"),
    image: UserMsgImg,
    onButtonClick: () => navigate("/kyc"),
  };
  const bindTweebaaData = {
    title: t("tweebaaRequired.title"),
    text: t("tweebaaRequired.text"),
    btnText: t("buttons.bindWithTweebaa"),
    image: AppIntegrationImg,
    onButtonClick: () => window.open(tweeBaaAuthUrl?.tweeBaaAuthUrl, "_blank"),
  };

  const data = isRegister
    ? registerData
    : isKyc
    ? kycData
    : isConnectTweebaa
    ? bindTweebaaData
    : {
        headerTitle,
        title,
        text,
        btnText,
        image,
        onButtonClick: () => onButtonClick,
      };

  const getContent = () => {
    if (isRegister) {
      return (
        <div className="invite-earn__register-layout">
          <ContentBlock
            image={RocketImg}
            title={t("inviteEarn.register.title")}
            // text={t("inviteEarn.register.text")}
            text={
              <Trans i18nKey="inviteEarn.register.text">
                Your <b>Limited Fiat Money</b>
                <br />
                Earn You <b>Unlimited Crypto Rewards</b>
                <br />
                While your <b>Fiat Money is 100% Secure.</b>
              </Trans>
            }
            btnOrLinkText={t("buttons.registerTweebank")}
            onClick={() => navigate("/create-account")}
          />
        </div>
      );
    }
    if (isConnectTweebaa) {
      return (
        <ContentBlock
          image={MetaverseImg}
          title={t("inviteEarn.metaverse.title")}
          text={t("inviteEarn.metaverse.text")}
          // btnOrLinkText={t("buttons.connectToTweeBaaApp")}
          onClick={() =>
            isAuth
              ? window.open(tweeBaaAuthUrl?.tweeBaaAuthUrl, "_blank")
              : navigate(`/something-required#register`, {
                  state: { prevPath: pathname },
                })
          }
          loading={loading}
        />
      );
    }
    if (isInviteFriends) {
      return (
        <ContentBlock
          image={InviteFriendsImg}
          title={t("landing.inviteFriends.title")}
          text={t("landing.inviteFriends.text")}
          btnOrLinkText={t("buttons.inviteFriends")}
          onClick={() =>
            isAuth
              ? setModal({ name: ModalNames.InviteFriends })
              : navigate(`/something-required#register`, {
                  state: { prevPath: pathname },
                })
          }
          className="invite-earn__card"
        />
      );
    }
    return (
      <Box
        sx={{
          fontSize: "14px",
          lineHeight: 1.5,
          padding: "30px 20px",
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="30px"
      >
        {<img src={data.image} style={{ maxWidth: "280px" }} alt="Logo" />}
        {data.title && (
          <Typography variant="h6" mt="20px" align="center">
            {data.title}
          </Typography>
        )}
        {data.text && (
          <Typography variant="subtitle1" color="#808080" align="center">
            {data.text}
          </Typography>
        )}
        <Button onClick={data.onButtonClick} loading={loading}>
          {data.btnText}
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <Header backPath={-1} title={headerTitle} />
        <HeaderLinks links={headerLinks} />
      </LocalThemeProvider>
      {getContent()}
      <TabBar />
      <InviteFriends />
    </Box>
  );
};

export default CommonPage;
