import { toast } from "react-toastify";
import { updateKycStatus } from "src/graphQl/kyc";
import KycService from "src/services/Kyc";
import { effectErrorHandler, groupBy } from "src/utils/helpers";
import { KycGate, getDocuments, setUploadedFiles, uploadDocuments } from ".";

uploadDocuments.use(async params => {
  try {
    await KycService.uploadDocuments(params);
    toast.success("Files uploaded successful");
    const { onUploadSuccess } = KycGate.state.getState();
    onUploadSuccess();
    updateKycStatus();
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

getDocuments.use(async kysDocuments => {
  try {
    if (kysDocuments?.length === 0) return;
    const groupedKycDocuments = groupBy(kysDocuments, "type", "id");
    const [idFrontFile, idBackFile, selfie] = await Promise.all([
      KycService.getFile(groupedKycDocuments["GOVERNMENT_ID_FRONT"][0]),
      KycService.getFile(groupedKycDocuments["GOVERNMENT_ID_BACK"][0]),
      KycService.getFile(groupedKycDocuments["SELFIE"][0]),
    ]);
    setUploadedFiles({
      governmentIdFrontFile: URL.createObjectURL(idFrontFile.data),
      governmentIdBackFile: URL.createObjectURL(idBackFile.data),
      selfieFile: URL.createObjectURL(selfie.data),
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
