import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import {
  $transferSuccessModal,
  setTransferSuccessModal,
} from "src/store/wallet";

interface IProps {
  onCloseFn: () => void;
}

const SuccessDialog: FC<IProps> = ({ onCloseFn }) => {
  const isOpen = useStore($transferSuccessModal);
  const { t } = useTranslation();

  const onClose = () => {
    onCloseFn();
    setTransferSuccessModal(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: 140 }}>
        <Typography
          mt={3}
          mb={4}
          textAlign="center"
          variant="h6"
          color="text.primary2"
        >
          {t("transferPage.completeTitle")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box color="text.primary" sx={{ m: "0 auto" }}>
          <LoadingButton
            variant="text"
            fullWidth
            color="inherit"
            onClick={onClose}
          >
            {t("buttons.close")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
