import { useQuery } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  getStripePayment,
  GetStripePaymentProps,
  GetStripePaymentResponse,
} from "../../graphQl/stripe";
import SuccessDialog from "./SuccessDialog";
import SuccessDialogContent from "./SuccessDialogContent";
import SuccessDialogFailedContent from "./SuccessDialogFailedContent";

type StripePaymentInfoConnectedProps = {
  sessionId?: string;
};

const StripePaymentInfoConnected: FC<StripePaymentInfoConnectedProps> = ({
  sessionId,
}) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<
    GetStripePaymentResponse,
    GetStripePaymentProps
  >(getStripePayment, {
    variables: {
      sessionId: sessionId as string,
    },
    skip: !sessionId,
  });
  const payment = data?.stripePayments.nodes[0];

  if (loading || !sessionId) {
    return (
      <Box display="flex" justifyContent="center" minHeight="150px">
        <CircularProgress size="20px" />
      </Box>
    );
  }

  if (error || !payment) {
    return (
      <SuccessDialogFailedContent
        titleTransKey="purchaseSuccessDialog.stripeErrorTitle"
        descriptionTransKey="purchaseSuccessDialog.stripeErrorText"
      />
    );
  }

  return (
    <SuccessDialogContent
      baseCurrencyAmount={payment.baseCurrencyNetAmount}
      baseCurrencyCode={"USD"}
      fee={payment.baseCurrencyFeeAmount}
      purchasedCurrencyAmount={payment.purchasedCurrencyAmount}
      purchasedCurrencyCode={payment.purchasedCurrencyCode}
      title={t("purchaseSuccessDialog.completeTitle")}
      paymentMethod={t("purchaseSuccessDialog.stripePaymentMethod")}
      feeMessage={t("purchaseSuccessDialog.strieFeeMessage")}
    />
  );
};

type SuccessStripeDialogProps = {
  open: boolean;
  onClose: () => void;
  sessionId?: string;
};

const SuccessStripeDialog: FC<SuccessStripeDialogProps> = ({
  open,
  onClose,
  sessionId,
}) => {
  return (
    <SuccessDialog open={open} onClose={onClose}>
      <StripePaymentInfoConnected sessionId={sessionId} />
    </SuccessDialog>
  );
};

export default SuccessStripeDialog;
