import { CSSProperties, FC, ReactNode } from "react";
import cs from "classnames";

import "./index.scss";

interface IProps {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  children?: string | ReactNode;
  iconLeft?: ReactNode;
  style?: CSSProperties;
}

const Button: FC<IProps> = ({
  className,
  onClick,
  disabled,
  loading,
  children,
  iconLeft,
  style,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      disabled={disabled}
      className={cs("button", className)}
    >
      {iconLeft}
      {loading ? "Loading..." : children}
    </button>
  );
};

export default Button;
