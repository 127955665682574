// import { useLocation, useNavigate } from "react-router-dom";
// import { useGate } from "effector-react";
// import { isValidPhoneNumber } from "react-phone-number-input";

import "./index.scss";
import Logo from "src/assets/register/Login.png";
import UnAuthLayout from "src/components/layouts/UnAuthLayout";
// import Input from "src/components/common/Input";
import Button from "src/components/common/Button";
import LoginLink from "src/components/common/LoginRegisterLink";
// import {
//   // $password,
//   // $passwordError,
//   // $phone,
//   // $phoneError,
//   // getConfirmationCode,
//   ScreenGate,
//   // setPassword,
//   // setPhone,
// } from "src/store/account";
// import PhoneInput from "src/components/common/PhoneInput";
// import { PhoneVerificationRequestType } from "src/services/User";
// import { useTranslation } from "react-i18next";
import { API_URL, LOGIN_REDIRECT_URL } from "src/config";
import { useEffect } from "react";
// import { USE_TEST_NUMBER } from "src/config";

const Login = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // useGate(ScreenGate, {
  //   onSuccess: () => navigate("/confirm-code-login", { state: location.state }),
  // });
  // // const phone = useStore($phone);
  // // const error = useStore($phoneError);

  // // const password = useStore($password);
  // // const passwordError = useStore($passwordError);

  // const { t } = useTranslation();

  const login = () => {
    window.open(API_URL + `/Auth/login?redirectUrl=${LOGIN_REDIRECT_URL}`, "_self");
  };

  useEffect(() => {
    window.open(API_URL + `/Auth/login?redirectUrl=${LOGIN_REDIRECT_URL}`, "_self");
  }, []);

  return (
    <UnAuthLayout className="login" img={Logo} hAndroid>
      {/* <PhoneInput
        onChange={setPhone}
        value={phone}
        placeholder={t("placeholders.phoneNumber")}
        error={error}
        className="login__phone-input"
      />
      <Input
        placeholder={t("placeholders.password")}
        error={passwordError}
        isPassword
        value={password}
        onChange={value => setPassword(value)}
      />
      <div className="login__forgot-password">
        <div onClick={() => navigate("/reset-password")}>
          {t("changePasswordForm.forgotPasswordQuestion")}
        </div>
      </div> */}
      
      <Button
        onClick={() => login()}
        // onClick={() =>
        //   getConfirmationCode({
        //     phone,
        //     type: PhoneVerificationRequestType.Login,
        //     requirePassword: true,
        //     password,
        //   })
        // }
        className="login__login-btn"
        // loading={loading}
        // disabled={loading || (!isValidPhoneNumber(phone) && !USE_TEST_NUMBER)}
      >
        Wait please...
        {/* {t("buttons.login")} */}
      </Button>
      <LoginLink toRegister />
    </UnAuthLayout>
  );
};

export default Login;
