import { FC, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
// import { useCurrentUser } from "src/graphQl/profile";
// import UserService from "../services/User";
// import { useApolloClient } from "@apollo/client";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { loginFx } from "src/store/auth";

const ConnectTweeBaaAppFallback: FC = () => {
  // const { t } = useTranslation();
  // const apolloClient = useApolloClient();
  // const { user, loading } = useCurrentUser();
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get("code");
  const loading = useStore(loginFx.pending);
  // const alreadyConnected = !!user?.tweeBaaAppUser;

  // useEffect(() => {
  //   if (!loading && !alreadyConnected && code) {
  //     (async () => {
  //       try {
  //         await UserService.connectTweeBaaApp(code);
  //         apolloClient.refetchQueries({
  //           include: ["Profile"],
  //         });
  //         toast.success(t("connectTweebaaApp.successNotification"));
  //       } catch (err) {
  //         console.log(err);
  //         toast.warn(t("connectTweebaaApp.failNotification"));
  //         navigate("/profile");
  //       }
  //     })();
  //   }
  // }, [alreadyConnected, loading, code, apolloClient, navigate, t]);

  useEffect(() => {
    const loginHandler = async (loginCode: string) => {
      await loginFx(loginCode);
      navigate("/");
    };

    code && loginHandler(code);
  }, []);

  if (!code) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return null;
};

export default ConnectTweeBaaAppFallback;
