import { gql } from "@apollo/client";

export interface PaypalPayments {
  id: number;
  userId: number;
  eventId: string;
  orderId: string;
  baseCurrencyCode: string;
  baseCurrencyAmount: number;
  payPalFee: number;
  purchasedCurrencyId: number;
  purchasedCurrencyCode: string;
  purchasedCurrencyAmount: number;
  createdDate: string;
  updatedDate: string;
}

export type PyapalPaymentsQueryResponse = {
  payPalPayments: { nodes: PaypalPayments[] };
};

export const PAYPAL_PAYMENTS = gql`
  query payPalPayments(
    $where: PayPalPaymentFilterInput
    $order: [PayPalPaymentSortInput!]
    $first: Int
  ) {
    payPalPayments(where: $where, order: $order, first: $first) {
      nodes {
        id
        userId
        eventId
        orderId
        baseCurrencyCode
        baseCurrencyAmount
        payPalFee
        purchasedCurrencyId
        purchasedCurrencyCode
        purchasedCurrencyAmount
        createdDate
        updatedDate
      }
    }
  }
`;
