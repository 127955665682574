import { AxiosResponse } from "axios";
import $api from "./http";

export interface IKycUploadDocumentsParams {
  governmentIdFrontFile?: File;
  governmentIdBackFile?: File;
  selfieFile?: File;
}

export default class KycService {
  static uploadDocuments(
    params: IKycUploadDocumentsParams
  ): Promise<AxiosResponse<string>> {
    const { governmentIdFrontFile, governmentIdBackFile, selfieFile } = params;
    const data = new FormData();
    governmentIdFrontFile &&
      data.set("governmentIdFrontFile", governmentIdFrontFile);
    governmentIdBackFile &&
      data.set("governmentIdBackFile", governmentIdBackFile);
    selfieFile && data.set("selfieFile", selfieFile);
    return $api.post<string>("/Kyc/upload_documents", data);
  }

  static getFile(fileId: number): Promise<AxiosResponse<Blob>> {
    return $api.get<Blob>("/Kyc/get_file", {
      params: { fileId },
      responseType: "blob",
    });
  }
}
