import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import "../PageNotFound/index.scss";

type PageInternalErrorProps = {
  resetError: () => void;
};

const PageInternalError: FC<PageInternalErrorProps> = ({ resetError }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onButtonClick = () => {
    resetError();
    navigate("/");
  };

  return (
    <div className="page-not-found">
      <div>500</div>
      <div>{t("pageInternalError.description")}</div>
      <Button onClick={onButtonClick} className="landing__btn">
        Back to Homepage
      </Button>
    </div>
  );
};

export default PageInternalError;
