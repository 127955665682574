import { useStore } from "effector-react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "src/components/common/Button";
import {
  $email,
  $message,
  $name,
  contactUs,
  setEmail,
  setMessage,
  setName,
} from "src/store/landing";
import "./index.scss";

interface IProps {
  hideTitle?: boolean;
}

const LandingContactUs: FC<IProps> = ({ hideTitle }) => {
  const { t } = useTranslation();
  const name = useStore($name);
  const email = useStore($email);
  const message = useStore($message);
  const loading = useStore(contactUs.pending);

  return (
    <div className="landing-contact-us">
      {!hideTitle && (
        <div className="landing-contact-us__title">{t("contactUs.title")}</div>
      )}
      <div className="landing-contact-us__card">
        <input
          className="landing-contact-us__input"
          placeholder={t("contactUs.namePlaceholder")}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          className="landing-contact-us__input"
          placeholder={t("contactUs.emailPlaceholder")}
          value={email}
          onChange={e => setEmail(e.target.value)}
          inputMode="email"
        />
        <textarea
          className="landing-contact-us__textarea"
          placeholder={t("contactUs.messagePlaceholder")}
          value={message}
          onChange={e => setMessage(e.target.value)}
        ></textarea>
        <Button
          onClick={() => contactUs({ name, email, message })}
          className="landing__btn"
          loading={loading}
          disabled={loading}
        >
          {t("buttons.contactUsSend")}
        </Button>
      </div>
    </div>
  );
};

export default LandingContactUs;
