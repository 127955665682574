import { AxiosResponse } from "axios";
import $api from "./http";
import { int } from "@zxing/library/esm/customTypings";

export interface PageResult<T>{
    page:int;
    size:int;
    total:int;
    items:T[];
}
export interface CustomerOrder{
    id:string;
    agencyOrderId:string;
    itemCount:number;
    agency:string;
    totalPrice:number;
    orderCompleteTime:Date;
}
export interface CustomerOrderItem{
    id:string;
    orderId:string;
    agencyOrderId:string;
    productId:string;
    productName:string;
    productCount:number;
    price:number;
}
export default class CustomerOrderService{
    
    static getMyOrders(page:int,size:int):Promise<PageResult<CustomerOrder>>{
        const path = `/External/deposit/my-customer-orders?page=${page}&size=${size}`;
        return $api.get(path)
        .then(result=>result.data);
    }
}