import { Box } from "@mui/material";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "src/components/common/Header";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import ambassadorIcon from "src/assets/ambassador-icon2.png";

import "./index.scss";
import Button from "src/components/common/Button";
import UserService from "src/services/User";
import { $isAuth } from "src/store/account";
import { useStore } from "effector-react";

interface IProps {
  withoutHeader?: boolean;
}

const AmbassadorProgram: FC<IProps> = ({ withoutHeader }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = useStore($isAuth);
  const { pathname } = useLocation();

  const apply = () => {
    if (!isAuth) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    UserService.setAmbassadorInProcess();
    navigate("/profile/ambassador");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
      className="ambassador-program"
    >
      {!withoutHeader && (
        <LocalThemeProvider theme="dark">
          <Header title={t("ambassadorProgram.headerTitle")} backPath={-1} />
        </LocalThemeProvider>
      )}
      <div className="ambassador-program__content">
        <img
          className="ambassador-program__logo"
          src={ambassadorIcon}
          alt="ambassador"
        />
        <div className="ambassador-program__title">
          {t("ambassadorProgram.title1")}
        </div>
        <div className="ambassador-program__text">
          {t("ambassadorProgram.section1")}
          <ol>
            <li>{t("ambassadorProgram.section1point1")}</li>
            <li>{t("ambassadorProgram.section1point2")}</li>
            <li>{t("ambassadorProgram.section1point3")}</li>
          </ol>
        </div>
        <div className="ambassador-program__title">
          {t("ambassadorProgram.title2")}
        </div>
        <div className="ambassador-program__text">
          {t("ambassadorProgram.section2")}
          <ul>
            <li>{t("ambassadorProgram.section2point1")}</li>
            <li>{t("ambassadorProgram.section2point2")}</li>
            <li>{t("ambassadorProgram.section2point3")}</li>
            <li>{t("ambassadorProgram.section2point4")}</li>
            <li>{t("ambassadorProgram.section2point5")}</li>
          </ul>
        </div>
        <Button onClick={apply} className="ambassador-program__btn">
          {t("ambassadorProgram.apply")}
        </Button>
      </div>
      {/* <CommonModal modalName={ModalNames.Register} /> */}
    </Box>
  );
};

export default AmbassadorProgram;
