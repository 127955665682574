import { AxiosResponse } from "axios";
import $api from "./http";

interface ICheckCode {
  code: string;
  phoneNumber: string;
  smsVerificationOperationId: string;
  phoneCode: string;
}

interface ISignUp {
  smsVerificationOperationId: string;
  password: string;
  invitationCode: string;
  firstName: string;
  lastName: string;
  email?: string;
  captchaToken: string;
}

interface ISignUpResetPassResponse {
  accessToken: string;
  refreshToken: string;
}

interface IResetPassword {
  smsVerificationOperationId: string;
  password: string;
}

export enum PhoneVerificationRequestType {
  Signup,
  ResetPassword,
  LinkTweeBaaAccount,
  Login,
  ResetTransactionPassword,
}

interface IGetSmsCodeParams {
  phoneNumber: string;
  type: PhoneVerificationRequestType;
  phoneCode: string;
}

export interface ITransferTbeansParams {
  amount: number;
  toTweeBaa: boolean;
}

export default class UserService {
  static getSmsCode(params: IGetSmsCodeParams): Promise<AxiosResponse<string>> {
    return $api.post<string>("/User/sms", params);
  }

  // static signUpGetCode(phoneNumber: string): Promise<AxiosResponse<string>> {
  //   return $api.post<string>("/User/sms/signup", { phoneNumber });
  // }

  // static resetPassGetCode(phoneNumber: string): Promise<AxiosResponse<string>> {
  //   return $api.post<string>("/User/sms/reset", { phoneNumber });
  // }

  static checkCode(params: ICheckCode): Promise<AxiosResponse<string>> {
    return $api.post<string>("/User/sms/result", params);
  }

  static signUp(
    params: ISignUp
  ): Promise<AxiosResponse<ISignUpResetPassResponse>> {
    return $api.post<ISignUpResetPassResponse>(
      `/User/signup?captchaResponse=${params.captchaToken}`,
      {
        ...params,
        captchaToken: undefined,
      }
    );
  }

  static resetPassword(
    params: IResetPassword
  ): Promise<AxiosResponse<ISignUpResetPassResponse>> {
    return $api.post<ISignUpResetPassResponse>("/User/reset_password", params);
  }
  static resetTransactionPassword(params: IResetPassword) {
    return $api.post("/User/reset_transaction_password", params);
  }
  static setTransactionPassword(password: string) {
    return $api.post("/User/set_transaction_password", { password });
  }

  static connectTweeBaaApp(code: string) {
    return $api.post("/User/link_twee_baa_app", { code });
  }
  static transferTbeans(params: ITransferTbeansParams) {
    return $api.post("/User/transfer_tbeans", params);
  }
  static setAmbassadorInProcess() {
    return $api.post("/User/set_ambassador_in_process");
  }
}
