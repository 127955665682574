import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useGate } from "effector-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

import Button from "src/components/common/Button";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import { IStake, StakingState, USER_STAKES } from "src/graphQl/userStakes";
import { StakeConfirmGate } from "src/store/staking";
import "./index.scss";
import { getDurationBy2Dates, round } from "src/utils/helpers";
import { useTranslation } from "react-i18next";
import { DEFAULT_ROUND } from "src/config";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";
import FinishedStakes from "src/components/staking/FinishedStakes";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const StakingSummary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useGate(StakeConfirmGate, { onConfirm: () => navigate(`/staking`) });
  const baseCurrencySymbol = useBaseCurrencySymbol();

  const [getStakes, { data }] = useLazyQuery(USER_STAKES);

  useEffect(() => {
    getStakes({
      variables: {
        first: 100,
        where: {
          state: {
            eq: StakingState.ACTIVE,
          },
        },
      },
    });
  }, [getStakes]);

  const stakes = data?.userStakes?.nodes;
  const totalInvested = stakes?.reduce((acc: number, cur: IStake) => {
    acc += cur.baseAmount;
    return acc;
  }, 0);
  const totalExpectedReturn =
    stakes?.reduce((acc: number, cur: IStake) => {
      acc += cur.baseInterestAmount;
      return acc;
    }, 0) || 0;

  return (
    <div className="staking-summary">
      <div className="staking-summary__title">
        {t("stakingSummaryPage.pageTitle")}
      </div>
      <div className="staking-summary__card">
        <div className="staking-summary__card-item">
          <div className="staking-summary__card-item-label">
            {t("stakingSummaryPage.totalStakedTitle")}
          </div>
          <div className="staking-summary__card-item-value">
            {baseCurrencySymbol}
            {round(totalInvested, DEFAULT_ROUND)}
          </div>
        </div>
        <div className="staking-summary__card-item">
          <div className="staking-summary__card-item-label">
            {t("stakingSummaryPage.totalInvestedReturnTitle")}
          </div>
          <div className="staking-summary__card-item-value">
            {baseCurrencySymbol}
            {round(totalExpectedReturn, DEFAULT_ROUND)}
          </div>
        </div>
      </div>
      {stakes?.length > 0 && (
        <div className="staking-summary__table-titles">
          <div className="staking-summary__table-title">
            {t("stakingSummaryPage.tableTitles.amount")}
          </div>
          <div className="staking-summary__table-title">
            {t("stakingSummaryPage.tableTitles.return")}
          </div>
          <div className="staking-summary__table-title">
            {t("stakingSummaryPage.tableTitles.remaining")}
          </div>
        </div>
      )}
      <div className="staking-summary__stakes">
        {stakes?.map((i: IStake) => {
          const remaining = getDurationBy2Dates(i.endDate);
          return (
            <div
              key={i.id}
              className="staking-summary__stake-item"
              onClick={() =>
                navigate(
                  `/staking/staked-details/${i.currency.name}?stakeId=${i.id}`
                )
              }
            >
              <CurrencyIcon
                currency={i.currency.name as string}
                style={{ width: 16, height: 16, minWidth: 16 }}
              />
              <div className="staking-summary__amount">
                {i.amount} {i.currency.name}
              </div>
              <div className="staking-summary__interest">{i.interest}%</div>
              <div className="staking-summary__remaining">{remaining}</div>
            </div>
          );
        })}
      </div>

      <Button
        className="staking-summary__btn"
        onClick={() => navigate(`/staking`)}
      >
        {t("buttons.stake")}
      </Button>

      <FinishedStakes />
    </div>
  );
};

export default StakingSummary;
