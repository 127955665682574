import { NetworkStatus, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { useTranslation } from "react-i18next";

import CurrencyIcon from "src/components/common/CurrencyIcon";
import { IStake, StakingState, USER_STAKES } from "src/graphQl/userStakes";
import "./index.scss";
import LoadMore from "src/components/common/LoadMore";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const FinishedStakes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getStakes, { data, fetchMore, networkStatus }] =
    useLazyQuery(USER_STAKES);

  useEffect(() => {
    getStakes({
      variables: {
        first: 5,
        where: {
          state: {
            eq: StakingState.DONE,
          },
        },
      },
      notifyOnNetworkStatusChange: true,
    });
  }, [getStakes]);

  const stakes = data?.userStakes?.nodes || [];
  const showLoadMore = data?.userStakes?.pageInfo?.hasNextPage;
  const endCursor = data?.userStakes?.pageInfo?.endCursor;

  if (stakes?.length === 0) {
    return null;
  }

  return (
    <div className="finished-stakes">
      <div className="finished-stakes__title">
        {t("stakingSummaryPage.finishedStakes")}
      </div>
      <div className="finished-stakes__table-titles">
        <div className="finished-stakes__table-title">
          {t("stakingSummaryPage.finishedTableTitles.stakeAmount")}
        </div>
        <div className="finished-stakes__table-title">
          {t("stakingSummaryPage.finishedTableTitles.finishedDate")}
        </div>
      </div>
      <div className="finished-stakes__stakes">
        {stakes?.map((i: IStake) => {
          return (
            <div
              key={i.id}
              className="finished-stakes__stake-item"
              onClick={() =>
                navigate(
                  `/staking/finished-staking-details/${i.currency.name}?stakeId=${i.id}`
                )
              }
            >
              <CurrencyIcon
                currency={i.currency.name as string}
                style={{ width: 16, height: 16, minWidth: 16 }}
              />
              <div className="finished-stakes__amount">
                {i.amount} {i.currency.name}
              </div>
              <div className="finished-stakes__finished-date">
                {dayjs(i.endDate).format("D MMM YYYY")}
              </div>
            </div>
          );
        })}
      </div>
      {showLoadMore && (
        <LoadMore
          onClick={() =>
            fetchMore({
              variables: {
                after: endCursor,
              },
              updateQuery: (prevResult: any, { fetchMoreResult }) => {
                fetchMoreResult.userStakes.nodes = [
                  ...prevResult.userStakes.nodes,
                  ...fetchMoreResult.userStakes.nodes,
                ];
                return fetchMoreResult;
              },
            })
          }
          loading={networkStatus === NetworkStatus.fetchMore}
        />
      )}
    </div>
  );
};

export default FinishedStakes;
