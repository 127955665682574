import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import "./index.scss";

interface ILink {
  label: string;
  path: string;
  exact?: boolean;
}

interface IProps {
  links?: ILink[];
}

const HeaderLinks: FC<IProps> = ({ links }) => {
  const { t } = useTranslation();
  const activeStyle = {
    borderBottom: "4px solid #EBBB71",
  };

  return (
    <div className="header-links">
      {links?.map((l, index) => (
        <NavLink
          key={index}
          style={({ isActive }) => (isActive ? activeStyle : {})}
          className="header-links__link"
          to={l.path}
          end={l.exact}
        >
          {t(l.label)}
        </NavLink>
      ))}
    </div>
  );
};

export default HeaderLinks;
