export const API_URL =
  process.env.REACT_APP_API_URL || "https://tweebank-api.tweebaa-stg.com";

export const CAPTCHA_SITEKEY =
  process.env.REACT_APP_CAPTCHA_SITEKEY ||
  "10000000-ffff-ffff-ffff-000000000001";

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const TWEBAA_APP_STORE_LINK =
  "https://apps.apple.com/app/tweebaa/id1467778439";

export const DEFAULT_ROUND = 0.0001;
export const DEFAULT_DECIMAL_PLACES = 4;
export const USE_TEST_NUMBER = !!localStorage.getItem("useTestNumber");
export const CONTACTS = {
  phone: "(123) 456-7890",
  email: "contact@tweebank.com",
};
export const AVAILABLE_LANGUAGES = ["en", "cn"];

export const SHOW_SWITCH_LANG_PATHS = [
  "/",
  "/create-account",
  "/confirm-code",
  "/setup-password",
  "/setup-tx-password",
  "/reset-password",
  "/reset-transaction-password",
  "/confirm-code-reset-password",
  "/confirm-code-transaction-password",
  "/login",
  "/confirm-code-login",
];

export const SENTRY_DSN = process.env.SENTRY_DSN;

export const LOGIN_REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL;
