import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { SettingsType } from "src/graphQl/settings";

import utc from "dayjs/plugin/utc";
import { AVAILABLE_LANGUAGES, DEFAULT_DECIMAL_PLACES } from "src/config";
import { ModalNames } from "src/types/common";
dayjs.extend(utc);

export const effectErrorHandler = (err: any): string => {
  console.dir(err);
  const msg =
    err?.response?.data?.Errors?.[0]?.Msg ||
    err?.data?.message ||
    err?.message ||
    "Unknown error";
  toast.warn(msg);
  return msg;
};

export const isValidEmail = (email: string) => {
  const re =
    //eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.trim().toLowerCase());
};

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getOnlyNumbers = (value: string): string => {
  const reg = new RegExp(/(\d+([.,]\d*)?|[.]\d+)?/);
  if (reg.test(value)) {
    return value?.match(reg)?.[0] || "";
  }
  return "";
};

export const getDecimalsCount = (v: number) => {
  const s = v.toString(),
    i = s.indexOf(".") + 1;
  return i && s.length - i;
};

export const round = (value?: number, step = 1) => {
  if (!value) {
    return 0;
  }
  const factor = 10 ** getDecimalsCount(step);
  const inv = (1 * factor) / (step * factor); // === 1 / step
  return Math.round(value * inv) / inv;
};

export const ceilDecimal = (value: number, step = 1) => {
  const factor = 10 ** getDecimalsCount(step);
  const inv = (1 * factor) / (step * factor); // === 1 / step
  return Math.ceil(value * inv) / inv;
};

export const isValidNumber = (value: string) =>
  /^(\d+([.]\d*)?|[.]\d+)?$/.test(value);

const isPasswordLengthValid = (password: string) => password.length > 5;
const isPasswordLowercaseValid = (password: string) =>
  /(?=.*[a-z])/.test(password);
const isPasswordDigitValid = (password: string) => /(?=.*[0-9])/.test(password);
const isPasswordUppercaseValid = (password: string) =>
  /(?=.*[A-Z])/.test(password);
const isPasswordNonAlphanumericValid = (password: string) =>
  /(?=.*[-+_!@#$%^&*.,?()=])/.test(password);

export const validatePassword = (password: string | undefined) => {
  const requirements = [
    {
      message: "Passwords length at least 6 characters.",
      validate: isPasswordLengthValid,
    },
    {
      message: "Passwords must have at least one digit ('0'-'9').",
      validate: isPasswordDigitValid,
    },
    {
      message: "Passwords must have at least one lowercase ('a'-'z').",
      validate: isPasswordLowercaseValid,
    },
    {
      message: "Passwords must have at least one uppercase ('A'-'Z').",
      validate: isPasswordUppercaseValid,
    },
    {
      message: "Passwords must have at least one non alphanumeric character.",
      validate: isPasswordNonAlphanumericValid,
    },
  ];
  if (!password) {
    return "Required";
  }
  const error = requirements.find(r => !r.validate(password));
  if (error) {
    return error.message;
  }
};

export const groupBy = (xs: any, key: string, field?: string) =>
  xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(field && x[field] ? x[field] : x);
    return rv;
  }, {});

export const calculateExpectedReturn = ({
  amount = "0",
  // baseInterest = 1,
  period = "0",
  price = 1,
  // tiv = 1,
  percent = 1,
}: {
  amount: string | number;
  // baseInterest: string | number;
  period: string | number;
  price: string | number;
  // tiv: string | number;
  percent: string | number;
}) => {
  // console.log({ amount, percent, period, price });
  return new BigNumber(amount)
    .multipliedBy(percent)
    .div(100)
    .div(12)
    .multipliedBy(period)
    .multipliedBy(price)
    .dp(4)
    .toString();
  // return new BigNumber(amount)
  //   .multipliedBy(baseInterest)
  //   .div(100)
  //   .div(12)
  //   .multipliedBy(period)
  //   .multipliedBy(price)
  //   .multipliedBy(tiv)
  //   .dp(4)
  //   .toString();
};

export const isTbean = (currencyName?: string | null) =>
  currencyName?.toLowerCase() === "t-bean";

export const objFromArr = (key: string, arr?: [], field?: string): any =>
  arr?.reduce(
    (acc, cur) => ({ ...acc, [cur[key]]: field ? cur[field] : cur }),
    {}
  );

export const getStakingPercent = (
  percent: number,
  settingsObj: { [k in SettingsType]: string },
  tiv?: number | null
) => {
  const bnPercent = new BigNumber(percent);
  let calculatedPercent = bnPercent;
  if (!tiv || tiv === 0) {
    calculatedPercent = bnPercent.minus(settingsObj.NO_TIV_STAKE_INTEREST);
  }
  if (tiv && tiv > +settingsObj.TIV_THRESHOLD) {
    calculatedPercent = bnPercent.plus(settingsObj.TIV_STAKE_POSITIVE_INTEREST);
  }
  if (tiv && tiv <= +settingsObj.TIV_THRESHOLD) {
    calculatedPercent = bnPercent.minus(
      settingsObj.TIV_STAKE_NEGATIVE_INTEREST
    );
  }
  return calculatedPercent.lt(0) ? 0 : calculatedPercent.toNumber();
};

export const getDurationBy2Dates = (
  endDateStr: string,
  startDateStr?: string
) => {
  const endDate = dayjs(endDateStr).utc();
  const startDate = startDateStr ? dayjs(startDateStr) : dayjs.utc();
  const duration = dayjs.duration(endDate.diff(startDate));
  const months = duration?.months();
  const d = duration?.days();
  const h = duration?.hours();
  const m = duration?.minutes();
  const formattedDuration = `${
    months > 0 ? (months === 1 ? `${months} month` : `${months} months`) : ""
  } ${months === 0 && d > 0 ? (d === 1 ? `${d} day` : `${d} days`) : ""} ${
    months === 0 && d === 0 && (h > 0 || m > 0) ? "< 1 day" : ""
  }`;
  return formattedDuration;
};

export const roundDecimal = (value: string | number, decimalPlaces?: number) =>
  new BigNumber(value).dp(decimalPlaces || DEFAULT_DECIMAL_PLACES).toNumber();

export const getModalName = ({
  user,
  kycRequired,
  appConnected,
}: {
  user?: boolean;
  kycRequired?: boolean;
  appConnected?: boolean;
}) =>
  !user
    ? ModalNames.Register
    : kycRequired
    ? ModalNames.Kyc
    : !appConnected
    ? ModalNames.ConnectTweebaa
    : null;

export const getLanguage = () => {
  const navigatorLanguage = navigator.language.split("-")[0];
  return (
    localStorage.getItem("LANGUAGE") ||
    (AVAILABLE_LANGUAGES.includes(navigatorLanguage) ? navigatorLanguage : "en")
  );
};
