import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGate, useStore } from "effector-react";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
// import cs from "classnames";

import "./index.scss";
import AuthLayout from "src/components/layouts/AuthLayout";
import Button from "src/components/common/Button";
import FileDropzone from "src/components/common/FileDropzone";
import { TFileWithPreview } from "src/types/common";
import {
  $files,
  $uploadedFiles,
  getDocuments,
  KycGate,
  setFiles,
  uploadDocuments,
} from "src/store/kyc";
import PassportImg from "src/assets/kyc/Passport.png";
import SelfieImg from "src/assets/kyc/Selfie.png";
import { KycStatus, useKyc } from "src/graphQl/kyc";

const ImageLoader = () => {
  return (
    <ContentLoader
      viewBox="0 0 300 240"
      height={240}
      width={300}
      backgroundColor="#f5f6f7"
      foregroundColor="#eee"
      // foregroundColor="rgba(235, 187, 113, 0.01)"
      style={{ margin: "0 auto" }}
      speed={2}
    >
      <rect x="0" y="0" rx="8" ry="8" width="300" height="240" />
    </ContentLoader>
  );
};

const Kyc = () => {
  const { t } = useTranslation();
  const { kyc } = useKyc();

  const navigate = useNavigate();
  useGate(KycGate, { onUploadSuccess: () => navigate("/") });
  const files = useStore($files);
  const uploadedFiles = useStore($uploadedFiles);
  const uploadingDocuments = useStore(uploadDocuments.pending);
  const loadingDocuments = useStore(getDocuments.pending);

  useEffect(() => {
    kyc && kyc.status !== KycStatus.DECLINE && getDocuments(kyc.documents);
  }, [kyc]);

  const onAttachmentChange = async (
    fieldName: string,
    newFiles: TFileWithPreview[]
  ) => {
    if (newFiles.length) {
      setFiles({
        [fieldName]: Object.assign(newFiles[0], {
          preview: URL.createObjectURL(newFiles[0]),
        }),
      });
    }
  };

  // useEffect(
  //   () => () => {
  //     Object.values(files).forEach(
  //       i => i?.preview && URL.revokeObjectURL(i.preview)
  //     );
  //   },
  //   [files]
  // );

  const renderFile = (file: TFileWithPreview) => {
    if (file?.type.includes("image") && file?.preview) {
      return <img className="kyc__image" src={file?.preview} alt="Kyc" />;
    }
  };

  const { governmentIdFrontFile, governmentIdBackFile, selfieFile } = files;
  const noFilesAttached =
    !governmentIdFrontFile && !governmentIdBackFile && !selfieFile;

  const isApproved = kyc?.status === KycStatus.APPROVED;
  const isDeclined = kyc?.status === KycStatus.DECLINE;

  const statusColor =
    kyc?.status === KycStatus.PENDING
      ? "blue"
      : isDeclined
      ? "#FA0404"
      : "green";

  const status =
    kyc?.status === KycStatus.PENDING
      ? "Pending"
      : isDeclined
      ? "Declined"
      : isApproved
      ? "Approved"
      : null;

  const filesData = [
    {
      fileName: "governmentIdFrontFile",
      title: t("kyc.governmentIdFrontSideTitle"),
      localFile: files.governmentIdFrontFile,
      uploadedFile: uploadedFiles.governmentIdFrontFile,
      imgPlaceholder: PassportImg,
      imgAlt: "Government Id Front File",
      imgDescription: t("kyc.governmentIdFrontSideDescription"),
    },
    {
      fileName: "governmentIdBackFile",
      title: t("kyc.governmentIdBackSideTitle"),
      localFile: files.governmentIdBackFile,
      uploadedFile: uploadedFiles.governmentIdBackFile,
      imgPlaceholder: PassportImg,
      imgAlt: "Government Id Back File",
      imgDescription: t("kyc.governmentIdBackSideDescription"),
    },
    {
      fileName: "selfieFile",
      title: t("kyc.selfieTitle"),
      localFile: files.selfieFile,
      uploadedFile: uploadedFiles.selfieFile,
      imgPlaceholder: SelfieImg,
      imgAlt: "Selfie",
      imgDescription: t("kyc.selfieDescription"),
    },
  ];

  const submitDisabled =
    kyc?.documents?.length === 0 &&
    !(governmentIdFrontFile && governmentIdBackFile && selfieFile);

  return (
    <AuthLayout
      className="kyc"
      headerTitle={t("pageTitle.kyc")}
      headerBackPath={-1}
    >
      {status && (
        <div className="kyc__status-card">
          <div className="kyc__status">
            {t("Status")}: <span style={{ color: statusColor }}>{status}</span>
          </div>
          {/* <div className="kyc__text">{t("kyc.baseInstruction")}</div> */}
          {isDeclined && kyc?.lastAdminComment && (
            <div className="kyc__text">{kyc?.lastAdminComment}</div>
          )}
        </div>
      )}

      {filesData.map((i, ind) => {
        return (
          <Fragment key={i.title}>
            <div className="kyc__title">
              {++ind}. {i.title}
            </div>
            {loadingDocuments ? (
              <ImageLoader />
            ) : (
              <FileDropzone
                className="kyc__dropzone"
                child={
                  <>
                    {i.localFile ? (
                      renderFile(i.localFile)
                    ) : i.uploadedFile ? (
                      <img
                        className="kyc__image"
                        src={i.uploadedFile}
                        alt={i.imgAlt}
                      />
                    ) : (
                      <div className="kyc__dropzone_empty">
                        <img src={i.imgPlaceholder} alt={i.imgAlt} />
                        <div>{i.imgDescription}</div>
                      </div>
                    )}
                    {(i.localFile || i.uploadedFile) && !isApproved && (
                      <Button
                        onClick={() => navigate(`/kyc`)}
                        className="kyc__change-img"
                      >
                        {t("buttons.changePhoto")}
                      </Button>
                    )}
                  </>
                }
                onDropFile={newFiles =>
                  onAttachmentChange(i.fileName, newFiles)
                }
              />
            )}
          </Fragment>
        );
      })}

      {/* <div className="kyc__title">1. {t("kyc.governmentIdFrontSideTitle")}</div>
      {loadingDocuments ? (
        <Loader />
      ) : (
        <FileDropzone
          className="kyc__dropzone"
          child={
            <>
              {files.governmentIdFrontFile ? (
                renderFile(files.governmentIdFrontFile)
              ) : uploadedFiles.governmentIdFrontFile ? (
                <img
                  className="kyc__image"
                  src={uploadedFiles.governmentIdFrontFile}
                  alt="Government Id Front File"
                />
              ) : (
                <div className="kyc__dropzone_empty">
                  <img src={PassportImg} alt="Passport" />
                  <div>{t("kyc.governmentIdFrontSideDescription")}</div>
                </div>
              )}
              {files.governmentIdFrontFile && (
                <Button
                  onClick={() => navigate(`/kyc`)}
                  className="kyc__change-img"
                >
                  {t("buttons.changePhoto")}
                </Button>
              )}
            </>
          }
          onDropFile={newFiles =>
            onAttachmentChange("governmentIdFrontFile", newFiles)
          }
        />
      )} */}

      {/* <div className="kyc__title">2. {t("kyc.governmentIdBackSideTitle")}</div>
      {loadingDocuments ? (
        <Loader />
      ) : (
        <FileDropzone
          className="kyc__dropzone"
          child={
            <>
              {files.governmentIdBackFile ? (
                renderFile(files.governmentIdBackFile)
              ) : uploadedFiles.governmentIdBackFile ? (
                <img
                  className="kyc__image"
                  src={uploadedFiles.governmentIdBackFile}
                  alt="Government Id Back File"
                />
              ) : (
                <div className="kyc__dropzone_empty">
                  <img src={PassportImg} alt="Passport" />
                  <div>{t("kyc.governmentIdBackSideDescription")}</div>
                </div>
              )}
              {(files.governmentIdBackFile ||
                uploadedFiles.governmentIdBackFile) && (
                <Button
                  onClick={() => navigate(`/kyc`)}
                  className="kyc__change-img"
                >
                  {t("buttons.changePhoto")}
                </Button>
              )}
            </>
          }
          onDropFile={newFiles =>
            onAttachmentChange("governmentIdBackFile", newFiles)
          }
        />
      )} */}

      {/* <div className="kyc__title">3. {t("kyc.selfieTitle")}</div>
      {loadingDocuments ? (
        <Loader />
      ) : (
        <FileDropzone
          className="kyc__dropzone"
          child={
            <>
              {files.selfieFile ? (
                renderFile(files.selfieFile)
              ) : uploadedFiles.selfieFile ? (
                <img
                  className="kyc__image"
                  src={uploadedFiles.selfieFile}
                  alt="Government Selfie File"
                />
              ) : (
                <div className="kyc__dropzone_empty">
                  <img src={SelfieImg} alt="Selfie" />
                  <div>{t("kyc.selfieDescription")}</div>
                </div>
              )}
              {files.selfieFile && (
                <Button
                  onClick={() => navigate(`/kyc`)}
                  className="kyc__change-img"
                >
                  {t("buttons.changePhoto")}
                </Button>
              )}
            </>
          }
          onDropFile={newFiles => onAttachmentChange("selfieFile", newFiles)}
        />
      )} */}

      {!isApproved && (
        <>
          <div className="kyc__instructions">
            <div className="kyc__instructions-title">{t("Instructions")}: </div>
            <div className="kyc__instructions-text">
              {t("kyc.instructions")}
            </div>
          </div>
          <Button
            onClick={() =>
              !noFilesAttached &&
              uploadDocuments({
                governmentIdFrontFile,
                governmentIdBackFile,
                selfieFile,
              })
            }
            className="kyc__submit"
            disabled={
              noFilesAttached ||
              uploadingDocuments ||
              loadingDocuments ||
              submitDisabled
            }
            loading={uploadingDocuments || loadingDocuments}
          >
            {t("buttons.submit")}
          </Button>
        </>
      )}
    </AuthLayout>
  );
};

export default Kyc;
