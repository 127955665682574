import { CSSProperties, FC } from "react";
import { useTranslation } from "react-i18next";
import { setLanguageFx } from "src/store/account";
import { getLanguage } from "src/utils/helpers";
import "./index.scss";

interface IProps {
  style?: CSSProperties;
}

const SwitchLanguage: FC<IProps> = ({ style }) => {
  const { t } = useTranslation();
  const lang = getLanguage();
  const changedLang = lang === "en" ? "cn" : "en";

  return (
    <div
      className="switch-language"
      style={style}
      onClick={() => setLanguageFx(changedLang)}
    >
      {t("languageName")}
    </div>
  );
};

export default SwitchLanguage;
