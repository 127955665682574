import { FC } from "react";
import cs from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { useCurrentUser } from "src/graphQl/profile";
import { KycStatus } from "src/graphQl/kyc";
import { USER_STAKES_COUNT } from "src/graphQl/userStakes";

const Ambassador: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { pathname } = useLocation();
  const { data: userStakes } = useQuery(USER_STAKES_COUNT, {
    variables: {
      where: {
        currency: {
          name: { in: ["Twee", "TweeB"] },
        },
      },
    },
  });

  const items = [
    {
      text: t("ambassador.point1"),
      success: !!user?.tweeBaaAppUser,
      action: () => navigate("/"),
    },
    {
      text: t("ambassador.point2"),
      success: user?.kycStatus === KycStatus.APPROVED,
      action: () => navigate("/kyc"),
    },
    {
      text: t("ambassador.point3"),
      success: userStakes?.userStakes?.totalCount > 0,
      action: () => navigate("/staking"),
    },
    {
      text: (
        <>
          {t("ambassador.point4")}&nbsp;
          <span className="ambassador__item-text_grey">
            {user?.tweeBaaAppUser?.invitationsCount || 0}/5{" "}
            {t("ambassador.done")}
          </span>
        </>
      ),
      success: user?.tweeBaaAppUser?.invitationsCount
        ? user?.tweeBaaAppUser?.invitationsCount >= 5
        : false,
      action: () =>
        navigate(`/something-required#invite`, {
          state: { prevPath: pathname },
        }),
    },
    {
      text: (
        <>
          {t("ambassador.point5")}&nbsp;
          <span className="ambassador__item-text_grey">
            {user?.invitationsAfterAmbassador || 0}/5 {t("ambassador.done")}
          </span>
        </>
      ),
      success:
        user?.invitationsAfterAmbassador &&
        user?.invitationsAfterAmbassador >= 5,
      action: () =>
        navigate(`/something-required#invite`, {
          state: { prevPath: pathname },
        }),
    },
  ];

  return (
    <div className="ambassador">
      <div className="ambassador__title">{t("ambassador.title")}</div>
      {items.map((i, ind) => (
        <div key={ind} className="ambassador__item">
          <div className="ambassador__item-text">
            {++ind}. {i.text}
          </div>
          <div
            className={cs("ambassador__item-action", {
              "ambassador__item-action_disabled": !i.success,
            })}
            onClick={i.action}
          >
            {i.success ? t("ambassador.done") : t("ambassador.toDo")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ambassador;
