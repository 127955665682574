import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStore } from "effector-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import {
  $withdrawSuccessModal,
  setWithdrawSuccessModal,
} from "src/store/wallet";

const WithdrawSuccess = () => {
  const { t } = useTranslation();
  const isOpen = useStore($withdrawSuccessModal);
  const navigate = useNavigate();

  const onClose = () => {
    setWithdrawSuccessModal(false);
    navigate("/wallet/withdraw");
  };

  return (
    <Dialog open={isOpen} className="withdraw-success">
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="withdraw-success__content">
        <div className="withdraw-success__title">
          {t("withdrawSuccessDialog.title")}
        </div>
        <div className="withdraw-success__text">
          {t("withdrawSuccessDialog.awaitDescription")}
        </div>
        <div className="withdraw-success__close" onClick={onClose}>
          {t("buttons.close")}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WithdrawSuccess;
