import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { FC, useLayoutEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PasswordTextField from "../../common/PasswordTextField";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { validatePassword } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

type FormValues = {
  currentPassword: string;
  newPassword: string;
};
type FormErrors = Partial<FormValues>;

type Props = {
  open: boolean;
  onClose: () => void;
  onOk: (values: FormValues) => void;
  onForgotClick: () => void;
  loading?: boolean;
  title: string;
  errors?: FormErrors;
};

const ChangePasswordDialogBase: FC<Props> = ({
  open,
  onClose,
  onOk,
  onForgotClick,
  loading,
  title,
  errors,
}) => {
  const { t } = useTranslation();
  const validation = (values: FormErrors) => {
    const validationErrors: FormErrors = {};

    if (!values.currentPassword) {
      validationErrors.currentPassword = t("changePasswordForm.requiredError");
    }
    if (values.newPassword && values.newPassword === values.currentPassword) {
      validationErrors.newPassword = t("changePasswordForm.samePasswordError");
    }

    const newPasswordErrors = validatePassword(values.newPassword);
    if (newPasswordErrors) {
      validationErrors.newPassword = newPasswordErrors;
    }

    return validationErrors;
  };
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validate: validation,
    onSubmit: onOk,
  });

  const { resetForm } = formik;

  useLayoutEffect(() => {
    if (open) {
      resetForm();
    }
  }, [resetForm, open]);

  const onCloseHandler = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={onCloseHandler}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PasswordTextField
            autoFocus
            margin="dense"
            id="currentPassword"
            label={t("placeholders.currentPassword")}
            fullWidth
            variant="filled"
            name="currentPassword"
            autoComplete="current-password"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={
              (formik.touched.currentPassword &&
                Boolean(formik.errors.currentPassword)) ||
              Boolean(errors?.currentPassword)
            }
            helperText={
              (formik.touched.currentPassword &&
                formik.errors.currentPassword) ||
              errors?.currentPassword
            }
          />

          <Button
            variant="text"
            color="secondary"
            sx={{ mt: "5px" }}
            onClick={onForgotClick}
          >
            {t("changePasswordForm.forgotPasswordQuestion")}
          </Button>
          <PasswordTextField
            margin="dense"
            id="newPassword"
            name="newPassword"
            label={t("placeholders.newPassword")}
            fullWidth
            variant="filled"
            autoComplete="off"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              (formik.touched.newPassword &&
                Boolean(formik.errors.newPassword)) ||
              Boolean(errors?.newPassword)
            }
            helperText={
              (formik.touched.newPassword && formik.errors.newPassword) ||
              errors?.newPassword
            }
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={loading}
            type="submit"
          >
            {t("buttons.setUpNewPassword")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialogBase;
