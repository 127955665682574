import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { FC } from "react";

import "./index.scss";
import Logo from "src/assets/register/SetupPassword.png";
import Input from "src/components/common/Input";
import Button from "src/components/common/Button";
import {
  $password,
  $passwordError,
  ConfirmTxPasswordGate,
  setPassword,
} from "src/store/account";
// import { setShowConfirmTx } from "src/store/wallet";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

interface IProps {
  onClick: (password: string) => void;
  loading: boolean;
}

const ConfirmTxPassword: FC<IProps> = ({ onClick, loading }) => {
  useGate(ConfirmTxPasswordGate);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const password = useStore($password);
  const passwordError = useStore($passwordError);

  return (
    <div
      className="confirm-tx-password"
      title={t("pageTitle.confirmTransactionPassword")}
    >
      <img src={Logo} alt="" className="confirm-tx-password__logo" />
      <Input
        placeholder={t("placeholders.enterTransactionPassword")}
        isPassword
        error={passwordError}
        value={password}
        onChange={value => setPassword(value)}
        className="confirm-tx-password__input"
        autoFocus
      />
      <Box sx={{ width: "298px" }}>
        <div
          className="confirm-tx-password__forgot"
          onClick={() => navigate("/reset-transaction-password")}
        >
          {t("changePasswordForm.forgotPasswordQuestion")}
        </div>
      </Box>
      <Button
        onClick={() => onClick(password)}
        className="confirm-tx-password__btn"
        loading={loading}
        disabled={loading || !password}
      >
        {t("buttons.confirm")}
      </Button>
      {/* <div style={{ margin: "10px 0" }}>or</div>
      <div
        className="confirm-tx-password__back"
        onClick={() => setShowConfirmTx(false)}
      >
        Go Back
      </div> */}
    </div>
  );
};

export default ConfirmTxPassword;
