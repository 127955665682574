import { useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Space from "src/components/common/Space";
import { INVITEES_STAKES_TRANSACTIONS_FULL } from "src/graphQl/inviteesStakesTransactions";
import { ITransaction, TransactionType } from "src/graphQl/operations";
import { roundDecimal } from "src/utils/helpers";

interface IProps {
  txs: ITransaction[];
}

const Rewards: FC<IProps> = ({ txs }) => {
  const { t } = useTranslation();

  return (
    <Container sx={{ pt: "25px", pb: "30px" }}>
      <Stack>
        {txs.map((tx: ITransaction, ind: number) => {
          const { toAccount, amount, currency, createdDate, type } = tx;
          const firstName = toAccount?.user?.firstName;
          const lastName = toAccount?.user?.lastName;
          const role =
            type === TransactionType.STAKING_INVITER_INTEREST
              ? t("Inviter")
              : t("Ambassador");
          return (
            <Space
              key={tx.id}
              align="center"
              justify="space-between"
              gap={10}
              sx={{
                height: "35px",
              }}
            >
              <Typography variant="subtitle2" color="text.primary2" noWrap>
                {`${ind + 1}. ${firstName} ${lastName}`}
              </Typography>
              <Typography variant="micro" color="text.primary2" noWrap>
                {role}
              </Typography>
              <Typography variant="subtitle2" color="text.primary2" noWrap>
                {roundDecimal(amount)}&nbsp;{currency.name}
              </Typography>
              <Typography variant="micro" color="text.primary2">
                {dayjs(createdDate).format("DD.MM.YYYY")}
              </Typography>
            </Space>
          );
        })}
      </Stack>
    </Container>
  );
};

const ProtectedRewards = () => {
  const { t } = useTranslation();
  const { data: inviteesStakesTransactions, loading } = useQuery(
    INVITEES_STAKES_TRANSACTIONS_FULL
  );

  const txs = useMemo(
    () => inviteesStakesTransactions?.inviteesStakesTransactions?.nodes || [],
    [inviteesStakesTransactions?.inviteesStakesTransactions?.nodes]
  );

  if (!inviteesStakesTransactions || loading) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  if (txs.length === 0) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <Typography variant="subtitle1" color="text.primary2">
          {t("rewardsPage.emptyText")}
        </Typography>
      </Box>
    );
  }

  return <Rewards txs={txs} />;
};

export default ProtectedRewards;
