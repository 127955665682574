import { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Typography, Link, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import ambassadorIcon from "src/assets/ambassador-icon.png";
import ambassadorIcon3 from "src/assets/ambassador-icon3.png";
import Space from "../Space";
import { useAmbassadorInProcess } from "src/graphQl/profile";

const Card = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.white,
  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.12)",
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "0 24px 28px 24px",
  marginTop: "50px",
}));

const Logo = styled("img")(() => ({
  // width: "180px",
  // height: "180px",
  margin: "-80px auto 10px",
  display: "block",
}));

const Logo2 = styled("img")(() => ({
  // width: "180px",
  // height: "180px",
  margin: "-35px auto 10px",
  display: "block",
}));

const AmbassadorBanner: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ambassadorInProcess } = useAmbassadorInProcess();

  return (
    <Card>
      {!ambassadorInProcess ? (
        <Logo className={styles.logo} src={ambassadorIcon3} alt="" />
      ) : (
        <Logo2 className={styles.logo} src={ambassadorIcon} alt="" />
      )}

      {!ambassadorInProcess && (
        <Space direction="vertical" gap={25}>
          <Typography variant="h5">{t("becomeAmbassador.title")}</Typography>
          <Typography variant="body2">
            <Trans i18nKey="becomeAmbassador.description" />
          </Typography>

          <Link to="/about-ambassador" component={RouterLink}>
            {t("becomeAmbassador.link")}
          </Link>
        </Space>
      )}
      {!ambassadorInProcess ? (
        <Button
          variant="contained"
          onClick={() => navigate("/ambassador-program")}
          fullWidth
          sx={{ mt: "20px", mx: "auto" }}
        >
          {t("buttons.becameAmbassador")}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={() => navigate("/profile/ambassador")}
          fullWidth
          sx={{ mt: "20px", mx: "auto" }}
        >
          {t("buttons.checkAmbassadorStatus")}
        </Button>
      )}
    </Card>
  );
};

export default AmbassadorBanner;
