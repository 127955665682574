import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "src/components/common/Header";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import "./index.scss";
import ContentBlock from "src/components/landing/ContentBlock";
import RocketImg from "src/assets/RocketImg.png";
import MetaverseImg from "src/assets/Metaverse.png";
// import { useTweebaaAuthUrl } from "src/graphQl/tweeAppAuthUrl";
import InviteFriendsImg from "src/assets/InviteFriends.png";
import { useAmbassadorInProcess, useCurrentUser } from "src/graphQl/profile";
import CheckLogo from "src/assets/CheckLogo.png";
import TabBar from "src/components/common/TabBar";
import { useTweebaaAuthUrl } from "src/graphQl/tweeAppAuthUrl";

const InviteEarn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tweeBaaAuthUrl } = useTweebaaAuthUrl();
  const { user, loading: loadingUser } = useCurrentUser();
  const { ambassadorInProcess } = useAmbassadorInProcess();
  const showMetaverse = !user?.tweeBaaAppUser && !loadingUser;
  const { pathname } = useLocation();

  const goToAmbassadorPage = () => {
    ambassadorInProcess
      ? navigate("/profile/ambassador")
      : navigate("/ambassador-program");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <Header backPath={-1} title={t("inviteEarn.headerTitle")} />
      </LocalThemeProvider>
      <Box
        sx={{
          fontSize: "14px",
          lineHeight: 1.5,
        }}
      >
        <div className="invite-earn__register-layout">
          <ContentBlock
            image={RocketImg}
            title={t("inviteEarn.register.title")}
            // text={t("inviteEarn.register.text")}
            text={
              <Trans i18nKey="inviteEarn.register.text">
                Your <b>Limited Fiat Money</b>
                <br />
                Earn You <b>Unlimited Crypto Rewards</b>
                <br />
                While your <b>Fiat Money is 100% Secure.</b>
              </Trans>
            }
            btnOrLinkText={!user ? t("buttons.registerTweebank") : undefined}
            onClick={
              !user
                ? () =>
                    navigate(`/create-account`, {
                      state: { prevPath: pathname },
                    })
                : undefined
            }
          />
        </div>
        <ContentBlock
          image={MetaverseImg}
          title={t("inviteEarn.metaverse.title")}
          text={t("inviteEarn.metaverse.text")}
          btnOrLinkText={
            showMetaverse ? t("buttons.connectToTweeBaaApp") : undefined
          }
          onClick={
            showMetaverse
              ? () =>
                  !user
                    ? navigate(`/something-required#register`, {
                        state: { prevPath: pathname },
                      })
                    : window.open(tweeBaaAuthUrl, "_blank")
              : undefined
          }
          // loading={loading}
        />
        <ContentBlock
          image={InviteFriendsImg}
          title={t("landing.inviteFriends.title")}
          text={t("landing.inviteFriends.text")}
          btnOrLinkText={t("buttons.inviteFriends")}
          onClick={() =>
            !user
              ? navigate(`/something-required#register`, {
                  state: { prevPath: pathname },
                })
              : navigate(`/something-required#invite`, {
                  state: { prevPath: pathname },
                })
          }
          className="invite-earn__card"
        />
        <ContentBlock
          image={CheckLogo}
          title={t("landing.promo.title")}
          text={t("landing.promo.text")}
          btnOrLinkText={
            ambassadorInProcess
              ? t("buttons.checkAmbassadorStatus")
              : t("buttons.applyForAmbassador")
          }
          onClick={goToAmbassadorPage}
        />
      </Box>
      <TabBar />
    </Box>
  );
};

export default InviteEarn;
