import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import "./index.scss";
import { $operationInfoModal, setOperationInfoModal } from "src/store/wallet";
import { round } from "src/utils/helpers";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";
import { DEFAULT_ROUND } from "src/config";

const TransactionInfo = () => {
  const { t } = useTranslation();
  const baseCurrencySymbol = useBaseCurrencySymbol();
  const data = useStore($operationInfoModal);
  const txs = data?.txs;
  const isPending = data?.isPending;

  const onClose = () => {
    setOperationInfoModal(null);
  };

  return (
    <Dialog open={!!data} className="transaction-info">
      <DialogTitle
        sx={{ background: isPending ? "rgba(196,196,196, 0.4)" : "unset" }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          background: isPending ? "rgba(196,196,196, 0.4)" : "unset",
          width: 340,
        }}
        className="transaction-info__content"
      >
        <Stack>
          {txs?.map(tx => {
            const amount = tx?.amount ? round(tx.amount, DEFAULT_ROUND) : 0;
            const baseAmount = tx?.baseAmount
              ? round(tx.baseAmount, DEFAULT_ROUND)
              : 0;
            return (
              <Box key={tx.id}>
                <div className="transaction-info__title">
                  {isPending
                    ? t("transactionInfo.pendingTransactionId", { id: tx?.id })
                    : t("transactionInfo.transactionId", { id: tx?.id })}
                </div>
                <div className="transaction-info__date">
                  {dayjs(tx?.createdDate).format("MMM DD, HH:mm")}
                </div>
                <div className="transaction-info__info">
                  <div className="transaction-info__type">
                    {tx?.type?.toLowerCase()}
                  </div>
                  <div className="transaction-info__amount">
                    {amount} {tx?.currency?.name}
                  </div>
                </div>
                <div className="transaction-info__amount-usd">
                  {baseAmount} {baseCurrencySymbol}
                </div>
              </Box>
            );
          })}
        </Stack>
        <div className="transaction-info__close" onClick={onClose}>
          {t("buttons.close")}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionInfo;
