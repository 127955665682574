import { FC } from "react";
import { PAYPAL_CLIENT_ID } from "../../config";
import { CryptoCurrency } from "./types";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalButtonsComponentProps,
} from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../graphQl/profile";

type PayPalButtonsContainerProps = {
  amount: number;
  onSuccess?: (orderId: string) => void;
  disabled?: boolean;
  currencyCode: CryptoCurrency;
  onClickValidation: (
    amount: number,
    currencyCode: CryptoCurrency
  ) => Promise<boolean>;
};

const PayPalButtonsContainer: FC<PayPalButtonsContainerProps> = ({
  amount,
  onSuccess,
  disabled,
  currencyCode,
  onClickValidation,
}) => {
  const { t } = useTranslation();
  const [{ isPending }] = usePayPalScriptReducer();
  const { user, loading } = useCurrentUser();

  if (isPending || loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  const createOrder: PayPalButtonsComponentProps["createOrder"] = async (
    data,
    actions
  ) => {
    return actions.order
      .create({
        intent: "CAPTURE",
        purchase_units: [
          {
            amount: {
              value: String(amount),
            },
            custom_id: JSON.stringify({
              customer_id: user?.id,
              currencyCode: currencyCode,
            }),
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then(orderId => {
        // Your code here after create the order
        return orderId;
      });
  };

  const onApprove: PayPalButtonsComponentProps["onApprove"] = (
    data,
    actions
  ) => {
    if (!actions.order) {
      return Promise.reject();
    }
    return actions.order.capture().then(async orderData => {
      // console.log(
      //   "Capture result",
      //   orderData,
      //   JSON.stringify(orderData, null, 2)
      // );
      onSuccess?.(orderData.id);
    });
  };

  const onCancel: PayPalButtonsComponentProps["onCancel"] = () => {
    toast.warn(t("purchasePage.paymentCancelledMessage"));
  };

  const onError: PayPalButtonsComponentProps["onError"] = () => {
    toast.warn(t("purchasePage.paymentErrorPage"));
  };

  const checkSystemCanOperation: PayPalButtonsComponentProps["onClick"] =
    async (data: any, actions: any) => {
      const isValid = await onClickValidation(amount, currencyCode);
      return isValid ? actions.resolve() : actions.reject();
    };

  return (
    <PayPalButtons
      style={{ layout: "horizontal", color: "white", tagline: false }}
      forceReRender={[amount]}
      disabled={!amount || disabled}
      createOrder={createOrder}
      onApprove={onApprove}
      onCancel={onCancel}
      onError={onError}
      onClick={checkSystemCanOperation}
    />
  );
};

const PayPalButton: FC<PayPalButtonsContainerProps> = props => {
  if (!PAYPAL_CLIENT_ID) return null;
  return (
    <PayPalScriptProvider
      options={{
        "client-id": PAYPAL_CLIENT_ID,
        currency: "USD",
        components: "buttons",
      }}
    >
      <PayPalButtonsContainer {...props} />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
