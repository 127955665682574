import { FC } from "react";
import { FaTwitter } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
import cs from "classnames";

import "./index.scss";

const socials = [
  {
    link: "https://twitter.com/tweebaa",
    icon: <FaTwitter />,
  },
  {
    link: "https://facebook.com/tweebaa/",
    icon: <AiFillFacebook />,
  },
  // {
  //   link: "https://linked.in",
  //   icon: <FaLinkedin />,
  // },
];

interface IProps {
  className?: string;
}

const Socials: FC<IProps> = ({ className }) => {
  return (
    <div className={cs("landing-socials", className)}>
      {socials.map(i => (
        <a
          key={i.link}
          className="landing-socials__item"
          href={i.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i.icon}
        </a>
      ))}
    </div>
  );
};

export default Socials;
