import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeTransactionPasswordFx } from "../../../store/account";
import ChangePasswordDialogBase from "../ChangePasswordDialogBase";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ChangeTransactionPasswordDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCloseHandler = () => {
    onClose();
  };
  const onOkHandler = async (values: {
    currentPassword: string;
    newPassword: string;
  }) => {
    try {
      await changeTransactionPasswordFx(values);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  const onForgotClickHandler = () => {
    navigate("/reset-transaction-password");
  };
  return (
    <ChangePasswordDialogBase
      title={t("dialogTitle.newTransactionPassword")}
      onOk={onOkHandler}
      onClose={onCloseHandler}
      onForgotClick={onForgotClickHandler}
      open={open}
    />
  );
};

export default ChangeTransactionPasswordDialog;
