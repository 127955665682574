import { Box } from "@mui/material";
// import { HiOutlineChevronLeft } from "react-icons/hi";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "src/components/common/Header";
import TabBar from "src/components/common/TabBar";
import LandingContactUs from "src/components/landing/ContactUs";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import "./index.scss";

const ContactUsScreen = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <LocalThemeProvider theme="dark">
        <Header backPath={-1} title={t("contactUs.headerTitle")} />
      </LocalThemeProvider>
      <div className="contact-us-screen">
        {/* <div
          onClick={() => navigate(-1)}
          className="contact-us-screen__back-btn"
        >
          <HiOutlineChevronLeft />
          Back
        </div> */}
        <LandingContactUs hideTitle />
      </div>
      <TabBar />
    </Box>
  );
};

export default ContactUsScreen;
