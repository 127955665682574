import { FC } from "react";
import cs from "classnames";
import { FiRefreshCw } from "react-icons/fi";

import "./index.scss";

interface Props {
  onClick: () => void;
  loading: boolean;
  className?: string;
}

const LoadMore: FC<Props> = ({ onClick, loading, className }) => {
  return (
    <div className={cs("load-more", className)} onClick={onClick}>
      <FiRefreshCw
        className={cs("load-more__refresh-icon", {
          rotateClockwise: loading,
        })}
      />
      Load more
    </div>
  );
};

export default LoadMore;
