import { FC } from "react";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import "./index.scss";

interface IProps {
  checked: boolean;
}

const Checkbox: FC<IProps> = ({ checked }) => {
  return (
    <div className="checkbox">
      {checked ? (
        <MdCheckBox className="checkbox_checked" />
      ) : (
        <MdCheckBoxOutlineBlank className="checkbox_unchecked" />
      )}
    </div>
  );
};

export default Checkbox;
