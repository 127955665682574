import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/lab";
import { toast } from "react-toastify";

import CurrencyIcon from "src/components/common/CurrencyIcon";
import Input from "src/components/common/Input";
import { useAccounts, useCurrentUser } from "src/graphQl/profile";
import {
  calculateExpectedReturn,
  getOnlyNumbers,
  getStakingPercent,
  isTbean,
  objFromArr,
  round,
} from "src/utils/helpers";
import "./index.scss";
import Button from "src/components/common/Button";
import { IAccount } from "src/graphQl/accounts";
import { IStakeTemplate, STAKE_TEMPLATES } from "src/graphQl/stakeTemplates";
import { CONVERT } from "src/graphQl/convert";
import Loader from "src/components/common/Loader";
import { TWEE_BAA_TIV } from "src/graphQl/tweeBaaTiv";
// import ConnectTweebaaApp from "src/components/common/ConnectTweebaaApp";
import { DEFAULT_ROUND } from "src/config";
import { SETTINGS } from "src/graphQl/settings";
import { KycStatus } from "src/graphQl/kyc";
import { CURRENCIES, ICurrency } from "src/graphQl/currencies";
import { $isAuth } from "src/store/account";
import { useStore } from "effector-react";
import { useBaseCurrencySymbol } from "src/hooks/useBaseCurrencySymbol";

const possiblePeriods = ["1", "3", "6", "12"];

const isSelectedPercent = (amount: string, percent: number, total?: number) =>
  total
    ? new BigNumber(amount).multipliedBy(100).div(total).isEqualTo(percent)
    : false;

const StakingCurrency = () => {
  const { t } = useTranslation();
  const possibleStakingParts = [
    { title: "10%", value: 10 },
    { title: "25%", value: 25 },
    { title: "50%", value: 50 },
    { title: "75%", value: 75 },
    { title: t("stakingCurrencyPage.stakingParts.all"), value: 100 },
  ];
  const { currency } = useParams();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const period = searchParams.get("period") || "0";
  const interestCurrencyId = searchParams.get("interestCurrencyId");
  const interestCurrencyName = searchParams.get("interestCurrencyName");
  const invitationCode = searchParams.get("invitationCode") || "";
  const amount = searchParams.get("amount") || "";
  const isAuth = useStore($isAuth);
  const { user } = useCurrentUser();
  const { accounts } = useAccounts();

  const [balanceWarning, setBalanceWarning] = useState(false);

  const [getTemplates, { data: templates, loading: loadingTemplates }] =
    useLazyQuery(STAKE_TEMPLATES);
  const [convert, { data: price, loading: loadingPrice }] =
    useLazyQuery(CONVERT);

  const { data: settings, loading: loadingSettings } = useQuery(SETTINGS);
  // const { data: tiv, loading: loadingTiv } = useQuery(TWEE_BAA_TIV);
  const [getTiv, { data: tiv, loading: loadingTiv }] =
    useLazyQuery(TWEE_BAA_TIV);

  const [getCurrencies, { data: currencies }] = useLazyQuery(CURRENCIES);

  useEffect(() => {
    isAuth && getTiv();
  }, [isAuth, getTiv]);

  useEffect(() => {
    !isAuth &&
      getCurrencies({
        variables: {
          where: {
            name: { nin: ["USD", "CNY"] },
          },
        },
      });
  }, [isAuth, getCurrencies]);

  const accountsList = isAuth
    ? accounts
    : currencies?.currencies?.nodes?.map((currencyItem: ICurrency) => ({
        balance: 0,
        baseBalance: 0,
        currency: currencyItem,
        id: currencyItem.id,
        currencyId: currencyItem.id,
      }));

  const settingsArray = useMemo(
    () => settings?.settings?.nodes || [],
    [settings?.settings?.nodes]
  );
  const tivValue = tiv?.tweeBaaTiv || 0;
  const settingsObj = useMemo(
    () => objFromArr("type", settingsArray, "value"),
    [settingsArray]
  );

  useEffect(() => {
    currency &&
      interestCurrencyName &&
      convert({
        variables: {
          amount: 1,
          from: currency,
          to: interestCurrencyName,
        },
      });
  }, [currency, convert, interestCurrencyName]);

  useEffect(() => {
    currency &&
      getTemplates({
        variables: {
          where: {
            currency: {
              name: { eq: currency },
            },
          },
        },
      });
  }, [currency, getTemplates]);

  const accountData = accounts?.find(i => i.currency.name === currency);
  const balance = accountData?.balance
    ? round(accountData?.balance, DEFAULT_ROUND)
    : 0;

  const updateAmount = (percent: number) => {
    const newAmount = balance
      ? new BigNumber(balance).div(100).multipliedBy(percent).toString()
      : "0";
    setBalanceWarning(false);

    navigate(
      `/staking/stake/${currency}?period=${period}&interestCurrencyId=${interestCurrencyId}&amount=${newAmount}&invitationCode=${invitationCode}&interestCurrencyName=${interestCurrencyName}`,
      { replace: true }
    );
  };

  const templatesList = useMemo(
    () => templates?.stakeTemplates?.nodes || [],
    [templates?.stakeTemplates?.nodes]
  );
  // console.log(templatesList);
  const sortedTemplatesList = useMemo(
    () =>
      templatesList
        ?.slice()
        .sort(
          (a: IStakeTemplate, b: IStakeTemplate) => a.termMonths - b.termMonths
        ),
    [templatesList]
  );
  const template = templatesList?.find(
    (i: IStakeTemplate) => i.termMonths.toString() === period
  );

  const loadingExpectedReturn =
    amount && (loadingPrice || loadingTemplates || !period || loadingTiv);

  const interestPercent = getStakingPercent(
    template?.baseInterest,
    settingsObj,
    tivValue
  );

  const expectedReturn = useMemo(
    () =>
      amount && period && interestPercent
        ? calculateExpectedReturn({
            amount,
            period,
            percent: interestPercent,
            price: price?.convert,
          })
        : null,
    [amount, period, price?.convert, interestPercent]
  );

  const baseCurrencySymbol = useBaseCurrencySymbol();

  const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;
  const appConnected = user?.tweeBaaAppUser;
  const showConnectTweebaaApp =
    !appConnected && (isTbean(currency) || isTbean(interestCurrencyName));

  const goToNextStep = () => {
    if (!user) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    if (kycRequired) {
      return navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
    }
    if (showConnectTweebaaApp) {
      return navigate(`/something-required#tweebaa`, {
        state: { prevPath: pathname },
      });
    }
    if (
      !accountData?.balance ||
      (accountData?.balance && accountData?.balance < +amount)
    ) {
      setBalanceWarning(true);
      return toast.warn(t("stakingCurrency.notEnoughBalance"));
    }
    if (!amount) {
      return toast.warn(t("stakingCurrency.amountIsNotSelected"));
    }
    if (!period || !possiblePeriods.includes(period)) {
      return toast.warn(t("stakingCurrency.stakePeriodNotSelected"));
    }
    if (!interestCurrencyId) {
      return toast.warn(t("stakingCurrency.interestCurrencyIsNotSelected"));
    }
    navigate(
      `/staking/details/${currency}?period=${period}&interestCurrencyId=${interestCurrencyId}&amount=${amount}&invitationCode=${invitationCode}&interestCurrencyName=${interestCurrencyName}`
    );
  };

  // if (user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED) {
  //   return <KycRequired kycStatus={user.kycStatus} />;
  // }

  // const modalName = !user
  //   ? ModalNames.Register
  //   : kycRequired
  //   ? ModalNames.Kyc
  //   : showConnectTweebaaApp
  //   ? ModalNames.ConnectTweebaa
  //   : null;

  return (
    <div className="staking-currency">
      <div className="staking-currency__title">
        <CurrencyIcon currency={currency as string} />
        {t("stakingCurrencyPage.interestingCurrencyTitle", { currency })}
      </div>
      <div className="staking-currency__amount">
        <Input
          placeholder={t("placeholders.amountWithCurrency", { currency })}
          value={amount}
          onChange={value => {
            setBalanceWarning(false);
            navigate(
              `/staking/stake/${currency}?period=${period}&interestCurrencyId=${interestCurrencyId}&amount=${value}&invitationCode=${invitationCode}&interestCurrencyName=${interestCurrencyName}`,
              { replace: true }
            );
          }}
          className="staking-currency__amount-input"
          onlyValidValue={getOnlyNumbers}
          inputMode="decimal"
        />
        <div className="staking-currency__possible-parts">
          {possibleStakingParts.map(i => (
            <div
              key={i.value}
              onClick={() => updateAmount(i.value)}
              style={{
                background: isSelectedPercent(amount, i.value, balance)
                  ? "#ebbb71"
                  : "rgba(235, 187, 113, 0.5)",
                opacity: isSelectedPercent(amount, i.value, balance) ? 1 : 0.8,
              }}
            >
              {i.title}
            </div>
          ))}
        </div>
        <div className="staking-currency__amount-available">
          {t("stakingCurrencyPage.availableForInvestment", {
            balance,
            currency,
          })}
        </div>
        {balanceWarning && (
          <Alert variant="filled" severity="error" sx={{ mt: "5px", py: 0 }}>
            Not enough balance
          </Alert>
        )}
      </div>
      <div className="staking-currency__title">
        {t("stakingCurrencyPage.choiceInvestmentPeriodTitle")}
      </div>
      <div className="staking-currency__periods">
        {loadingTemplates || loadingSettings || loadingTiv ? (
          <Loader />
        ) : (
          sortedTemplatesList?.map((i: IStakeTemplate) => {
            const percent = getStakingPercent(
              i.baseInterest,
              settingsObj,
              tivValue
            );
            return (
              <div
                key={i.id}
                className="staking-currency__period-wrapper"
                onClick={() =>
                  navigate(
                    `/staking/stake/${currency}?period=${i.termMonths}&interestCurrencyId=${interestCurrencyId}&amount=${amount}&invitationCode=${invitationCode}&interestCurrencyName=${interestCurrencyName}`,
                    { replace: true }
                  )
                }
              >
                <div
                  className="staking-currency__period"
                  style={{
                    opacity: period === i.termMonths.toString() ? 1 : 0.4,
                  }}
                >
                  <div className="staking-currency__period-value">
                    {i.termMonths}
                  </div>{" "}
                  {t(i.termMonths === 1 ? "month" : "months")}
                </div>
                <div
                  className="staking-currency__period-percent"
                  style={{
                    opacity: period === i.termMonths.toString() ? 1 : 0.4,
                  }}
                >
                  {percent} %
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="staking-currency__rates-hint">
        {t("stakingPage.rateTiVNote")} {t("stakingPage.yourTiv")}: {tivValue}{" "}
        <Link to="/about-staking">{t("stakingPage.tivMoreLink")}</Link>
      </div>
      <div className="staking-currency__title">
        {t("stakingCurrencyPage.choiceInterestCurrencyTitle")}
      </div>
      <div className="staking-currency__interest-currencies">
        {accountsList
          ?.filter((i: IAccount) => i.currency.name !== "USDT")
          ?.map((i: IAccount) => {
            const currencyRate =
              localStorage.getItem("baseCurrencyName") === "CNY"
                ? i.currency.cnyRate
                : i.currency.usdRate;
            return (
              <div key={i.id} className="staking-currency__interest-currency">
                <Button
                  className="staking-currency__interest-currency-btn"
                  onClick={() =>
                    navigate(
                      `/staking/stake/${currency}?period=${period}&interestCurrencyId=${i.currencyId}&interestCurrencyName=${i.currency.name}&amount=${amount}&invitationCode=${invitationCode}`,
                      { replace: true }
                    )
                  }
                  style={{
                    background:
                      interestCurrencyId === i.currencyId.toString()
                        ? "#ebbb71"
                        : "rgba(235, 187, 113, 0.4)",
                    // opacity: interestCurrency === i.currencyId.toString() ? 1 : 0.4,
                  }}
                >
                  <div
                    style={{
                      opacity:
                        interestCurrencyId === i.currencyId.toString()
                          ? 1
                          : 0.6,
                    }}
                    className="staking-currency__interest-currency-btn-name-container"
                  >
                    <CurrencyIcon
                      currency={i.currency.name as string}
                      style={{ width: 24, height: 24 }}
                    />{" "}
                    {i.currency.name}
                  </div>
                  <div className="staking-currency__interest-currency-price">
                    1 {i.currency.name} = {baseCurrencySymbol}
                    {round(currencyRate, DEFAULT_ROUND)}
                  </div>
                </Button>
                {interestCurrencyId === i.currencyId.toString() &&
                  (loadingExpectedReturn ? (
                    <Loader
                      className="staking-currency__expected-return-loader"
                      linear
                    />
                  ) : (
                    expectedReturn && (
                      <div className="staking-currency__interest-currency-return">
                        {t("stakingCurrencyPage.expectedReturn", {
                          value: expectedReturn,
                          currency: i.currency.name,
                        })}
                      </div>
                    )
                  ))}
              </div>
            );
          })}
      </div>
      <Input
        placeholder={t("placeholders.ambassadorReferralCodeOpt")}
        value={invitationCode}
        onChange={value =>
          navigate(
            `/staking/stake/${currency}?period=${period}&interestCurrencyId=${interestCurrencyId}&amount=${amount}&invitationCode=${value}&interestCurrencyName=${interestCurrencyName}`,
            { replace: true }
          )
        }
        className="staking-currency__referral-code"
      />
      <Button
        className="staking-currency__next"
        onClick={goToNextStep}
        // disabled={
        //   (isAuth && !accountData?.balance) ||
        //   !amount ||
        //   !period ||
        //   !possiblePeriods.includes(period) ||
        //   !interestCurrencyId
        // }
      >
        {t("buttons.next")}
      </Button>
      {/* <CommonModal modalName={modalName} /> */}
    </div>
  );
};

export default StakingCurrency;
