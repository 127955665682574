import { FC } from "react";
import Space from "src/components/common/Space";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LocalThemeProvider from "../../LocalThemeProvider";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import { round } from "src/utils/helpers";
import { useBaseCurrencySymbol } from "../../../hooks/useBaseCurrencySymbol";
import { DEFAULT_ROUND } from "src/config";

interface IProps {
  item: {
    balance: number;
    currency: {
      id: number;
      name: string;
    };
    baseBalance: number;
    subtitle?: string;
  };
  hideTicker?: boolean;
  hideBaseBalance?: boolean;
  navigatePath?: string;
}

const Container = styled(Box)(({ theme }) => ({
  height: "72px",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  gap: "10px",
  alignItems: "center",
  paddingLeft: "15px",
  paddingRight: "15px",
  boxShadow: "1px 1px 7px rgba(0, 0, 0, 0.12)",
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  background: theme.palette.background.white,
}));

// const IconContainer = styled(Box)(() => ({
//   width: "40px",
//   height: "40px",
//   "&>*": { width: "100%", height: "100%", objectFit: "contain" },
// }));

const CurrencyTab: FC<IProps> = ({
  item,
  hideTicker,
  navigatePath,
  hideBaseBalance,
}) => {
  const baseCurrencySymbol = useBaseCurrencySymbol();
  // const { icon, title, balance, balanceUsd, subtitle, currencyCode } = item;
  const {
    balance,
    currency: { name, id: currencyId },
    baseBalance: balanceUsd,
    subtitle,
  } = item;

  const navigate = useNavigate();

  return (
    <LocalThemeProvider theme="light" background="transparent">
      <Container
        onClick={() =>
          navigate(
            navigatePath ||
              `/wallet/currency-details/${name}?currencyId=${currencyId}#all`
          )
        }
      >
        <CurrencyIcon currency={name} />
        {/* <IconContainer>{icon}</IconContainer> */}
        <Space sx={{ overflow: "hidden" }} gap="3" direction="vertical">
          <Typography variant="h6" noWrap color="text.primary2">
            {name}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Space>
        <Space gap="3" direction="vertical" align="end">
          <Typography variant="h6" color="text.primary2">
            {round(balance, DEFAULT_ROUND)}
            {!hideTicker && (
              <>
                &nbsp;
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="span"
                >
                  {name}
                </Typography>
              </>
            )}
          </Typography>
          {!hideBaseBalance && (
            <Typography variant="body2" color="text.secondary">
              {`${baseCurrencySymbol} ${round(balanceUsd, DEFAULT_ROUND)}`}
            </Typography>
          )}
        </Space>
      </Container>
    </LocalThemeProvider>
  );
};

export default CurrencyTab;
