import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./index.scss";

interface IProps {
  toRegister?: boolean;
}

const LoginLink: FC<IProps> = ({ toRegister }) => {
  const { t } = useTranslation();
  if (toRegister) {
    return (
      <div className="login-register-link">
        {`${t("loginToRegister.description")} `}
        <Link to="/create-account" className="login-register-link__link">
          {t("loginToRegister.link")}
        </Link>
      </div>
    );
  }

  return (
    <div className="login-register-link">
      {`${t("registerToLogin.description")} `}
      <Link to="/login" className="login-register-link__link">
        {t("registerToLogin.link")}
      </Link>
    </div>
  );
};

export default LoginLink;
