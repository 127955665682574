import { IconButton } from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";

type DialogCloseButtonProps = {
  onClick: () => void;
};

const DialogCloseButton: FC<DialogCloseButtonProps> = ({ onClick }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default DialogCloseButton;
