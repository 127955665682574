import { FC, useEffect, useMemo } from "react";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import groupBy from "lodash.groupby";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import Space from "src/components/common/Space";
import CurrencyTab from "src/components/common/CurrencyTab";
import ContactUsLink from "src/components/wallet/ContactUsLink";
import AmbassadorBanner from "../../components/common/AmbassadorBanner";
import LocalThemeProvider from "../../components/LocalThemeProvider";
import TabBar from "../../components/common/TabBar";
import { useCurrentUser, IUser, useAccounts } from "../../graphQl/profile";
import { AccountType, IAccount } from "src/graphQl/accounts";
import { useBaseCurrencySymbol } from "../../hooks/useBaseCurrencySymbol";
import {
  StakingState,
  UserStakesQueryResponse,
  USER_STAKES,
} from "../../graphQl/userStakes";
import { round } from "../../utils/helpers";
// import ConnectTweeBaaAppButton from "../../components/common/ConnectTweeBaaAppButton";
import noStakesLogo from "src/assets/noStakesLogo.png";
import helpIcon from "src/assets/helpIcon.png";
import dashboardLogo from "src/assets/dashboardLogo.png";
import { DEFAULT_ROUND } from "src/config";
import TotalEarnings from "src/components/common/TotalEarnings";
import { CURRENCIES, ICurrency } from "src/graphQl/currencies";
import { useStore } from "effector-react";
import { $isAuth } from "src/store/account";

const LogoContainer = styled(Box)({
  width: "290px",
  height: "220px",
  marginLeft: "auto",
  marginRight: "auto",
});

const NoStakesLogo = styled("img")(() => ({
  margin: "20px auto",
  display: "block",
}));

const AssetsSummary = () => {
  const { t } = useTranslation();
  const baseCurrencySymbol = useBaseCurrencySymbol();
  const { accounts, loading } = useAccounts();
  const isAuth = useStore($isAuth);

  const [getCurrencies, { data: currencies }] = useLazyQuery(CURRENCIES);

  useEffect(() => {
    !isAuth &&
      getCurrencies({
        variables: {
          where: {
            name: { nin: ["USD", "CNY"] },
          },
        },
      });
  }, [isAuth, getCurrencies]);

  const userAccounts = useMemo(
    () => accounts?.filter(account => account.type === AccountType.USER) || [],
    [accounts]
  );

  const assetsTotalUsd = useMemo(
    () => userAccounts.reduce((s, v) => s + v.baseBalance, 0),
    [userAccounts]
  );

  if (loading) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  const accountsList = isAuth
    ? userAccounts
    : currencies?.currencies?.nodes?.map((currency: ICurrency) => ({
        balance: 0,
        baseBalance: 0,
        currency,
        id: currency.id,
      }));

  return (
    <Box>
      <Space justify="center" align="center" gap={30} sx={{ mt: "15px" }}>
        <Typography variant="subtitle1">
          {t("dashboard.totalAssetsTitle")}
        </Typography>
        <Space gap={3} align="end">
          <Typography variant="h6" color="text.secondary" mb="3px">
            {baseCurrencySymbol}
          </Typography>
          <Typography variant="h5">
            <Typography
              sx={{ fontSize: "inherit", color: "primary.main" }}
              component="span"
            >
              {assetsTotalUsd.toFixed(2)}
            </Typography>
          </Typography>
        </Space>
      </Space>
      <Container sx={{ mt: "20px" }}>
        <Space direction="vertical" gap={16}>
          {accountsList?.map((asset: IAccount) => (
            <CurrencyTab key={asset.id} item={asset} />
          ))}
        </Space>
        <Box sx={{ mt: "20px" }}>
          <TotalEarnings />
        </Box>
      </Container>
    </Box>
  );
};

const StakingSummary = () => {
  const { t } = useTranslation();
  const baseCurrencySymbol = useBaseCurrencySymbol();
  const { data, loading: stakesLoading } =
    useQuery<UserStakesQueryResponse>(USER_STAKES);

  const stakes = useMemo(
    () =>
      data?.userStakes?.nodes?.filter(
        stake => stake.state === StakingState.ACTIVE
      ) || [],
    [data]
  );

  const groupedStakes = useMemo(() => groupBy(stakes, "currencyId"), [stakes]);

  const totalBaseAmount = useMemo(
    () => stakes.reduce((s, v) => s + v.baseAmount, 0),
    [stakes]
  );

  const stakesTabs = useMemo(
    () =>
      Object.entries(groupedStakes).map(([, items]) => {
        const depositsCount = items.length;
        const totalBalance = items.reduce((s, v) => s + v.amount, 0);
        const weightAvgAPR =
          items.reduce((s, v) => s + v.interest * v.amount, 0) / totalBalance;
        const totalBaseBalance = items.reduce((s, v) => s + v.baseAmount, 0);
        return {
          id: items[0].currencyId,
          balance: totalBalance,
          currency: {
            id: items[0].currencyId,
            name: items[0].currency.name,
          },
          baseBalance: totalBaseBalance,
          subtitle: `${
            depositsCount > 1 ? `${depositsCount} deposits: ` : ""
          }+${round(weightAvgAPR, DEFAULT_ROUND)}% APR`,
        };
      }),
    [groupedStakes]
  );

  if (stakesLoading) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  if (stakes.length === 0) {
    return (
      <Box py="30px" px="20px" bgcolor="background.white">
        <Typography variant="h5" mb="15px">
          {t("dashboard.stakingSummary.title")}
        </Typography>
        <NoStakesLogo src={noStakesLogo} alt="" />

        <Typography variant="subtitle1">
          {t("dashboard.stakingSummary.text1")}
        </Typography>
        <Box py="30px" sx={{ display: "flex" }} bgcolor="background.white">
          <img src={helpIcon} alt="" />
          <Box sx={{ display: "flex", flexFlow: "column", gap: "10px" }}>
            <Typography variant="subtitle1" mt="10px">
              {t("dashboard.stakingSummary.text2")}
            </Typography>
            <Link to="/staking" component={RouterLink}>
              {t("dashboard.stakingSummary.link1")}
            </Link>
            <Link to="/about-staking" component={RouterLink}>
              {t("dashboard.stakingSummary.link2")}
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box pt="30px" pb="30px" bgcolor="background.white">
      <Typography variant="h5" textAlign="center" mb="15px">
        {t("dashboard.investmentSummaryTitle")}
      </Typography>
      <Space justify="center" gap={30} align="center">
        <Typography variant="subtitle1">
          {t("dashboard.totalInvestmentTitle")}
        </Typography>
        <Space gap={3} align="end">
          <Typography variant="h6" color="text.secondary" mb="3px">
            {baseCurrencySymbol}
          </Typography>
          <Typography variant="h5">
            <Typography
              sx={{ fontSize: "inherit", color: "primary.main" }}
              component="span"
            >
              {round(totalBaseAmount, 0.001)}
            </Typography>
          </Typography>
        </Space>
      </Space>
      <Container sx={{ marginTop: "20px" }}>
        <Space direction="vertical" gap={16}>
          {stakesTabs.map(stakeTab => (
            <CurrencyTab
              key={stakeTab.id}
              item={stakeTab}
              navigatePath="/staking/summary"
            />
          ))}
        </Space>
      </Container>
    </Box>
  );
};

const UserDashboard: FC<{ user?: IUser }> = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <LocalThemeProvider theme="dark">
        <Box sx={{ paddingTop: "30px", paddingBottom: "20px" }}>
          <LogoContainer>
            <img src={dashboardLogo} alt="" />
            {/* <Logo /> */}
          </LogoContainer>
          <AssetsSummary />
        </Box>
      </LocalThemeProvider>
      <StakingSummary />

      <Container sx={{ mt: "100px" }}>
        <AmbassadorBanner />
      </Container>
      <Box
        sx={{
          mt: "32px",
          mb: "34px",
          display: "grid",
          gap: "18px",
          gridTemplateColumns: "250px",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          onClick={() =>
            navigate(`/something-required#invite`, {
              state: { prevPath: pathname },
            })
          }
        >
          {t("buttons.inviteFriends")}
        </Button>
        {/* {!user?.tweeBaaAppUser && <ConnectTweeBaaAppButton />} */}
      </Box>

      <ContactUsLink />
      <TabBar />
    </>
  );
};

const ConnectedUserDashboard = () => {
  const { user, loading: userLoading } = useCurrentUser();

  if (userLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          pt: "30px",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return <UserDashboard user={user} />;
};

export default ConnectedUserDashboard;
