import { useStore } from "effector-react";
import { BiUserCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "src/components/common/Button";
import { $isAuth } from "src/store/account";
import Socials from "../Socials";
import "./index.scss";
import SwitchLanguage from "src/components/common/SwitchLanguage";

const LandingFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = useStore($isAuth);

  return (
    <div className="landing-footer">
      {/* <div className="landing-footer__links">
        {links.map(i => (
          <a key={i.title} href={i.href} className="landing-footer__link">
            {i.title}
          </a>
        ))}
      </div> */}
      {isAuth && (
        <Link to="/profile" className="landing-footer__link">
          <BiUserCircle className="landing-footer__user-icon" />
          {t("footer.myProfile")}
        </Link>
      )}
      <SwitchLanguage
        style={{
          color: "#ebbb71",
          top: isAuth ? "30px" : "15px",
          right: isAuth ? "25px" : "15px",
        }}
      />
      <div className="landing-footer__block">
        <div className="landing-footer__block-title">
          {t("footer.termsPolicies")}
        </div>
        <div className="landing-footer__block-links">
          <a
            href="/agreement"
            target="_blank"
            className="landing-footer__block-link"
          >
            {t("footer.userAgreement")}
          </a>
          <a
            href="/privacy"
            target="_blank"
            className="landing-footer__block-link"
          >
            {t("footer.privacyPolicy")}
          </a>
        </div>
      </div>
      <div className="landing-footer__block">
        <div className="landing-footer__block-title">{t("footer.content")}</div>
        <div className="landing-footer__block-links">
          <a href="#!" className="landing-footer__block-link">
            {t("footer.invitationSystem")}
          </a>
          <a href="#!" className="landing-footer__block-link">
            {t("footer.promotionAmbassador")}
          </a>
        </div>
      </div>
      {!isAuth && (
        <Button
          onClick={() => navigate(`/create-account`)}
          className="landing__btn landing-footer__btn"
        >
          {t("buttons.signUpNow")}
        </Button>
      )}
      <Socials />
    </div>
  );
};

export default LandingFooter;
