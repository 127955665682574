import { useMemo } from "react";
import {
  useLocation,
  resolvePath,
  matchPath,
  useResolvedPath,
} from "react-router-dom";

export const useActiveTabIndex = (tabs: { href: string }[]) => {
  const location = useLocation();
  const parentPathname = useResolvedPath(".").pathname;
  const index = useMemo(() => {
    return tabs.findIndex(tab => {
      const resolvedPath = resolvePath(tab.href, parentPathname);
      const match = matchPath(resolvedPath.pathname, location.pathname);
      return !!match;
    });
  }, [location.pathname, tabs, parentPathname]);

  return index;
};
