import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";

import "./index.scss";

import Button from "src/components/common/Button";
import Space from "src/components/common/Space";
import CurrencyTab from "src/components/common/CurrencyTab";
import { useAccounts } from "src/graphQl/profile";
import { IAccount } from "src/graphQl/accounts";
import { IOperation, OPERATIONS } from "src/graphQl/operations";
import TransactionInfo from "src/components/wallet/TransactionInfo";
import Operation from "src/components/wallet/Operation";
import { CURRENCIES, ICurrency } from "src/graphQl/currencies";
import { $isAuth } from "src/store/account";

const Wallet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = useStore($isAuth);
  const { accounts, userId } = useAccounts();

  const [getLastOperations, { data }] = useLazyQuery(OPERATIONS);

  const [getCurrencies, { data: currencies }] = useLazyQuery(CURRENCIES);

  useEffect(() => {
    !isAuth &&
      getCurrencies({
        variables: {
          where: {
            name: { nin: ["USD", "CNY"] },
          },
        },
      });
  }, [isAuth, getCurrencies]);

  useEffect(() => {
    userId &&
      getLastOperations({
        variables: {
          where: {
            userAccount: {
              userId: {
                eq: userId,
              },
            },
          },
          order: {
            createdDate: "DESC",
          },
          first: 5,
        },
      });
  }, [getLastOperations, userId]);

  // const txs = data?.operations?.nodes
  //   ?.map((a: IOperation) => a.transactions?.map((tx: ITransaction) => tx))
  //   .flat();

  const operations = useMemo(
    () => data?.operations?.nodes || [],
    [data?.operations?.nodes]
  );

  const accountsList = isAuth
    ? accounts
    : currencies?.currencies?.nodes?.map((currency: ICurrency) => ({
        balance: 0,
        baseBalance: 0,
        currency,
        id: currency.id,
      }));

  return (
    <div className="wallet">
      <Space className="wallet__currencies-balances" gap={16}>
        {accountsList?.map((account: IAccount) => (
          <CurrencyTab
            key={account.id}
            item={account}
            hideTicker
            hideBaseBalance={!isAuth}
          />
        ))}
      </Space>
      <Button
        className="wallet__buy-btn"
        onClick={() => navigate("/wallet/purchase")}
      >
        {t("walletPage.buyUsdtAndTBean")}
      </Button>
      {operations?.length > 0 && (
        <>
          <div className="wallet__btw-container">
            <div className="wallet__data-name">
              {t("walletPage.recentTransactions")}
            </div>
            <div className="wallet__column-name">{t("walletPage.amount")}</div>
          </div>
          <div>
            {operations?.map((i: IOperation) => (
              <Operation key={i.id} item={i} />
            ))}
          </div>
          <Link className="wallet__txs-link" to="/wallet/transactions">
            {t("buttons.allTransactions")}
          </Link>
        </>
      )}
      <TransactionInfo />
    </div>
  );
};

export default Wallet;
