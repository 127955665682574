import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import "./index.scss";
import Button from "src/components/common/Button";
import Header from "src/components/landing/Header";
import Footer from "src/components/landing/Footer";
import Contacts from "src/components/landing/Contacts";
import ContentBlock from "src/components/landing/ContentBlock";
import AboutUs from "src/components/landing/AboutUs";
import ContactUs from "src/components/landing/ContactUs";

import CheckLogo from "src/assets/CheckLogo.png";
import StakingImg from "src/assets/landing/Staking.png";
import AppStore from "src/assets/landing/AppStore.png";
// import PlayMarket from "src/assets/landing/PlayMarket.png";
import Video from "src/assets/landing/Video.png";
import AppIntegrationImg from "src/assets/landing/AppIntegration.png";
import RocketImg from "src/assets/RocketImg.png";
import { TWEBAA_APP_STORE_LINK } from "src/config";
import TabBar from "src/components/common/TabBar";
import { Box } from "@mui/material";

const Landing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <div className="landing">
        <Header />
        <Button
          onClick={() => navigate(`/create-account`)}
          className="landing__btn landing__btn_sign-up-top"
        >
          {t("buttons.signUpNow")}
        </Button>
        <div className="landing__login">
          <Trans i18nKey="landing.orLogin">
            or <Link to="/login">Login</Link> if you already have TweeBank
            account
          </Trans>
        </div>
        <div className="landing__card">
          <div className="landing__card-title">
            {t("landing.keyFeatures.title")}
          </div>
          <div className="landing__text">{t("landing.keyFeatures.text")}</div>
        </div>
        <ContentBlock
          image={StakingImg}
          title={
            <span style={{ textTransform: "uppercase" }}>{t("staking")}</span>
          }
          text={t("landing.staking.text")}
          linkPath="/about-staking"
          btnOrLinkText={t("landing.readMore")}
          link2={{
            label: t("landing.tryItNow"),
            path: "/staking",
          }}
        />
        <ContentBlock
          image={CheckLogo}
          title={t("landing.promo.title")}
          text={t("landing.promo.text")}
          linkPath="/about-ambassador"
          btnOrLinkText={t("landing.readMore")}
          link2={{
            label: t("landing.tryItNow"),
            // path: "/about-ambassador",
            path: "/ambassador-program",
          }}
        />
        <ContentBlock
          image={AppIntegrationImg}
          title={t("landing.appIntegration.title")}
          text={t("landing.appIntegration.text")}
        />
        <div className="landing__markets-container">
          <a href={TWEBAA_APP_STORE_LINK} target="_blank" rel="noreferrer">
            <img src={AppStore} alt="" />
          </a>
          <a href={TWEBAA_APP_STORE_LINK} target="_blank" rel="noreferrer">
            <img src={AppStore} alt="" />
          </a>
        </div>
        <div className="landing__video-container">
          <img src={Video} alt="" />
        </div>
        <ContentBlock
          title={t("landing.banking.title")}
          text={t("landing.banking.text")}
        />
        <Button
          onClick={() => navigate(`/create-account`)}
          className="landing__btn"
        >
          {t("buttons.signUpNow")}
        </Button>
        <div className="landing__login">
          <Trans i18nKey="landing.orLogin">
            or <Link to="/login">Login</Link> if you already have TweeBank
            account
          </Trans>
        </div>
        <AboutUs />
        <div className="landing__invite-earn-layout">
          <ContentBlock
            image={RocketImg}
            title={t("landing.inviteEarn.title")}
            text={t("landing.inviteEarn.text")}
            link2={{
              label: t("landing.readMore"),
              path: "/invite-earn",
            }}
          />
        </div>
        <ContactUs />
        <Contacts />
        <Footer />
      </div>
      <TabBar />
    </Box>
  );
};

export default Landing;
