import { useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  createStripePaymentSessionQuery,
  CreateStripePaymentSessionQueryProps,
  CreateStripePaymentSessionQueryResponse,
} from "../../graphQl/stripe";
import { CryptoCurrency } from "./types";

type StripeButtonProps = {
  amount: number;
  disabled?: boolean;
  currencyCode: CryptoCurrency;
  setDisabled: (disabled: boolean) => void;
  onClickValidation: (
    amount: number,
    currencyCode: CryptoCurrency
  ) => Promise<boolean>;
};
const StripeButton: FC<StripeButtonProps> = ({
  amount,
  disabled,
  currencyCode,
  setDisabled,
  onClickValidation,
}) => {
  const { t } = useTranslation();
  const [createStripePaymentSession] = useLazyQuery<
    CreateStripePaymentSessionQueryResponse,
    CreateStripePaymentSessionQueryProps
  >(createStripePaymentSessionQuery);
  const onStripePayment = async () => {
    const isValid = await onClickValidation(amount, currencyCode);
    if (!isValid) return;

    setDisabled(true);

    const { data, error } = await createStripePaymentSession({
      variables: {
        request: {
          amount,
          currency: currencyCode,
          successUrl: `${window.location.origin}/stripe-success?session_id={CHECKOUT_SESSION_ID}`,
          failUrl: `${window.location.origin}/stripe-fail`,
        },
      },
      fetchPolicy: "network-only",
    });

    if (error || !data?.stripeCheckoutSessionUrl) {
      toast.warn(t("purchasePage.paymentErrorPage"));
    }

    window.open(data?.stripeCheckoutSessionUrl, "_blank");

    setDisabled(false);
  };

  return (
    <Button
      onClick={onStripePayment}
      disabled={disabled}
      variant="contained"
      color="primary"
      sx={{ height: "46px" }}
    >
      {t("buttons.buyWithStripe")}
    </Button>
  );
};

export default StripeButton;
