import { FC, useEffect, useRef, useState } from "react";
import { useStore, useGate } from "effector-react";
import ReactCodeInput from "react-code-input";
import { To, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { useDidMount } from "rooks";

import "./index.scss";
import {
  $code,
  $codeError,
  $phone,
  checkConfirmationCode,
  getConfirmationCode,
  ScreenGate,
  setCode,
} from "src/store/account";
import Button from "src/components/common/Button";
import { PhoneVerificationRequestType } from "src/services/User";
import { useTranslation } from "react-i18next";

// const codeInputStyles: CSSProperties = {
//   fontFamily: "monospace",
//   MozAppearance: "textfield",
//   borderRadius: "4px",
//   border: "1px solid",
//   borderColor: "rgba(255, 255, 255, 0.6)",
//   margin: "5px",
//   width: "39px",
//   height: "54px",
//   fontSize: "32px",
//   boxSizing: "border-box",
//   color: "#fff",
//   backgroundColor: "rgba(255, 255, 255, 0.2)",
//   textAlign: "center",
// };

type Props = {
  successRoute: string | To;
  phoneVerificationRequestType: PhoneVerificationRequestType;
  preSuccess?: () => void;
  dark?: boolean;
};

const ConfirmCodeForm: FC<Props> = ({
  successRoute,
  phoneVerificationRequestType,
  preSuccess,
  dark,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  useGate(ScreenGate, {
    onSuccess: async () => {
      preSuccess && (await preSuccess());
      navigate(successRoute, { replace: true });
    },
  });
  const phone = useStore($phone);
  const code = useStore($code);
  const error = useStore($codeError);
  const checkingCode = useStore(checkConfirmationCode.pending);
  const resendingCode = useStore(getConfirmationCode.pending);
  const [codeCountdownDate, setCodeCountdownDate] = useState(
    Date.now() + 1000 * 45
  );

  const setCountdownDate = (value?: number) => {
    const countdownDate = value || Date.now() + 1000 * 45;
    localStorage.setItem("codeCountdownDate", countdownDate.toString());
    setCodeCountdownDate(countdownDate);
  };

  useDidMount(() => {
    const countdownDate = localStorage.getItem("codeCountdownDate");
    if (!countdownDate || +countdownDate < Date.now()) {
      setCountdownDate();
    } else {
      setCountdownDate(+countdownDate);
    }
  });

  const codeRef: any = useRef(null);

  useEffect(() => {
    if (code === "") {
      clear();
    }
  }, [code]);

  const clear = () => {
    if (codeRef?.current?.textInput[0]) {
      codeRef.current.textInput?.[0]?.focus();
      for (let i = 0; i < codeRef.current.state.input.length; i++) {
        codeRef.current.state.input[i] = "";
      }
    }
  };

  // const goToChangePhone = () => {
  //   if (pathname === "/confirm-code-reset-password") {
  //     return navigate("/login");
  //   }
  //   return navigate(-1);
  // };

  return (
    <>
      <div className="confirm-code__input-wrapper">
        <ReactCodeInput
          value={code}
          onChange={setCode}
          type="number"
          fields={6}
          name="confirmCode"
          inputMode="numeric"
          isValid
          inputStyle={{
            fontFamily: "monospace",
            MozAppearance: "textfield",
            borderRadius: "4px",
            border: "1px solid",
            borderColor: "rgba(255, 255, 255, 0.6)",
            margin: "5px",
            width: "39px",
            height: "54px",
            fontSize: "32px",
            boxSizing: "border-box",
            color: dark ? "#282A2B" : "#fff",
            backgroundColor: dark
              ? "rgba(40, 42, 43, 0.12)"
              : "rgba(255, 255, 255, 0.2)",
            textAlign: "center",
          }}
          className="confirm-code__input"
          disabled={checkingCode}
          ref={codeRef}
        />
        {error && <div className="input-error">{error}</div>}
      </div>
      <div className="confirm-code__text">
        {`${t("confirmCode.phoneMessage")} `}
        <span className="confirm-code__phone">{phone}</span>
      </div>
      <Countdown
        date={codeCountdownDate}
        zeroPadTime={2}
        renderer={({ seconds, completed, api }) =>
          completed ? (
            <Button
              onClick={async () => {
                await getConfirmationCode({
                  phone,
                  doNotCallSuccess: true,
                  type: phoneVerificationRequestType,
                });
                setCountdownDate();
                api.start();
              }}
              className="confirm-code__resend-btn"
              loading={resendingCode}
              disabled={resendingCode}
            >
              {t("buttons.resend")}
            </Button>
          ) : (
            <div className="confirm-code__resend-text">
              {t("confirmCode.resendIn", { seconds })}
            </div>
          )
        }
      />
      {/* <div className="confirm-code__change-number">
        {t("or")}{" "}
        <span
          className="confirm-code__change-number-link"
          onClick={goToChangePhone}
        >
          {t("confirmCode.changePhone")}
        </span>
      </div> */}
    </>
  );
};

export default ConfirmCodeForm;
