import { Tab, Tabs } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useActiveTabIndex } from "../../../hooks/useActiveTabIndex";

interface LinkTabProps {
  label: string;
  href: string;
  disabled?: boolean;
}

const LinkTab: FC<LinkTabProps> = ({ href, children, ...props }) => {
  const navigate = useNavigate();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(href);
      }}
      {...props}
    />
  );
};

type Props = {
  tabs: LinkTabProps[];
  onChange?: (value: number) => void;
};

const NavTabs: FC<Props> = ({ tabs }) => {
  const activeIndex = useActiveTabIndex(tabs);
  return (
    <Tabs
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      value={activeIndex || 0}
    >
      {tabs.map(tab => (
        <LinkTab key={tab.label} {...tab} />
      ))}
    </Tabs>
  );
};

export default NavTabs;
