import { useGate, useStore } from "effector-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";

import RoutesComponent from "src/routes";
import { $isAuthChecked } from "src/store/account";
import { AppGate } from "src/store/common";

import "src/styles/index.scss";
import { CircularProgress } from "@mui/material";
import ScrollToTop from "./components/common/ScrollToTop";
import Loader from "./components/common/Loader";

function App() {
  useGate(AppGate);
  const isAuthChecked = useStore($isAuthChecked);
  if (!isAuthChecked) {
    return <Loader fullPage />;
  }

  return (
    <div className="app">
      <RoutesComponent />
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback={<CircularProgress color="primary" size={20} />}>
      <App />
    </Suspense>
  );
}
