import { gql, useQuery } from "@apollo/client";
import { IContract } from "src/graphQl/contracts";

export type CurrencyType = "BASE" | "NORMAL";

export interface ICurrency {
  contract: IContract;
  contractId: string;
  createdDate: string;
  id: number;
  handlingFee: number;
  name: string;
  oneUnit: string;
  updatedDate: string;
  type: CurrencyType;
  usdRate: number;
  cnyRate: number;
}

export const CURRENCIES = gql`
  query Currencies($where: CurrencyFilterInput) {
    currencies(where: $where) {
      nodes {
        contract {
          id
        }
        contractId
        createdDate
        id
        handlingFee
        name
        oneUnit
        updatedDate
        usdRate
        cnyRate
      }
    }
  }
`;

export const useCurrencies = (currencyType: CurrencyType = "NORMAL") => {
  const { data, error, loading } = useQuery<{
    currencies: {
      nodes: ICurrency[];
    };
  }>(CURRENCIES, {
    variables: {
      where: { type: { eq: currencyType } },
    },
  });

  return {
    currencies: data?.currencies?.nodes,
    error,
    loading,
  };
};
