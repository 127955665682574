import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import cs from "classnames";

import Button from "src/components/common/Button";
import "./index.scss";

interface IProps {
  image?: string;
  title?: string | ReactNode;
  text: string | ReactNode;
  onClick?: () => void;
  btnOrLinkText?: string;
  linkPath?: string;
  link2?: {
    label: string;
    path: string;
  };
  loading?: boolean;
  className?: string;
}

const LandingContentBlock: FC<IProps> = ({
  image,
  title,
  text,
  onClick,
  btnOrLinkText,
  linkPath,
  loading,
  link2,
  className,
}) => {
  return (
    <div className={cs("landing-content-block", className)}>
      {image && (
        <img className="landing-content-block__image" src={image} alt="" />
      )}
      {title && <div className="landing-content-block__title">{title}</div>}
      <div
        className="landing-content-block__text"
        style={{ marginTop: title ? 0 : "50px" }}
      >
        {text}
      </div>
      {linkPath && (
        <Link className="landing-content-block__read-more" to={linkPath}>
          {btnOrLinkText}
        </Link>
      )}
      {link2 && (
        <Link className="landing-content-block__link" to={link2.path}>
          {link2.label}
        </Link>
      )}
      {!linkPath && onClick && (
        <Button
          onClick={onClick}
          loading={loading}
          className="landing-content-block__btn"
        >
          {btnOrLinkText}
        </Button>
      )}
    </div>
  );
};

export default LandingContentBlock;
