import { AxiosResponse } from "axios";
import $api from "./http";

export interface ISwapParams {
  accountFromId: number;
  accountToId: number;
  amount: number;
  transactionPassword: string;
}

export default class ExchangeService {
  static swap(params: ISwapParams): Promise<AxiosResponse<string>> {
    return $api.post<string>("/Exchange/Swap", params);
  }
}
