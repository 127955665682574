import { AxiosResponse } from "axios";
import $api from "./http";
import { LOGIN_REDIRECT_URL } from "src/config";

interface ISignIn {
  phoneNumber: string;
  password: string;
  smsVerificationOperationId: string;
  phoneCode: string;
}

interface ISignInResponse {
  accessToken: string;
  refreshToken: string;
}

interface ITokens {
  accessToken: string;
  refreshToken: string;
  scope: string;
  tokenType: string;
  expiresInSeconds: number;
}

export default class AuthService {
  static checkAuth(): Promise<AxiosResponse<number>> {
    return $api.get<number>("/Auth");
  }

  static signIn(params: ISignIn): Promise<AxiosResponse<ISignInResponse>> {
    return $api.post<ISignInResponse>("/Auth", params);
  }

  static tokens(code: string): Promise<AxiosResponse<ITokens>> {
    return $api.post<ITokens>("/Auth/tokens", {
      code,
      redirectUrl: LOGIN_REDIRECT_URL,
    });
  }
}
