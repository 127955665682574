import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Space from "../../common/Space";

type User = {
  id: number;
  firstName: string;
  lastName: string;
  createdDate: string;
};

const UserTab: FC<User> = ({ firstName, lastName, createdDate }) => {
  return (
    <Space
      align="center"
      justify="space-between"
      gap={10}
      sx={{
        height: "40px",
      }}
    >
      <Typography variant="subtitle2" color="text.primary2" noWrap>
        {firstName}&nbsp;{lastName}
      </Typography>
      <Typography variant="micro" color="text.primary2">
        {dayjs(createdDate).format("DD.MM.YYYY")}
      </Typography>
    </Space>
  );
};

const InviteesList: FC<{ users: User[] }> = ({ users }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography fontSize="16px">
        <Typography fontSize="inherit" fontWeight="bold" component="span">
          {users.length}
        </Typography>
        &nbsp;{t("inviteesList.title")}
      </Typography>
      <Stack spacing="8px" sx={{ mt: "11px" }}>
        {users.map(user => (
          <UserTab key={user.id} {...user} />
        ))}
      </Stack>
    </Box>
  );
};

export default InviteesList;
