import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import { $swapSuccessModal, setSwapSuccessModal } from "src/store/wallet";

interface IProps {
  amount: number;
  currencyName: string;
}

const SuccessDialog: FC<IProps> = props => {
  const { amount, currencyName } = props;
  const isOpen = useStore($swapSuccessModal);
  const { t } = useTranslation();

  const onClose = () => {
    setSwapSuccessModal(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: 140 }}>
        <Typography
          mt={3}
          mb={4}
          textAlign="center"
          variant="h6"
          color="text.primary2"
        >
          {t("exchangePage.completeTitle")}
        </Typography>
        <Typography textAlign="center" variant="subtitle1" color="#808080">
          {t("exchangePage.completeText", { amount, currencyName })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box color="text.primary" sx={{ m: "0 auto" }}>
          <LoadingButton
            variant="text"
            fullWidth
            color="inherit"
            onClick={onClose}
          >
            {t("buttons.close")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
