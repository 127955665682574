import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { showTranslations } from "translation-check";

const TranslationRedirect = () => {
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => showTranslations(i18n)}
      >
        Check translations
      </Button>
    </Box>
  );
};

export default TranslationRedirect;
