import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";

import { IAccount } from "src/graphQl/accounts";
import { Invitation } from "./invitation";
import { ICurrency } from "./currencies";
import { KycStatus } from "./kyc";
import { $isAuth } from "src/store/account";

// enum KycDocumentType {
//   GOVERNMENT_ID_BACK = "GOVERNMENT_ID_BACK",
//   GOVERNMENT_ID_FRONT = "GOVERNMENT_ID_FRONT",
//   SELFIE = "SELFIE",
// }

interface IKycStatusChange {
  userId: number;
  adminId: number;
  kycStatus: KycStatus;
  comment: string;
  user: IUser;
  id: number;
  createdDate: string;
  updatedDate: string;
}
export interface TweeBaaAppUser {
  id: number;
  tweeBaaUserId: number;
  userName: string;
  nickName: string;
  avatar: string;
  locale: string;
  mobile: string;
  invitationsCount: number;
  invitationsCountUpdated: string;
}

export interface IUser {
  accounts: IAccount[];
  address: string;
  addressIndex: number;
  phoneNumber: string;
  phoneCode: string;
  referralCode: string;
  email?: string;
  roles: string[];
  id: number;
  createdDate: string;
  updatedDate: string;
  tweeBaaAppUser: TweeBaaAppUser | null;
  invitersInvitations: Invitation[];
  inviteeInvitations: Invitation[];
  kycStatus: KycStatus;
  kycStatusTime: string;
  kycStatusChanges: IKycStatusChange[];
  language: string;
  firstName: string;
  lastName: string;
  baseCurrency: ICurrency;
  baseCurrencyId: number;

  ambassadorInProcess: boolean;
  isAmbassador: boolean;
  isAmbassadorDate: string;
  // payPalPayments: PayPalPayment[];
  // kycDocuments: IKycDocument[];
  invitationsAfterAmbassador: number;
}

export const PROFILE = gql`
  query Profile {
    profile {
      address
      addressIndex
      phoneNumber
      phoneCode
      referralCode
      email
      roles
      id
      createdDate
      updatedDate

      tweeBaaAppUser {
        id
        tweeBaaUserId
        userName
        nickName
        avatar
        locale
        mobile
        invitationsCount
      }
      language

      inviteeInvitations {
        invitationType
        id
        inviteeUserId
        inviterUserId
        inviteeUser {
          id
          firstName
          lastName
          createdDate
        }
      }
      invitersInvitations {
        invitationType
        id
        inviteeUserId
        inviterUserId
        inviterUser {
          id
          firstName
          lastName
          roles
        }
      }
      baseCurrencyId
      baseCurrency {
        id
        contractId
        createdDate
        name
        oneUnit
        updatedDate
      }
      kycStatus
      kycStatusTime
      firstName
      lastName
      ambassadorInProcess
      invitationsAfterAmbassador
    }
  }
`;

export const AMBASSADOR_IN_PROCESS = gql`
  query Profile {
    profile {
      ambassadorInProcess
    }
  }
`;

export const ACCOUNTS = gql`
  query Accounts {
    profile {
      id
      accounts {
        userId
        currencyId
        currency {
          contract {
            id
          }
          contractId
          createdDate
          id
          name
          oneUnit
          updatedDate
          usdRate
        }
        baseBalance
        balance
        escrow
        type
        id
        createdDate
        updatedDate
        transactions {
          id
          amount
          type
          id
          createdDate
          updatedDate
        }
      }
    }
  }
`;

export type ProfileQueryResponse = {
  profile: IUser;
};

export const useCurrentUser = () =>
  // options?: QueryHookOptions<ProfileQueryResponse, null>
  {
    const isAuth = useStore($isAuth);
    const [getProfile, { data, error, loading }] = useLazyQuery<
      ProfileQueryResponse,
      null
    >(PROFILE);

    useEffect(() => {
      isAuth && getProfile();
    }, [isAuth, getProfile]);

    return {
      user: data?.profile,
      error,
      loading,
    };
  };

export const useAmbassadorInProcess = () => {
  const isAuth = useStore($isAuth);
  const [getProfile, { data, error, loading }] = useLazyQuery<
    ProfileQueryResponse,
    null
  >(AMBASSADOR_IN_PROCESS);

  useEffect(() => {
    isAuth && getProfile();
  }, [isAuth, getProfile]);

  return {
    ambassadorInProcess: data?.profile?.ambassadorInProcess,
    error,
    loading,
  };
};

export const useAccounts = () => {
  const isAuth = useStore($isAuth);
  const [getProfile, { data, error, loading }] = useLazyQuery<
    ProfileQueryResponse,
    null
  >(ACCOUNTS);

  useEffect(() => {
    isAuth && getProfile();
  }, [isAuth, getProfile]);

  return {
    accounts: data?.profile?.accounts?.filter(
      (acc: any) => acc?.currency?.name?.toLowerCase() !== "usd"
    ),
    userId: data?.profile?.id,
    error,
    loading,
  };
};
