import { ICurrency } from "src/graphQl/currencies";
import { ITransaction } from "src/graphQl/operations";
import { IUser } from "src/graphQl/profile";

export enum AccountType {
  USER = "USER",
  BLOCKCHAIN = "BLOCKCHAIN",
  HOT = "HOT",
  GLOBAL_BLOCKCHAIN = "GLOBAL_BLOCKCHAIN",
  STAKING = "STAKING",
  SYSTEM = "SYSTEM",
  SYSTEM_EXTERNAL = "SYSTEM_EXTERNAL",
  USER_EXTERNAL_USD = "USER_EXTERNAL_USD",
}

export interface IAccount {
  userId: number;
  user: IUser;
  currencyId: number;
  currency: ICurrency;
  balance: number;
  baseBalance: number;
  escrow: number;
  type: AccountType;
  transactions: ITransaction[];
  id: number;
  createdDate: string;
  updatedDate: string;
}
