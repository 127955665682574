import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./index.scss";

const ContactUsLink = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-us-link">
      {t("contactUs.question")}&nbsp;
      <Link className="contact-us-link__link" to="/contact-us">
        {t("buttons.contactUs")}
      </Link>
    </div>
  );
};

export default ContactUsLink;
