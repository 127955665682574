import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import "./index.scss";
import Button from "src/components/common/Button";
import Header from "src/components/landing/Header";
import Footer from "src/components/landing/Footer";
import Contacts from "src/components/landing/Contacts";
import ContentBlock from "src/components/landing/ContentBlock";

import UserMsgImg from "src/assets/UserMsg.png";
import StakingImg from "src/assets/landing/Staking.png";
import AppIntegrationImg from "src/assets/landing/AppIntegration.png";
import ChatImg from "src/assets/landing/Chat.png";
import ChartPercentImg from "src/assets/landing/ChartPercent.png";
import AmbassadorIcon from "src/assets/ambassador-icon.png";
import { KycStatus, useKyc } from "src/graphQl/kyc";
import { useAmbassadorInProcess } from "src/graphQl/profile";
// import { useTweebaaAuthUrl } from "src/graphQl/tweeAppAuthUrl";
import TabBar from "src/components/common/TabBar";

const LoggedLanding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { user } = useCurrentUser();
  const { kyc } = useKyc();
  const { ambassadorInProcess } = useAmbassadorInProcess();
  // const { tweeBaaAuthUrl, loading } = useTweebaaAuthUrl();
  const { pathname } = useLocation();

  if (kyc?.status === KycStatus.APPROVED) {
    return <Navigate to="/dashboard" />;
  }

  const goToAmbassadorPage = () => {
    ambassadorInProcess
      ? navigate("/profile/ambassador")
      : navigate("/ambassador-program");
  };

  const kycBtnText =
    kyc?.status === KycStatus.NONE
      ? t("buttons.startKyc")
      : t("buttons.checkKycStatus");

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
    >
      <div className="logged-landing">
        <Header />

        <ContentBlock
          image={UserMsgImg}
          title={t("landing.kyc.title")}
          text={t("landing.kyc.text")}
          btnOrLinkText={kycBtnText}
          onClick={() => navigate("/kyc")}
        />
        <ContentBlock
          image={StakingImg}
          title={
            <span style={{ textTransform: "uppercase" }}>{t("staking")}</span>
          }
          text={t("landing.staking.text")}
          btnOrLinkText={t("buttons.checkItOut")}
          onClick={() => navigate("/staking")}
        />
        <ContentBlock
          image={AppIntegrationImg}
          title={t("landing.appIntegration.title")}
          text={t("landing.appIntegration.text2")}
          btnOrLinkText={t("buttons.bindWithTweebaa")}
          // onClick={() => toast.info("Coming soon")}
          // onClick={() => window.open(tweeBaaAuthUrl, "_blank")}
          onClick={() =>
            navigate(`/something-required#tweebaa`, {
              state: { prevPath: pathname },
            })
          }
          // loading={loading}
        />
        <ContentBlock
          image={ChatImg}
          title={t("landing.inviteFriends.title")}
          text={t("landing.inviteFriends.text")}
          btnOrLinkText={t("buttons.inviteFriends")}
          onClick={() =>
            navigate(`/something-required#invite`, {
              state: { prevPath: pathname },
            })
          }
        />
        <ContentBlock
          image={ChartPercentImg}
          title={t("landing.walletManagement.title")}
          text={t("landing.walletManagement.text")}
          btnOrLinkText={t("buttons.checkItOut")}
          onClick={() => navigate("/wallet")}
        />

        <div className="landing__card logged-landing__card">
          <img
            className="logged-landing__card-img"
            src={AmbassadorIcon}
            alt="ambassador"
          />
          <div className="logged-landing__card-title">
            {t("landing.becomeAnAmbassador")}
          </div>
          <div className="landing__text">{t("landing.promo.text")}</div>
          <Button
            onClick={goToAmbassadorPage}
            className="logged-landing__btn logged-landing__card-btn"
          >
            {ambassadorInProcess
              ? t("buttons.checkAmbassadorStatus")
              : t("buttons.applyForAmbassador")}
          </Button>
        </div>

        {/* <Button
        onClick={() => toast.info("Coming soon")}
        className="button_outline logged-landing__btn"
      >
        Connect to Tweebaa App
      </Button> */}

        <Contacts />
        <Footer />
      </div>
      <TabBar />
    </Box>
  );
};

export default LoggedLanding;
