import { FC } from "react";
import { CustomerOrder } from "src/services/CustomerOrder";
import { useTranslation } from "react-i18next";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import dayjs from "dayjs";
import "./index.scss";
interface IProps {
    order: CustomerOrder
}
const CustomerOrderCard: FC<IProps> = ({ order }) => {
    const link = `https://tweebaa.ai/my-account/view-order/${order.agencyOrderId}`;
    const { t } = useTranslation();
    return (
        <div className="transactions-card">
            <a href={link} target="_blank" rel="noreferrer">

                <div className="transactions-card__row transactions-card__count">
                    <CurrencyIcon currency="twee" style={{ width: 40, height: 40 }} />
                    <div>{order.itemCount.toLocaleString('en-us', { minimumFractionDigits: 2 })} Twee</div>
                </div>
                <div className="transactions-card__row transactions-card__footer">
                    <div className="transactions-card__header">Id:{order.agencyOrderId}</div>
                    <div >{dayjs(order.orderCompleteTime).format("MMMM DD, YYYY")}</div>
                    <div >Total Price: CAD ${order.totalPrice.toLocaleString('en-us', { minimumFractionDigits: 2 })}</div>
                </div>
            </a>
        </div>
    );
}

export default CustomerOrderCard;