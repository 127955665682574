import { AxiosResponse } from "axios";
import $api from "./http";

export interface IContactUsParams {
  name: string;
  email: string;
  message: string;
}

export default class HomeService {
  static contactUs(params: IContactUsParams): Promise<AxiosResponse<string>> {
    return $api.post<string>("/Home/contact_us", params);
  }
}
