import { useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "src/components/common/Button";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import { IAccount } from "src/graphQl/accounts";
import { CURRENCIES, ICurrency } from "src/graphQl/currencies";
import { KycStatus, useKyc } from "src/graphQl/kyc";
import { useAccounts } from "src/graphQl/profile";
import { $isAuth } from "src/store/account";
import { isTbean } from "src/utils/helpers";

import "./index.scss";

interface IProps {
  path: "deposit" | "withdraw";
}

const CurrenciesList: FC<IProps> = ({ path }) => {
  const navigate = useNavigate();
  const { accounts } = useAccounts();
  const isAuth = useStore($isAuth);
  const [getCurrencies, { data: currencies }] = useLazyQuery(CURRENCIES);
  const { kyc } = useKyc();
  const { pathname } = useLocation();

  const isDepositPage = path === "deposit";

  useEffect(() => {
    !isAuth &&
      getCurrencies({
        variables: {
          where: {
            name: { nin: ["USD", "CNY"] },
          },
        },
      });
  }, [isAuth, getCurrencies]);

  const accountsList = isAuth
    ? accounts
    : currencies?.currencies?.nodes?.map((currency: ICurrency) => ({
        balance: 0,
        baseBalance: 0,
        currency,
        id: currency.id,
      }));

  const kycRequired = kyc?.status !== KycStatus.APPROVED;

  const itemClickHandler = (account: IAccount) => {
    if (!isAuth && isDepositPage) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    if (kycRequired && isDepositPage) {
      return navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
    }
    isTbean(account.currency.name)
      ? navigate(`/wallet/transfer#${path}`)
      : navigate(
          `/wallet/${path}/${account.currency.name}?currencyId=${account.currencyId}`
        );
  };

  // const modalName = !isAuth
  //   ? ModalNames.Register
  //   : kycRequired
  //   ? ModalNames.Kyc
  //   : null;

  return (
    <div className="currencies-list">
      {accountsList?.map((i: IAccount) => (
        <Button
          key={i.id}
          className="currencies-list__currency-item"
          onClick={() => itemClickHandler(i)}
        >
          <div className="currencies-list__icon">
            <CurrencyIcon currency={i.currency.name} />
          </div>{" "}
          <div className="currencies-list__title">{i.currency.name}</div>
        </Button>
      ))}
      {/* <CommonModal modalName={modalName} /> */}
    </div>
  );
};

export default CurrenciesList;
