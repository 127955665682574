import { useState, FC, useEffect } from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { useStore } from "effector-react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import "./index.scss";
import Button from "src/components/common/Button";
import { ReactComponent as Usdt } from "src/assets/wallet/USDT.svg";
import { ReactComponent as TBeans } from "src/assets/wallet/TBean.svg";
import { round, isValidNumber } from "src/utils/helpers";
import Space from "src/components/common/Space";
import SuccessDialog from "./SuccessDialog";
import { IUser, useAccounts, useCurrentUser } from "src/graphQl/profile";
import { $showConfirmTx, setShowConfirmTx, swap } from "src/store/wallet";
import { DEFAULT_ROUND } from "src/config";
import ConfirmTxPassword from "../ConfirmTxPassword";
import { KycStatus } from "src/graphQl/kyc";

enum CryptoCurrency {
  "USDT" = "USDT",
  "T-Bean" = "T-Bean",
}

const currenciesLimits: { [k in CryptoCurrency]: [number, number] } = {
  USDT: [1, 10000],
  "T-Bean": [1, 10000],
};

const ExchangeInputForm: FC<{
  fromCurrency: CryptoCurrency;
  toCurrency: CryptoCurrency;
  cryptoRate: number;
  user?: IUser;
}> = ({ fromCurrency, toCurrency, cryptoRate, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const amountLimits = currenciesLimits[fromCurrency];
  const amountToCurrency = new BigNumber(amount)
    .multipliedBy(cryptoRate)
    .toNumber();
  const swapping = useStore(swap.pending);
  const { accounts } = useAccounts();
  const showConfirmTx = useStore($showConfirmTx);
  const { pathname } = useLocation();

  const amountValid =
    Number(amount) >= amountLimits[0] && Number(amount) <= amountLimits[1];

  const showInput = amountValid && !!amountToCurrency;

  useEffect(() => {
    setAmount("");
  }, [fromCurrency]);

  const fromAccount = accounts?.find(i => i.currency.name === fromCurrency);
  const toAccount = accounts?.find(i => i.currency.name === toCurrency);

  const updateAmount = (percent: number) => {
    const newAmount = fromAccount?.balance
      ? new BigNumber(fromAccount?.balance)
          .div(100)
          .multipliedBy(percent)
          .toString()
      : "0";
    setAmount(newAmount);
  };

  const swapHandler = (transactionPassword: string) => {
    if (!fromAccount || !toAccount) {
      return toast.warn("System error");
    }
    if (fromAccount.balance < +amount) {
      return toast.info("No enough funds");
    }
    swap({
      accountFromId: fromAccount.id,
      accountToId: toAccount.id,
      amount: +amount,
      transactionPassword,
    });
  };

  if (showConfirmTx) {
    return <ConfirmTxPassword onClick={swapHandler} loading={swapping} />;
  }
  const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;

  const exchangeBtnHandler = () => {
    if (!user) {
      return navigate(`/something-required#register`, {
        state: { prevPath: pathname },
      });
    }
    if (kycRequired) {
      return navigate(`/something-required#kyc`, {
        state: { prevPath: pathname },
      });
    }
    setShowConfirmTx(true);
  };

  return (
    <>
      <TextField
        value={amount}
        variant="filled"
        onChange={e =>
          isValidNumber(e.target.value) && setAmount(e.target.value)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{fromCurrency}</InputAdornment>
          ),
        }}
        inputProps={{
          inputMode: "numeric",
          pattern: "^([0-9]+([.][0-9]*)?|[.][0-9]+)?$",
        }}
        sx={{ mt: "30px" }}
        error={!amountValid && !!amount}
        helperText={
          !amountValid &&
          !!amount &&
          `Invalid value: min ${amountLimits[0]}, max ${amountLimits[1]} ${fromCurrency}`
        }
        fullWidth
        placeholder="Amount"
      />
      <div className="exchange__possible-parts">
        {[
          { title: "25%", value: 25 },
          { title: "50%", value: 50 },
          { title: "All", value: 100 },
        ].map(i => (
          <div key={i.value} onClick={() => updateAmount(i.value)}>
            {i.title}
          </div>
        ))}
      </div>
      {showInput && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: "25px",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="body2" noWrap>
            {t("exchangePage.youGet")}:
          </Typography>

          <Space align="center">
            <Typography variant="h6" color="text.primary2" noWrap>
              {round(amountToCurrency, DEFAULT_ROUND)} {toCurrency}
            </Typography>
          </Space>
        </Box>
      )}
      <Box mt="27px">
        <Button
          onClick={exchangeBtnHandler}
          className="exchange__btn"
          disabled={!amount || !amountValid || swapping}
          loading={swapping}
        >
          {t("exchangePage.exchangeBtn")}
        </Button>
      </Box>
      <SuccessDialog amount={amountToCurrency} currencyName={toCurrency} />
    </>
  );
};

const useInitialBaseRate = () => {
  const { data, loading } = useQuery<{ [k in string]: number }>(
    gql`
      query cryptoBaseRate {
        usdt: convert(amount: 1, from: "USDT", to: "T-Bean")
        tbean: convert(amount: 1, from: "T-Bean", to: "USDT")
      }
    `,
    {
      fetchPolicy: "network-only",
      pollInterval: 10000,
    }
  );

  return {
    loading,
    data: { USDT: data?.usdt || 0, "T-Bean": data?.tbean || 0 },
  };
};

const Exchange = () => {
  const { t } = useTranslation();
  const [cryptoCurrency, setCryptoCurrency] = useState<CryptoCurrency>(
    CryptoCurrency.USDT
  );
  const { data: cryptoRate } = useInitialBaseRate();
  const usdtSelected = cryptoCurrency === CryptoCurrency.USDT;
  const { user } = useCurrentUser();
  const showConfirmTx = useStore($showConfirmTx);

  // const appConnected = user?.tweeBaaAppUser;

  useEffect(() => {
    return () => {
      setShowConfirmTx(false);
    };
  }, []);

  // if (!appConnected) {
  //   return <ConnectTweebaaApp />;
  // }
  // const kycRequired = user?.kycStatus && user?.kycStatus !== KycStatus.APPROVED;

  // const modalName = !user
  //   ? ModalNames.Register
  //   : kycRequired
  //   ? ModalNames.Kyc
  //   : null;

  return (
    <Box
      sx={{
        width: "305px",
        maxWidth: "100%",
        margin: "0 auto",
        pt: "35px",
        pb: "60px",
      }}
    >
      {!showConfirmTx && (
        <Typography variant="h6" align="center">
          {t("exchangePage.chooseCurrencyToExchange")}
        </Typography>
      )}
      {!showConfirmTx && (
        <div className="exchange__currencies">
          <div className="exchange__currency-container">
            <Button
              style={{
                opacity: usdtSelected ? 1 : 0.3,
              }}
              onClick={() => setCryptoCurrency(CryptoCurrency.USDT)}
              iconLeft={<Usdt className="exchange__currency-icon" />}
              className="exchange__currency-btn"
            >
              {CryptoCurrency.USDT}
            </Button>
            <div className="exchange__currency-rate">
              1 {CryptoCurrency.USDT} ={" "}
              {round(cryptoRate?.USDT || 0, DEFAULT_ROUND)} T-bean
            </div>
          </div>
          {usdtSelected ? (
            <HiArrowRight className="exchange__arrow-icon" />
          ) : (
            <HiArrowLeft className="exchange__arrow-icon" />
          )}
          <div className="exchange__currency-container">
            <Button
              style={{
                opacity: cryptoCurrency === CryptoCurrency["T-Bean"] ? 1 : 0.3,
              }}
              onClick={() => setCryptoCurrency(CryptoCurrency["T-Bean"])}
              iconLeft={<TBeans className="exchange__currency-icon" />}
              className="exchange__currency-btn"
            >
              {CryptoCurrency["T-Bean"]}
            </Button>
            <div className="exchange__currency-rate">
              1 {CryptoCurrency["T-Bean"]} ={" "}
              {round(cryptoRate?.["T-Bean"] || 0, DEFAULT_ROUND)} USDT
            </div>
          </div>
        </div>
      )}
      {!!cryptoCurrency && !!cryptoRate && (
        <ExchangeInputForm
          fromCurrency={cryptoCurrency}
          toCurrency={
            cryptoCurrency === CryptoCurrency["T-Bean"]
              ? CryptoCurrency.USDT
              : CryptoCurrency["T-Bean"]
          }
          cryptoRate={cryptoRate[cryptoCurrency]}
          user={user}
        />
      )}
      {/* <CommonModal modalName={modalName} /> */}
    </Box>
  );
};

export default Exchange;
