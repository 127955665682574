import { FC, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import { useCurrentUser } from "src/graphQl/profile";
import { TWEE_BAA_TIV } from "src/graphQl/tweeBaaTiv";
import { $isAuth } from "src/store/account";
import Button from "src/components/common/Button";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  logo?: string;
  firstName?: string;
  lastName?: string;
  createdDate?: string;
  invitedBy: string | false;
  tweeBaaAppFullName?: string;
  tivValue?: number;
};

const CardContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "1px 1px 7px rgba(0, 0, 0, 0.12)",
  padding: "19px 14px",
  display: "flex",
  gap: "18px",
}));

const LogoContainer = styled(Box)(() => ({
  width: "56px",
  height: "56px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: "linear-gradient(180deg, #E6BF7B 0%, #D5A249 100%)",
  overflow: "hidden",
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export const ProfileCard: FC<Props> = ({
  logo,
  firstName,
  lastName,
  createdDate,
  invitedBy,
  tweeBaaAppFullName,
  tivValue,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <LogoContainer>
        {logo ? (
          <img src={logo} alt="User logo" />
        ) : (
          <Typography fontSize="21px" fontWeight="500">
            {!!firstName && !!lastName && firstName[0] + lastName[0]}
          </Typography>
        )}
      </LogoContainer>
      <Box>
        <Typography variant="subtitle1">
          {(!!firstName && !!lastName && `${firstName} ${lastName}`) ||
            tweeBaaAppFullName}
        </Typography>
        <Box display="flex" gap="50px">
          <Typography
            variant="caption"
            color="text.primary2"
            mt="16px"
            component="p"
          >
            {t("profileCard.createdLabel")}:{" "}
            {dayjs(createdDate).format("D MMM YYYY")}
          </Typography>
          <Typography
            variant="caption"
            color="text.primary2"
            mt="16px"
            component="p"
          >
            {t("profileCard.yourTiv")}: {tivValue}
          </Typography>
        </Box>
        {invitedBy && (
          <Typography
            variant="micro"
            color="text.primary2"
            mt="5px"
            component="p"
          >
            {t("profileCard.invitedBy")}&nbsp;{invitedBy}
          </Typography>
        )}
      </Box>
    </CardContainer>
  );
};

const ConnectedProfileCard = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const isAuth = useStore($isAuth);
  const [getTiv, { data: tiv }] = useLazyQuery(TWEE_BAA_TIV);
  const tivValue = tiv?.tweeBaaTiv || 0;
  const { pathname } = useLocation();

  useEffect(() => {
    isAuth && getTiv();
  }, [isAuth, getTiv]);

  const invitedByUser = user?.invitersInvitations[0]?.inviterUser;
  const invitedByFullName =
    !!invitedByUser && `${invitedByUser.firstName} ${invitedByUser.lastName}`;

  if (!isAuth) {
    return (
      <CardContainer>
        <Stack gap="20px">
          <Typography variant="subtitle1" color="text.primary2" align="center">
            {t("profileCard.unAuthText")}
          </Typography>
          <Button
            style={{ margin: "0 auto" }}
            onClick={() =>
              navigate(`/something-required#register`, {
                state: { prevPath: pathname },
              })
            }
          >
            {t("buttons.registerTweebank")}
          </Button>
        </Stack>
      </CardContainer>
    );
  }

  return (
    <ProfileCard
      logo={user?.tweeBaaAppUser?.avatar}
      firstName={user?.firstName}
      lastName={user?.lastName}
      invitedBy={invitedByFullName}
      tweeBaaAppFullName={user?.tweeBaaAppUser?.nickName}
      createdDate={user?.createdDate}
      tivValue={tivValue}
    />
  );
};

export default ConnectedProfileCard;
