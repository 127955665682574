import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changePasswordFx } from "../../../store/account";
import ChangePasswordDialogBase from "../ChangePasswordDialogBase";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ChangePasswordDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCloseHandler = () => {
    onClose();
  };
  const onOkHandler = async (values: {
    currentPassword: string;
    newPassword: string;
  }) => {
    try {
      await changePasswordFx(values);
      onClose();
    } catch (err) {
      // console.log({ err });
    }
  };
  const onForgotClickHandler = () => {
    navigate("/reset-password?backBtnName=Back");
  };
  return (
    <ChangePasswordDialogBase
      title={t("dialogTitle.newPassword")}
      onOk={onOkHandler}
      onClose={onCloseHandler}
      onForgotClick={onForgotClickHandler}
      open={open}
    />
  );
};

export default ChangePasswordDialog;
