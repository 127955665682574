import { Typography, Container, IconButton, Box } from "@mui/material";
import { FC } from "react";
import LocalThemeProvider from "../../LocalThemeProvider";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

type Props = {
  backPath?: string;
  title: string;
};

const AppHeader: FC<Props> = ({ backPath, title }) => {
  const navigate = useNavigate();
  return (
    <LocalThemeProvider theme="dark">
      <Container>
        <Box position="relative" pt="30px" pb="25px">
          {!!backPath && (
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: "0",
              }}
              color="info"
              onClick={() => navigate(backPath)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <Typography variant="h6" textAlign="center">
            {title}
          </Typography>
        </Box>
      </Container>
    </LocalThemeProvider>
  );
};

export default AppHeader;
