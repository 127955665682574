import { useNavigate } from "react-router-dom";
import { useGate, useStore } from "effector-react";

import "../ResetPassword/index.scss";
import UnAuthLayout from "src/components/layouts/UnAuthLayout";
import Input from "src/components/common/Input";
import Button from "src/components/common/Button";
import {
  $password,
  $passwordError,
  $phone,
  getConfirmationCode,
  ScreenGate,
  setPassword,
} from "src/store/account";
import { PhoneVerificationRequestType } from "src/services/User";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const ResetTransactionPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useGate(ScreenGate, {
    onSuccess: () => navigate("/confirm-code-transaction-password"),
  });

  const phone = useStore($phone);
  const password = useStore($password);
  const passwordError = useStore($passwordError);
  const loading = useStore(getConfirmationCode.pending);

  return (
    <UnAuthLayout
      className="reset-password"
      title={t("pageTitle.resetTransactionPassword")}
      backBtn={
        <div className="reset-password__back" onClick={() => navigate(-1)}>
          <HiOutlineChevronLeft className="reset-password__back-icon" />{" "}
          {t("buttons.back")}
        </div>
      }
      hAndroid
    >
      <div className="reset-password__text">
        {`${t("resetTransactionPassword.pageDescription")} `}
        <span className="reset-password__phone">{phone}</span>
      </div>
      <Input
        placeholder={t("placeholders.enterNewPassword")}
        value={password}
        onChange={value => setPassword(value)}
        isPassword
        error={passwordError}
      />
      <Button
        onClick={() =>
          getConfirmationCode({
            phone,
            type: PhoneVerificationRequestType.ResetTransactionPassword,
            requirePassword: true,
            password,
          })
        }
        className="reset-password__btn"
        loading={loading}
        disabled={loading}
      >
        {t("buttons.sendConfirmationCode")}
      </Button>
    </UnAuthLayout>
  );
};

export default ResetTransactionPassword;
