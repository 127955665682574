import { useLazyQuery, useQuery } from "@apollo/client";
import { useGate, useStore } from "effector-react";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "src/components/common/Button";
import Checkbox from "src/components/common/Checkbox";
import CurrencyIcon from "src/components/common/CurrencyIcon";
import { CURRENCIES } from "src/graphQl/currencies";
import { STAKE_TEMPLATES } from "src/graphQl/stakeTemplates";
import { USER_STAKES } from "src/graphQl/userStakes";
import { cancelStake, stake, StakeConfirmGate } from "src/store/staking";
import {
  calculateExpectedReturn,
  getDurationBy2Dates,
  getStakingPercent,
  objFromArr,
  round,
} from "src/utils/helpers";
import "./index.scss";
import { CONVERT } from "src/graphQl/convert";
import { TWEE_BAA_TIV } from "src/graphQl/tweeBaaTiv";
import Loader from "src/components/common/Loader";
import { DEFAULT_ROUND } from "src/config";
import { SETTINGS } from "src/graphQl/settings";
import ConfirmTxPassword from "../ConfirmTxPassword";
import { $showConfirmTx, setShowConfirmTx } from "src/store/wallet";
import dayjs from "dayjs";

interface IProps {
  isStaked?: boolean;
  isFinished?: boolean;
}

const StakingDetails: FC<IProps> = ({ isStaked, isFinished }) => {
  const { t } = useTranslation();
  let { currency } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  useGate(StakeConfirmGate, { onConfirm: () => navigate(`/staking/summary`) });
  const searchParams = new URLSearchParams(search);
  const stakeId = searchParams.get("stakeId");
  let period: string | number | null = searchParams.get("period");
  const interestCurrencyId = searchParams.get("interestCurrencyId");
  let amount = searchParams.get("amount") || "";
  const invitationCode = searchParams.get("invitationCode") || "";
  const staking = useStore(stake.pending);
  const cancelStaking = useStore(cancelStake.pending);
  // let expectedReturn = searchParams.get("expectedReturn") || "0";

  const [agreeWithdraw, setAgreeWithdraw] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const showConfirmTx = useStore($showConfirmTx);

  const [getTemplates, { data: templates, loading: loadingTemplates }] =
    useLazyQuery(STAKE_TEMPLATES);
  const [getCurrencies, { data: currencies }] = useLazyQuery(CURRENCIES);
  const [getStakes, { data: stakes, loading: loadingStakes }] =
    useLazyQuery(USER_STAKES);
  const [convert, { data: price, loading: loadingPrice }] =
    useLazyQuery(CONVERT);

  const { data: settings, loading: loadingSettings } = useQuery(SETTINGS);
  const { data: tiv, loading: loadingTiv } = useQuery(TWEE_BAA_TIV);

  const settingsArray = useMemo(
    () => settings?.settings?.nodes || [],
    [settings?.settings?.nodes]
  );
  const tivValue = tiv?.tweeBaaTiv || 0;
  const settingsObj = useMemo(
    () => objFromArr("type", settingsArray, "value"),
    [settingsArray]
  );

  useEffect(() => {
    !isStaked &&
      currency &&
      period &&
      getTemplates({
        variables: {
          where: {
            currency: {
              name: { eq: currency },
            },
            termMonths: { eq: +period },
          },
        },
      });
  }, [isStaked, currency, period, getTemplates]);

  useEffect(() => {
    !isStaked &&
      interestCurrencyId &&
      getCurrencies({
        variables: {
          where: {
            id: { eq: +interestCurrencyId },
          },
        },
      });
  }, [isStaked, interestCurrencyId, getCurrencies]);

  useEffect(() => {
    (isStaked || isFinished) &&
      stakeId &&
      getStakes({
        variables: {
          where: {
            id: { eq: +stakeId },
          },
        },
      });
  }, [isStaked, stakeId, getStakes, isFinished]);

  const template = templates?.stakeTemplates?.nodes?.[0];
  let interestCurrencyName = currencies?.currencies?.nodes?.[0]?.name;

  useEffect(() => {
    currency &&
      interestCurrencyName &&
      currency.toLowerCase() !== interestCurrencyName.toLowerCase() &&
      convert({
        variables: {
          amount: 1,
          from: currency,
          to: interestCurrencyName,
        },
      });
  }, [currency, convert, interestCurrencyName]);

  const userStake = stakes?.userStakes?.nodes?.[0];

  if ((isStaked || isFinished) && userStake) {
    amount = userStake.amount;
    interestCurrencyName = userStake.interestCurrency.name;
    currency = userStake.currency.name;
    period = userStake.termDays / 30;
    // expectedReturn = userStake.interestAmount;
  }

  const apy =
    isStaked || isFinished
      ? userStake?.interest
      : getStakingPercent(template?.baseInterest, settingsObj, tivValue);

  const loadingExpectedReturn =
    amount && (loadingPrice || loadingTemplates || !period || loadingTiv);

  const percent = getStakingPercent(
    template?.baseInterest,
    settingsObj,
    tivValue
  );

  const expectedReturnValue = useMemo(
    () =>
      amount && percent && period
        ? calculateExpectedReturn({
            amount,
            period,
            price: price?.convert || 1,
            percent,
          })
        : null,
    [amount, percent, period, price?.convert]
  );

  const expectedReturn =
    isStaked || isFinished
      ? userStake?.interestAmount
        ? round(userStake?.interestAmount, DEFAULT_ROUND)
        : ""
      : expectedReturnValue;

  if (
    ((!isStaked && (!period || !amount || !interestCurrencyId)) ||
      (isStaked && !stakeId)) &&
    !isFinished
  ) {
    return <Navigate to="/staking" />;
  }

  const remaining = getDurationBy2Dates(userStake?.endDate);

  if (showConfirmTx) {
    return (
      <ConfirmTxPassword
        onClick={transactionPassword =>
          stakeId && cancelStake({ stakeId: +stakeId, transactionPassword })
        }
        loading={cancelStaking}
      />
    );
  }

  return (
    <div className="staking-details">
      <div className="staking-details__title">
        {currency && <CurrencyIcon currency={currency as string} />}
        {t(
          isFinished
            ? "stakingDetailsPage.pageTitles.finishedStaking"
            : isStaked
            ? "stakingDetailsPage.pageTitles.staked"
            : "stakingDetailsPage.pageTitles.staking"
        )}
      </div>
      {isStaked && loadingStakes ? (
        <Loader />
      ) : (
        <div className="staking-details__card">
          <div className="staking-details__card-item">
            <div className="staking-details__card-item-label">
              {t("stakingDetailsPage.cardLabels.amount")}
            </div>
            <div className="staking-details__card-item-value">
              {amount} {currency}
            </div>
          </div>
          {isFinished && (
            <div className="staking-details__card-item">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.interestAmount")}
              </div>
              <div className="staking-details__card-item-value">
                {round(userStake?.interestAmount, DEFAULT_ROUND)}{" "}
                {interestCurrencyName}
              </div>
            </div>
          )}
          <div className="staking-details__card-item">
            <div className="staking-details__card-item-label">
              {t("stakingDetailsPage.cardLabels.term")}:
            </div>
            <div className="staking-details__card-item-value">
              {isStaked || isFinished
                ? getDurationBy2Dates(userStake?.endDate, userStake?.startDate)
                : `${period} ${t("month")}`}
            </div>
          </div>
          {isFinished && (
            <div className="staking-details__card-item">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.startDate")}
              </div>
              <div className="staking-details__card-item-value">
                {dayjs(userStake?.startDate).format("D MMM YYYY")}
              </div>
            </div>
          )}
          {isFinished && (
            <div className="staking-details__card-item">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.endDate")}
              </div>
              <div className="staking-details__card-item-value">
                {dayjs(userStake?.endDate).format("D MMM YYYY")}
              </div>
            </div>
          )}
          {isStaked && remaining && (
            <div className="staking-details__card-item">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.remaining")}:
              </div>
              <div className="staking-details__card-item-value">
                {remaining}
              </div>
            </div>
          )}
          {!isFinished && (
            <div className="staking-details__card-item">
              <div className="staking-details__card-item-label">
                {t("apy")}:
              </div>
              <div className="staking-details__card-item-value">
                {loadingSettings || loadingTiv ? <Loader /> : `${apy}%`}
              </div>
            </div>
          )}
          {!isFinished && (
            <div className="staking-details__card-item">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.interestCurrency")}:
              </div>
              <div className="staking-details__card-item-value">
                {interestCurrencyName}
              </div>
            </div>
          )}
          <div className="staking-details__card-item">
            <div className="staking-details__card-item-label">
              {t("stakingDetailsPage.cardLabels.yourTiv")}:
            </div>
            <div className="staking-details__card-item-value">{tivValue}</div>
          </div>
          {!isFinished && (
            <div className="staking-details__card-item staking-details__card-item_with-border-top">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.expectedReturn")}:
              </div>
              {loadingExpectedReturn ? (
                <Loader />
              ) : (
                <div className="staking-details__card-item-value">
                  {expectedReturn} {interestCurrencyName}
                </div>
              )}
            </div>
          )}
          {isStaked && userStake?.ambassadorInterestAmount && (
            <div className="staking-details__card-item staking-details__card-item_with-border-top">
              <div className="staking-details__card-item-label">
                {t("stakingDetailsPage.cardLabels.ambassadorEarning")}
                {userStake?.ambassadorUser && (
                  <div className="staking-details__ambassador-name">
                    {userStake?.ambassadorUser?.firstName}{" "}
                    {userStake?.ambassadorUser?.lastName}
                  </div>
                )}
              </div>
              <div className="staking-details__card-item-value">
                {userStake?.ambassadorInterestAmount} {interestCurrencyName}
              </div>
            </div>
          )}
        </div>
      )}

      {!isFinished && (
        <>
          <div
            className="staking-details__allow"
            onClick={() => setAgreeWithdraw(ps => !ps)}
          >
            <Checkbox checked={agreeWithdraw} />
            <div className="allow-text">
              {t("stakingDetailsPage.earlyWithdrawWarning")}
            </div>
          </div>
          <div
            className="staking-details__allow"
            onClick={() => setAgreeTerms(ps => !ps)}
          >
            <Checkbox checked={agreeTerms} />
            <div className="allow-text">
              {t("termAndConditions.message")}{" "}
              <a
                href="/agreement"
                target="_blank"
                onClick={e => e.stopPropagation()}
              >
                {t("termAndConditions.link")}
              </a>
            </div>
          </div>
        </>
      )}
      {!isStaked && interestCurrencyId && (
        <Button
          className="staking-details__confirm"
          onClick={() =>
            stake({
              templateId: template.id,
              interestCurrencyId: +interestCurrencyId,
              amount: +amount.replace(",", "."),
              invitationCode,
            })
          }
          loading={staking}
          disabled={
            staking ||
            !agreeWithdraw ||
            !agreeTerms ||
            !template ||
            loadingTemplates
          }
        >
          {t("buttons.finalizeStake")}
        </Button>
      )}

      {isStaked && (
        <Button
          className="staking-details__confirm"
          onClick={() => setShowConfirmTx(true)}
          loading={cancelStaking}
          disabled={cancelStaking || !agreeWithdraw || !agreeTerms || !stakeId}
        >
          {t("buttons.cancelStake")}
        </Button>
      )}
    </div>
  );
};

export default StakingDetails;
