import { useTranslation } from "react-i18next";

import "./index.scss";

const Agreement = () => {
  const { t } = useTranslation();

  return (
    <div className="agreement">
      <div className="agreement__title">{t("userAgreement.title")}</div>
      <br />
      {t("userAgreement.description1")}
      <br />
      {t("userAgreement.description2")}
      <br />
      {t("userAgreement.description3")}
      <br />
      {t("userAgreement.section1Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section1part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section1part2")}
      </div>
      {t("userAgreement.section2Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section2part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section2part2")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section2part3")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section2part4")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section2part5")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section2part6")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section2part7")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section2part8")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section2part9")}
      </div>
      {t("userAgreement.section3Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section3part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section3part2")}
      </div>
      {t("userAgreement.section4Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section4part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section4part2")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section4part3")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part4")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part5")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part6")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part7")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part8")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part9")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section4part10")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part11")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part12")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section4part13")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part14")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part15")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part16")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part17")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part18")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section4part19")}
      </div>
      <br />
      {t("userAgreement.section5Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section5part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section5part2")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section5part3")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section5part4")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section5part5")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section5part6")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section5part7")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section5part8")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section5part9")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section5part10")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section5part11")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section5part12")}
      </div>
      {t("userAgreement.section6Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section6part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section6part2")}
      </div>
      {t("userAgreement.section7Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section7part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section7part2")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section7part3")}
      </div>
      {t("userAgreement.section8Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section8part1")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section8part2")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section8part3")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section8part4")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section8part5")}
      </div>
      {t("userAgreement.section9Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section9part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section9part2")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section9part3")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section9part4")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section9part5")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section9part6")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section9part7")}
      </div>
      <div className="agreement__list-sub-item">
        {t("userAgreement.section9part8")}
      </div>
      {t("userAgreement.section10Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section10part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section10part2")}
      </div>
      {t("userAgreement.section11Title")}
      <br />
      <div className="agreement__list-item">
        {t("userAgreement.section11part1")}
      </div>
      <div className="agreement__list-item">
        {t("userAgreement.section11part2")}
      </div>
    </div>
  );
};

export default Agreement;
