import { forward, guard, sample } from "effector";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import AuthService from "src/services/Auth";
import UserService from "src/services/User";
import $api from "src/services/http";
import { apolloClient } from "src/graphQl";
import { gql } from "@apollo/client";
import * as Sentry from "@sentry/react";

import {
  $code,
  $codeError,
  $needCheckCode,
  checkConfirmationCode,
  ScreenGate,
  getConfirmationCode,
  setCode,
  register,
  $phone,
  checkAuth,
  setEmailError,
  setPasswordError,
  setupTxPassword,
  $isAuth,
  confirmLogin,
  confirmResetPassword,
  $email,
  $emailUpdateDialogOpened,
  openEmailDialog,
  closeEmailDialog,
  $emailError,
  updateEmail,
  patchUserFx,
  fetchUserFx,
  setCurrency,
  setLanguageFx,
  changeTransactionPasswordFx,
  changePasswordFx,
  confirmResetTransactionPassword,
  setFirstNameError,
  setLastNameError,
  setPhoneError,
  logout,
  setSentryUser,
} from "src/store/account";
import { effectErrorHandler, isValidEmail } from "src/utils/helpers";
import { PatchUserParams } from "./types";
import i18n from "../../i18n";
import { IUser, PROFILE, ProfileQueryResponse } from "src/graphQl/profile";
import { ValidationError } from "../../types/errors";
import { USE_TEST_NUMBER } from "src/config";
import { loginFx } from "../auth";

$phone.on(ScreenGate.open, () => {
  const phone = localStorage.getItem("phone");
  if (phone) {
    return `+${phone}`;
  }
  return "";
});

getConfirmationCode.use(
  async ({
    phone,
    doNotCallSuccess,
    type,
    requirePassword,
    password,
    isCreateAccount,
    firstName,
    lastName,
    captchaToken,
  }) => {
    try {
      if (USE_TEST_NUMBER && phone.slice(0, 4) !== "+000") {
        setPhoneError("Use country code +000");
        throw Error("Use country code +000");
      }
      if (!phone || (phone && !isValidPhoneNumber(phone) && !USE_TEST_NUMBER)) {
        setPhoneError("Incorrect phone number format");
        throw Error("Incorrect phone number format");
      }
      if (requirePassword && !password) {
        setPasswordError("Enter password please");
        throw Error("Enter password please");
      }
      if (isCreateAccount && !firstName) {
        setFirstNameError("Enter first name please");
        throw Error("Enter first name please");
      }
      if (isCreateAccount && !lastName) {
        setLastNameError("Enter last name please");
        throw Error("Enter last name please");
      }
      if (isCreateAccount && !captchaToken) {
        setLastNameError("Pass the captcha please");
        throw Error("Pass the captcha please");
      }
      password && localStorage.setItem("password", password);
      const phoneNumber = phone.slice(1);
      localStorage.setItem("phone", phoneNumber);
      const phoneData = USE_TEST_NUMBER
        ? { countryCallingCode: "+000", nationalNumber: phone.slice(4) }
        : parsePhoneNumber(phone);
      if (!phoneData) {
        throw Error("Unknown error");
      }
      const { countryCallingCode, nationalNumber } = phoneData;
      const { data } = await UserService.getSmsCode({
        phoneNumber: nationalNumber,
        type,
        phoneCode: countryCallingCode,
      });
      localStorage.setItem("smsVerificationOperationId", data);
      if (isCreateAccount) {
        firstName && localStorage.setItem("firstName", firstName);
        lastName && localStorage.setItem("lastName", lastName);
        captchaToken && localStorage.setItem("captchaToken", captchaToken);
      }
      if (!doNotCallSuccess) {
        const { onSuccess } = ScreenGate.state.getState();
        onSuccess();
      }
    } catch (err) {
      const errMsg = effectErrorHandler(err);
      throw Error(errMsg);
    }
  }
);

guard({
  clock: setCode,
  filter: $needCheckCode,
  source: $code,
  target: checkConfirmationCode,
});

checkConfirmationCode.use(async code => {
  try {
    const phoneNumber = localStorage.getItem("phone");
    const smsVerificationOperationId = localStorage.getItem(
      "smsVerificationOperationId"
    );
    if (!phoneNumber || !smsVerificationOperationId) {
      throw Error("Enter phone number again please");
    }
    const phoneData = USE_TEST_NUMBER
      ? {
          countryCallingCode: "+000",
          nationalNumber: `+${phoneNumber}`.slice(4),
        }
      : parsePhoneNumber(`+${phoneNumber}`);
    if (!phoneData) {
      throw Error("Unknown error");
    }
    const { countryCallingCode, nationalNumber } = phoneData;
    await UserService.checkCode({
      code,
      phoneNumber: nationalNumber,
      smsVerificationOperationId,
      phoneCode: countryCallingCode,
    });
    const { onSuccess } = ScreenGate.state.getState();
    onSuccess();
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$codeError.on(checkConfirmationCode.failData, (_, { message }) => message);

register.use(async ({ password, invitationCode, email }) => {
  try {
    if (!password) {
      setPasswordError("Enter password please");
      throw Error("Enter password please");
    }
    if (email && !isValidEmail(email)) {
      setEmailError("Wrong Email");
      throw Error("Wrong Email");
    }
    const smsVerificationOperationId = localStorage.getItem(
      "smsVerificationOperationId"
    );
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const captchaToken = localStorage.getItem("captchaToken");
    if (
      !smsVerificationOperationId ||
      !firstName ||
      !lastName ||
      !captchaToken
    ) {
      throw Error("Unknown error");
    }
    const { data } = await UserService.signUp({
      smsVerificationOperationId,
      password,
      invitationCode,
      firstName,
      lastName,
      email,
      captchaToken,
    });
    const { accessToken, refreshToken } = data;
    localStorage.setItem("accessToken", accessToken);
    refreshToken && localStorage.setItem("refreshToken", refreshToken);
    localStorage.removeItem("smsVerificationOperationId");
    const { onSuccess } = ScreenGate.state.getState();
    onSuccess();
  } catch (err: any) {
    if (err?.response?.data?.Errors?.[0]?.Code === 4) {
      setPasswordError(err?.response?.data?.Errors?.[0]?.Msg);
    }
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

setupTxPassword.use(async ({ password }) => {
  try {
    await UserService.setTransactionPassword(password);
    const { onSuccess } = ScreenGate.state.getState();
    onSuccess();
  } catch (err: any) {
    if (err?.response?.data?.Errors?.[0]?.Code === 4) {
      setPasswordError(err?.response?.data?.Errors?.[0]?.Msg);
    }
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

confirmLogin.use(async () => {
  try {
    const phoneNumber = localStorage.getItem("phone");
    const password = localStorage.getItem("password");
    const smsVerificationOperationId = localStorage.getItem(
      "smsVerificationOperationId"
    );
    if (!phoneNumber || !password || !smsVerificationOperationId) {
      throw Error("Unknown error");
    }
    const phoneData = USE_TEST_NUMBER
      ? {
          countryCallingCode: "+000",
          nationalNumber: `+${phoneNumber}`.slice(4),
        }
      : parsePhoneNumber(`+${phoneNumber}`);
    if (!phoneData) {
      throw Error("Unknown error");
    }
    const { countryCallingCode, nationalNumber } = phoneData;
    const { data } = await AuthService.signIn({
      phoneNumber: nationalNumber,
      password,
      smsVerificationOperationId,
      phoneCode: countryCallingCode,
    });
    const { accessToken, refreshToken } = data;
    localStorage.setItem("accessToken", accessToken);
    refreshToken && localStorage.setItem("refreshToken", refreshToken);
    localStorage.removeItem("smsVerificationOperationId");
    localStorage.removeItem("password");
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

confirmResetPassword.use(async () => {
  try {
    const password = localStorage.getItem("password");
    const smsVerificationOperationId = localStorage.getItem(
      "smsVerificationOperationId"
    );
    if (!password || !smsVerificationOperationId) {
      throw Error("Unknown error");
    }
    await UserService.resetPassword({
      password,
      smsVerificationOperationId,
    });
    // const { accessToken, refreshToken } = data;
    // localStorage.setItem("accessToken", accessToken);
    // refreshToken && localStorage.setItem("refreshToken", refreshToken);
    localStorage.removeItem("smsVerificationOperationId");
    localStorage.removeItem("password");
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

confirmResetTransactionPassword.use(async () => {
  try {
    const password = localStorage.getItem("password");
    const smsVerificationOperationId = localStorage.getItem(
      "smsVerificationOperationId"
    );
    localStorage.removeItem("smsVerificationOperationId");
    localStorage.removeItem("password");
    if (!password || !smsVerificationOperationId) {
      throw Error("Unknown error");
    }
    await UserService.resetTransactionPassword({
      password,
      smsVerificationOperationId,
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

checkAuth.use(async () => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return false;
    }
    const { data } = await AuthService.checkAuth();
    console.log("checkAuth", data);
    return true;
  } catch (err) {
    // const errMsg = effectErrorHandler(err);
    throw Error();
  }
});

$isAuth.on(checkAuth.doneData, (_, result) => result);
$isAuth.on(
  [confirmLogin.done, register.done, confirmResetPassword.done, loginFx.done],
  () => true
);

// Email Update flow
const patchEmailDone = patchUserFx.done.filter({
  fn: ({ params }) => !!params.email,
});
const patchEmailFail = patchUserFx.fail.filter({
  fn: ({ params }) => !!params.email,
});
$email.on(openEmailDialog, (_, initialEmail) => initialEmail);
$emailError.reset(openEmailDialog);
$emailUpdateDialogOpened.on(openEmailDialog, () => true);
$emailUpdateDialogOpened.on(closeEmailDialog, () => false);
$emailUpdateDialogOpened.on(patchEmailDone, () => false);
$emailError.on(patchEmailFail, () => "Wrong email");

sample({
  source: $email,
  clock: updateEmail,
  fn: email => ({ email }),
  target: patchUserFx,
});

const validateUserUpdatedFields = (userUpdatedFields: PatchUserParams) => {
  const errors: Record<string, string> = {};

  if (
    userUpdatedFields.email !== undefined &&
    !isValidEmail(userUpdatedFields.email)
  ) {
    errors.email = "Wrong email";
  }
  if (Object.keys(errors).length) {
    return errors;
  }
  return null;
};

const updateUserCache = (userUpdatedFields: Partial<IUser>) => {
  const user = apolloClient.readQuery<{ profile: IUser }>({
    query: PROFILE,
  });
  // expect that query already called and result cached
  if (!user) return;
  const { profile } = user;
  apolloClient.writeFragment({
    id: `User:${profile.id}`,
    fragment: gql`
     fragment _ on User {
        ${Object.keys(userUpdatedFields).join("\n")}
      }
    `,
    data: userUpdatedFields,
  });

  // fields which trigger refetch user model
  const invalidateTriggersFields: Array<keyof IUser> = ["baseCurrencyId"];

  if (
    (Object.keys(userUpdatedFields) as Array<keyof IUser>).some(upf =>
      invalidateTriggersFields.includes(upf)
    )
  ) {
    apolloClient.refetchQueries({
      include: ["Profile"],
    });
  }
};

patchUserFx.use(async userUpdatedFields => {
  try {
    const validateErrors = validateUserUpdatedFields(userUpdatedFields);
    if (validateErrors) {
      throw new ValidationError(validateErrors);
    }
    await $api.patch("/User", userUpdatedFields);
    updateUserCache(userUpdatedFields);
  } catch (err) {
    if (err instanceof ValidationError) {
      throw err;
    } else {
      const errMsg = effectErrorHandler(err);
      throw Error(errMsg);
    }
  }
});

// forward({
//   from: setCurrency.map(c => ({ baseCurrencyId: c })),
//   to: patchUserFx,
// });

guard({
  clock: setCurrency.map(c => ({ baseCurrencyId: c })),
  filter: $isAuth,
  target: patchUserFx,
});

setLanguageFx.use(lang => {
  lang && localStorage.setItem("LANGUAGE", lang);
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }
});

// forward({
//   from: setLanguageFx.done.map(({ params }) => ({ language: params })),
//   to: patchUserFx,
// });

guard({
  clock: setLanguageFx.done.map(({ params }) => ({ language: params })),
  filter: $isAuth,
  target: patchUserFx,
});

guard({
  clock: $isAuth,
  filter: $isAuth,
  target: fetchUserFx,
});

fetchUserFx.use(async () => {
  const { data } = await apolloClient.query<ProfileQueryResponse>({
    query: PROFILE,
    fetchPolicy: "network-only",
  });

  return data.profile;
});

forward({
  from: fetchUserFx.doneData.map(profile => profile.language),
  to: setLanguageFx,
});

forward({
  from: fetchUserFx.doneData,
  to: setSentryUser,
});

setSentryUser.use(user => {
  if (user) {
    Sentry.setUser({
      id: String(user.id),
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      username: `+${user.phoneCode} ${user.phoneNumber}`,
    });
  } else {
    Sentry.setUser(null);
  }
});

changeTransactionPasswordFx.use(async ({ currentPassword, newPassword }) => {
  try {
    await $api.post("User/change_transaction_password", {
      oldPassword: currentPassword,
      newPassword,
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

changePasswordFx.use(async ({ currentPassword, newPassword }) => {
  try {
    await $api.post("User/change_password", {
      oldPassword: currentPassword,
      newPassword,
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

logout.use(async () => {
  try {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    apolloClient.resetStore();
    window.location.href = "/";
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
