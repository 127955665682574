import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import Space from "src/components/common/Space";
import {
  IRewardInProgress,
  RewardsInProgressType,
  useRewardsInProgress,
} from "src/graphQl/rewardsInProgress";
import { roundDecimal } from "src/utils/helpers";

interface IProps {
  rewards: IRewardInProgress[];
}

const RewardsInProgress: FC<IProps> = ({ rewards }) => {
  const { t } = useTranslation();

  return (
    <Container sx={{ pt: "25px", pb: "30px" }}>
      <Stack spacing="8px">
        {rewards.map((reward: IRewardInProgress, ind: number) => {
          const {
            amount,
            currency,
            endStakeDate,
            stakerFirstName,
            stakerLastName,
            type,
          } = reward;
          return (
            <Space
              key={reward.stakeId}
              align="center"
              justify="space-between"
              gap={10}
              sx={{
                height: "35px",
              }}
            >
              <Typography variant="subtitle2" color="text.primary2" noWrap>
                {`${ind + 1}. ${stakerFirstName} ${stakerLastName}`}
              </Typography>
              <Typography variant="micro" color="text.primary2" noWrap>
                {type === RewardsInProgressType.AMBASSADOR
                  ? t("Ambassador")
                  : t("Inviter")}
              </Typography>
              <Typography variant="subtitle2" color="text.primary2" noWrap>
                {roundDecimal(amount)}&nbsp;{currency.name}
              </Typography>
              <Typography variant="micro" color="text.primary2">
                {dayjs(endStakeDate).format("DD.MM.YYYY")}
              </Typography>
            </Space>
          );
        })}
      </Stack>
    </Container>
  );
};

const ProtectedRewardsInProgress = () => {
  const { t } = useTranslation();
  const { rewardsInProgress, loading } = useRewardsInProgress();

  if (!rewardsInProgress || loading) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  if (rewardsInProgress.length === 0) {
    return (
      <Box sx={{ display: "flex", pt: "30px", justifyContent: "center" }}>
        <Typography variant="subtitle1" color="text.primary2">
          {t("rewardsPage.emptyText")}
        </Typography>
      </Box>
    );
  }

  return <RewardsInProgress rewards={rewardsInProgress} />;
};

export default ProtectedRewardsInProgress;
