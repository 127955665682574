import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { FC } from "react";
import cs from "classnames";

import "./index.scss";

interface IProps {
  fullPage?: boolean;
  linear?: boolean;
  className?: string;
}

const Loader: FC<IProps> = ({ fullPage, linear, className }) => {
  return (
    <div
      className={cs("loader", className, {
        "loader_full-page": fullPage,
      })}
    >
      {linear ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="primary" />
        </Box>
      ) : (
        <CircularProgress color="primary" size={20} />
      )}
    </div>
  );
};

export default Loader;
