import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { $isAuth } from "src/store/account";

export type TWEE_APP_AUTH_URL_RESPONSE = {
  tweeBaaAuthUrl: string;
};

export const TWEE_APP_AUTH_URL = gql`
  query tweeBaaAuthUrl {
    tweeBaaAuthUrl
  }
`;

export const useTweebaaAuthUrl = () => {
  const isAuth = useStore($isAuth);
  const [getTweebaaAuthUrl, { data, error, loading }] =
    useLazyQuery(TWEE_APP_AUTH_URL);

  useEffect(() => {
    isAuth && getTweebaaAuthUrl();
  }, [isAuth, getTweebaaAuthUrl]);

  return {
    tweeBaaAuthUrl: data?.tweeBaaAuthUrl,
    error,
    loading,
  };
};
