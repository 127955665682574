import { gql, useLazyQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { $isAuth } from "src/store/account";

export const AMBASSADOR_REWARDS_IN_PROGRESS = gql`
  query ambassadorRewardsInProgress {
    ambassadorRewardsInProgress
  }
`;

export const useAmbassadorRewardsInProgress = () => {
  const isAuth = useStore($isAuth);
  const [getRewards, { data, error, loading }] = useLazyQuery(
    AMBASSADOR_REWARDS_IN_PROGRESS
  );

  useEffect(() => {
    isAuth && getRewards();
  }, [isAuth, getRewards]);

  return {
    ambassadorRewardsInProgress: data?.ambassadorRewardsInProgress || 0,
    error,
    loading,
  };
};
