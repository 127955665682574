import { gql, useQuery } from "@apollo/client";
import { ICurrency } from "./currencies";

export enum RewardsInProgressType {
  AMBASSADOR = "AMBASSADOR",
  INVITER = "INVITER",
}

export interface IRewardInProgress {
  amount: number;
  baseAmount: number;
  currency: ICurrency;
  currencyId: number;
  endStakeDate: string;
  stakeId: number;
  stakerFirstName: string;
  stakerLastName: string;
  type: RewardsInProgressType;
}

export const REWARDS_IN_PROGRESS = gql`
  query rewardsInProgress {
    rewardsInProgress {
      amount
      currency {
        name
      }
      endStakeDate
      stakeId
      stakerFirstName
      stakerLastName
      type
    }
  }
`;

export const useRewardsInProgress = () => {
  const { data, error, loading } = useQuery(REWARDS_IN_PROGRESS);

  return {
    rewardsInProgress: data?.rewardsInProgress || [],
    error,
    loading,
  };
};
