import { useQuery } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PAYPAL_PAYMENTS,
  PyapalPaymentsQueryResponse,
} from "../../graphQl/paypalTransactions";

import PayPalService from "../../services/PayPal";
import SuccessDialog from "./SuccessDialog";
import SuccessDialogContent from "./SuccessDialogContent";
import SuccessDialogFailedContent from "./SuccessDialogFailedContent";

const usePayPalPayments = (orderId: string | null) => {
  const REFETCH_TIMER = 3 * 1000;
  const MAX_REFETCH_COUNT = 2;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] =
    useState<PyapalPaymentsQueryResponse["payPalPayments"]["nodes"][number]>();
  const { refetch } = useQuery<PyapalPaymentsQueryResponse>(PAYPAL_PAYMENTS, {
    fetchPolicy: "no-cache",
    skip: true,
  });

  useEffect(() => {
    if (!orderId) return;
    let cancelled = false;
    setError("");
    setLoading(true);
    let refetchCount = 0;

    const fetchOrder = async (id: string) => {
      const { data: refetchData } = await refetch({
        where: {
          orderId: { eq: id },
        },
        first: 1,
      });

      return refetchData;
    };

    const fetchAndSave = async (id: string) => {
      const response = await fetchOrder(id);
      if (!response?.payPalPayments.nodes.length) {
        throw new Error();
      }
      if (cancelled) return;
      setData(response.payPalPayments.nodes[0]);
      setLoading(false);
    };

    const fetchWithTimer = async (id: string) => {
      try {
        refetchCount++;
        await fetchAndSave(id);
      } catch (err) {
        if (refetchCount === MAX_REFETCH_COUNT) {
          setError("Internal error");
          setLoading(false);
          return;
        }
        setTimeout(() => {
          if (cancelled) return;
          fetchWithTimer(id);
        }, REFETCH_TIMER);
      }
    };

    fetchWithTimer(orderId);

    return () => {
      cancelled = true;
    };
  }, [refetch, orderId, REFETCH_TIMER, MAX_REFETCH_COUNT]);

  return {
    data,
    loading,
    error,
  };
};

const useApproveOrderAndFetchPayments = (orderId?: string | null) => {
  const [approvedOrderId, setApprovedOrderId] = useState<null | string>(null);
  const [approveLoading, setApproveLoading] = useState(false);
  const { data, loading, error } = usePayPalPayments(approvedOrderId);

  useEffect(() => {
    if (!orderId) return;
    setApproveLoading(true);
    let cancelled = false;
    (async () => {
      try {
        await PayPalService.orderDetails(orderId);
      } catch (err) {
        console.log({ err });
      }
      if (!cancelled) {
        setApprovedOrderId(orderId);
        setApproveLoading(false);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [orderId]);

  return { payment: data, loading: loading || approveLoading, error };
};

type PayPalPaymentInfoConnectedProps = {
  orderId?: string;
};

const PayPalPaymentInfoConnected: FC<PayPalPaymentInfoConnectedProps> = ({
  orderId,
}) => {
  const { t } = useTranslation();
  const { payment, loading, error } = useApproveOrderAndFetchPayments(orderId);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" minHeight="150px">
        <CircularProgress size="20px" />
      </Box>
    );
  }
  if (error || !payment) {
    return (
      <SuccessDialogFailedContent
        titleTransKey="purchaseSuccessDialog.errorTitle"
        descriptionTransKey="purchaseSuccessDialog.errorText"
      />
    );
  }

  return (
    <SuccessDialogContent
      baseCurrencyAmount={payment.baseCurrencyAmount}
      baseCurrencyCode={"USD"}
      fee={payment.payPalFee}
      purchasedCurrencyAmount={payment.purchasedCurrencyAmount}
      purchasedCurrencyCode={payment.purchasedCurrencyCode}
      title={t("purchaseSuccessDialog.completeTitle")}
      paymentMethod={t("purchaseSuccessDialog.completePaymentMethod")}
      feeMessage={t("purchaseSuccessDialog.feeMessage")}
    />
  );
};

type SuccessPayPalDialogProps = {
  open: boolean;
  onClose: () => void;
  orderId?: string;
};

const SuccessPayPalDialog: FC<SuccessPayPalDialogProps> = ({
  open,
  onClose,
  orderId,
}) => {
  return (
    <SuccessDialog open={open} onClose={onClose}>
      <PayPalPaymentInfoConnected orderId={orderId} />
    </SuccessDialog>
  );
};

export default SuccessPayPalDialog;
