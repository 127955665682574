import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import TransactionsCard from "src/components/wallet/TransactionsCard";
import { useCurrentUser } from "src/graphQl/profile";
import Button from "src/components/common/Button";
import Loader from "src/components/common/Loader";
import TransactionInfo from "src/components/wallet/TransactionInfo";
import { ReactComponent as Empty } from "src/assets/Empty.svg";
import { PENDING_TRANSACTIONS } from "src/graphQl/pendingTransactions";
import CustomerOrderService from "src/services/CustomerOrder";
import { PageResult, CustomerOrder } from "src/services/CustomerOrder";
import {
    GROUPED_TRANSACTIONS,
    IGroupedTransaction,
} from "src/graphQl/groupedTransactions";
import CustomerOrderCard from "src/components/wallet/CustomerOrderCard";

const Transactions: FC = () => {

    const [orders, setOrders] = useState<PageResult<CustomerOrder> | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        CustomerOrderService.getMyOrders(1, 1000)
            .then(result => {
                setOrders(result);
                setLoading(false);
            });

    }, []);

    const { t } = useTranslation();
    const navigate = useNavigate();

    function isOrderEmpty(): boolean {
        return (orders == null || orders.total == 0);
    }


    const loadedContent = (<>
        {
            isOrderEmpty() ? (
                (<div className="currency-details__empty-container">
                    <Empty />
                    <div className="currency-details__empty-text">
                        {t("transactionsPage.emptyText")}
                    </div>
                </div>)
            ) : orders?.items.map((order) => (<CustomerOrderCard key={order.id} order={order} />))
        }
        <TransactionInfo />
    </>)

    return (
        <div className="transactions">
            {loading ? (<Loader />) : loadedContent}
        </div>
    );
};

export default Transactions;
