import { FC } from "react";

import "./index.scss";
import UnAuthLayout from "src/components/layouts/UnAuthLayout";
import { PhoneVerificationRequestType } from "src/services/User";
import ConfirmCodeForm from "src/components/common/ConfirmCodeForm";
import ConfirmCodeImg from "src/assets/register/ConfirmCode.png";
import { Location, To, useLocation } from "react-router-dom";

interface IProps {
  successRoute: string;
  phoneVerificationRequestType: PhoneVerificationRequestType;
  preSuccess?: () => void;
  // defaultBackBtn?: boolean;
  useLocationState?: boolean;
}

const useComputeDistanation = (
  successRoute: string,
  useLocationState?: boolean
) => {
  const location = useLocation() as Location & {
    state: { from?: Location | string } | null;
  };

  const from = location.state?.from;
  let distanation: To = successRoute;
  if (useLocationState) {
    if (typeof from === "string") {
      distanation = from;
    } else if (from?.pathname) {
      distanation = {
        pathname: from.pathname,
        search: from.search,
        hash: from.hash,
      };
    }
  }
  return distanation;
};

const ConfirmCode: FC<IProps> = ({
  successRoute,
  phoneVerificationRequestType,
  preSuccess,
  // defaultBackBtn,
  useLocationState,
}) => {
  const distanation = useComputeDistanation(successRoute, useLocationState);
  return (
    <UnAuthLayout className="confirm-code" img={ConfirmCodeImg} defaultBackBtn>
      <ConfirmCodeForm
        successRoute={distanation}
        phoneVerificationRequestType={phoneVerificationRequestType}
        preSuccess={preSuccess}
      />
    </UnAuthLayout>
  );
};

export default ConfirmCode;
