import { Box, Divider, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Space from "../../common/Space";

type Props = {
  currentYearValue?: number;
  inProgressValue?: number;
  ambassadorValue?: number;
  isAmbassador: boolean;
};

const Container = styled(Box)(({ theme }) => ({
  boxShadow: "1px 1px 7px rgba(0, 0, 0, 0.12)",
  borderRadius: theme.shape.borderRadius,
  padding: "18px 20px 16px 20px",
  backgroundColor: theme.palette.background.white,
}));

const RewardsCard: FC<Props> = ({
  currentYearValue,
  inProgressValue,
  ambassadorValue,
  isAmbassador,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Space justify="space-between">
        <Box>
          <Typography variant="subtitle2" color="text.primary2">
            {t("rewardsCard.thisYearTitle")}:
          </Typography>
          <Link variant="micro" to="/profile/rewards/this-year" component={RouterLink}>
            {t("rewardsCard.historyLink")}
          </Link>
        </Box>
        <Typography variant="subtitle2">${currentYearValue}</Typography>
      </Space>
      <Space justify="space-between" sx={{ mt: "7px" }}>
        <Box>
          <Typography variant="subtitle2" color="text.primary2">
            {t("rewardsCard.inProgress")}:
          </Typography>
          <Link variant="micro" to="/profile/rewards/in-progress" component={RouterLink}>
            {t("rewardsCard.historyLink")}
          </Link>
        </Box>
        <Typography variant="subtitle2" color="text.primary2">
          ${inProgressValue}
        </Typography>
      </Space>
      {isAmbassador && (
        <>
          <Divider sx={{ mt: "12px", mb: "16px" }} />
          <Space justify="space-between">
            <Typography variant="subtitle2" color="text.primary2">
              {t("rewardsCard.ambassadorRewards")}:
            </Typography>
            <Typography variant="subtitle2" color="text.primary2">
              ${ambassadorValue}
            </Typography>
          </Space>
        </>
      )}
    </Container>
  );
};

export default RewardsCard;
