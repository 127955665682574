import { Container, styled } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Header from "src/components/common/Header";
import LocalThemeProvider from "src/components/LocalThemeProvider";
import "./index.scss";

function createData(
  name: string,
  d30: number,
  d90: number,
  d180: number,
  d365: number
) {
  return { name, d30, d90, d180, d365 };
}

function InterestPercentTable() {
  const { t } = useTranslation();
  const percentRows = [
    createData(
      t("aboutStaking.interestPercentTable.bodyCell1"),
      10,
      16,
      33,
      100
    ),
    createData(
      t("aboutStaking.interestPercentTable.bodyCell2"),
      6,
      9.6,
      19.8,
      60
    ),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%", fontSize: "12px" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutStaking.interestPercentTable.headCell1")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }} align="center">
              {t("aboutStaking.interestPercentTable.headCell2")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }} align="center">
              {t("aboutStaking.interestPercentTable.headCell3")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }} align="center">
              {t("aboutStaking.interestPercentTable.headCell4")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }} align="center">
              {t("aboutStaking.interestPercentTable.headCell5")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {percentRows.map(row => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontSize: "12px" }} component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }} align="center">
                {row.d30}%
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }} align="center">
                {row.d90}%
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }} align="center">
                {row.d180}%
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }} align="center">
                {row.d365}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function createValuesData(deposit: string, interest: string) {
  return { deposit, interest };
}

const valuesRows = [
  createValuesData("100 TWEE", "100 TweeB"),
  createValuesData("100 TweeB", "A choice of 100 TweeB or 100 T-Beans"),
  createValuesData("100 T-Beans", "100 TWEE"),
];

function InterestValueTable() {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%", fontSize: "12px" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutStaking.interestValueTable.headCell1")}
            </TableCell>
            <TableCell sx={{ fontSize: "12px" }}>
              {t("aboutStaking.interestValueTable.headCell2")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {valuesRows.map(row => (
            <TableRow
              key={row.deposit}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontSize: "12px" }} component="th" scope="row">
                {row.deposit}
              </TableCell>
              <TableCell sx={{ fontSize: "12px" }}>{row.interest}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Title = styled("div")({
  color: "#ebbb71",
  fontSize: "16px",
  fontWeight: 500,
  margin: "15px 0 5px",
});

const AboutStaking = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto auto",
        gridTemplateColumns: "100%",
      }}
      className="about-staking"
    >
      <LocalThemeProvider theme="dark">
        <Header backPath={-1} title={t("aboutStaking.headerTitle")} />
      </LocalThemeProvider>
      <Container
        sx={{
          background: "#fff",
          p: "16px",
          fontSize: "14px",
          lineHeight: 1.5,
        }}
      >
        <Title>{t("aboutStaking.title1")}</Title>
        <p>{t("aboutStaking.section1Paragraph1")}</p>
        <br />
        {t("aboutStaking.section1Paragraph2")}
        <br />
        {t("aboutStaking.section1Paragraph3")}
        <InterestPercentTable />
        <br />
        {t("aboutStaking.note")}
        <p>{t("aboutStaking.section1Paragraph4")}</p>
        <br />
        {t("aboutStaking.section1Paragraph5")}
        <br />
        {t("aboutStaking.section1Paragraph6")}
        <InterestValueTable />
        <br />
        {t("aboutStaking.section1Paragraph7")}
        <br />
        <Title>{t("aboutStaking.title2")}</Title>
        <Trans i18nKey="aboutStaking.section2Paragraph1">
          Stake <b>TweeB</b> to receive the return of <b>TweeB</b>.
        </Trans>
        <br />
        {t("aboutStaking.section2Paragraph2")}
        <br />
        <Trans i18nKey="aboutStaking.section2Paragraph3">
          Stake <b>TweeB</b> to receive the return of <b>T-Bean</b> = same value
          of <b>TweeB</b> market price while staking. This example demonstrates
          TweeBank's Dual Benefit “Maintain fiat money value AND enjoy the
          potential growth rewards of TweeB”
        </Trans>
        <br />
        {t("aboutStaking.section2Paragraph4")}
        <br />
        <b>{t("aboutStaking.comparisonExample")}</b>
        <p>{t("aboutStaking.section2Paragraph5")}</p>
        <p>{t("aboutStaking.section2Paragraph6")}</p>
        {t("aboutStaking.section2Paragraph7")}
        <br />
        <b>{t("aboutStaking.summarizeAbove")}</b>
        <p>{t("aboutStaking.section2Paragraph8")}</p>
        <p>{t("aboutStaking.section2Paragraph9")}</p>
        {t("aboutStaking.section2Paragraph10")}
        <br />
        <b>{t("aboutStaking.theConclusion")}</b>
        <p>{t("aboutStaking.section2Paragraph11")}</p>
        <br />
        <Title>{t("aboutStaking.title3")}</Title>
        <p>
          <Trans i18nKey="aboutStaking.section3Paragraph1">
            Stake <b>T-Bean</b> to receive the return of <b>T-Bean</b>
          </Trans>
        </p>
        {t("aboutStaking.section3Paragraph2")}
        <br />
        <Trans i18nKey="aboutStaking.section3Paragraph3">
          Stake <b>T-Bean</b> to receive the return of <b>TWEE</b> (TWEE amount
          is calculated at the same value of T-Bean, using the market price of
          TWEE when staking T-Bean) Example: Stake 10000 T-Bean at the beginning
          of the year, will receive the return of your original T-Bean stake
          plus TWEE at the end of the year. Using the market price of TWEE when
          staking 10000 T-Bean to calculate, ie. If it is US$45.50, the
          principal is 1000 T Bean * US$0.1/T-Bean = US$1000, the return of TWEE
          is US$ 1000 / US$45.5 = 22 TWEE. At the end of the year, the total
          asset including the principal and return is 10000 T-Bean + 22 TWEE.
        </Trans>
        <br />
        <b>{t("aboutStaking.comparisonExample")}</b>
        <p>{t("aboutStaking.section3Paragraph4")}</p>
        <p>{t("aboutStaking.section3Paragraph5")}</p>
        <p>{t("aboutStaking.summarizeAbove")}</p>
        <p>{t("aboutStaking.section3Paragraph6")}</p>
        {t("aboutStaking.section3Paragraph7")}
        <br />
        <b>{t("aboutStaking.theConclusion")}</b>
        <p>{t("aboutStaking.section3Paragraph8")}</p>
        <Title>{t("aboutStaking.title4")}</Title>
        <Trans i18nKey="aboutStaking.section4Paragraph1">
          Stake <b>TWEE</b> to receive the return of <b>TweeB</b>
        </Trans>
        <p>{t("aboutStaking.section4Paragraph2")}</p>
        <p>{t("aboutStaking.section4Paragraph3")}</p>
      </Container>
    </Container>
  );
};

export default AboutStaking;
