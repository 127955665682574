import { gql } from "@apollo/client";

export const INVITEES_STAKES_TRANSACTIONS = gql`
  query inviteesStakesTransactions(
    $where: TransactionFilterInput
    $first: Int
  ) {
    inviteesStakesTransactions(where: $where, first: $first) {
      nodes {
        baseAmount
      }
    }
  }
`;

export const INVITEES_STAKES_TRANSACTIONS_FULL = gql`
  query inviteesStakesTransactions(
    $where: TransactionFilterInput
    $first: Int
  ) {
    inviteesStakesTransactions(where: $where, first: $first) {
      nodes {
        amount
        id
        type
        createdDate
        currency {
          name
        }
        toAccount {
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
