import { AxiosResponse } from "axios";
import $api from "./http";

export interface IWithdrawalRequestParams {
  currencyId: number;
  amount: number;
  to: string;
  transactionPassword: string;
}

export default class AccountService {
  static withdrawalRequest(
    params: IWithdrawalRequestParams
  ): Promise<AxiosResponse<string>> {
    return $api.post<string>("/Account/WithdrawalRequest", params);
  }
}
